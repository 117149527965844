
import { apiServices } from "../../service/apiService";
import { licenseRequestUrlConstants } from "./Constants";

const getSelectedLicenseRequestService = async (id) => {
  return await apiServices.getApi(
    `${licenseRequestUrlConstants.getSelectedLicenseRequestUrl}/${id}`
  );
};


export const licenseRequestService = {
    getSelectedLicenseRequestService,
};
