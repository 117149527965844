export const errorMessage = {
  email: "*Please Enter a valid Email",
  password: "*Please Enter a valid Password",
  emailPassword: "*Please Enter all the Fields",
};

export const headings = {
  login: "Login",
  signUp: "Sign Up",
  rememberMe: "Remember me",
  forgotPassword: "Forgot Password?",
  userName: "We are Aautiverse",
  welcomeText: "Welcome, Please login to continue",
  aboutUs: "About Us",
  showcase: "Showcase",
};

export const apiStatus = {
  success: "success",
};

export const userRoles = {
  admin: "Admin",
  superAdmin: "superAdmin",
};

export const text = {
  savedEmail: "savedEmail",
  savedPassword: "savedPassword",
  error: "error",
  emptyString: "",
  role: "role",
  companyId: "companyId",
};

export const images = {
  image1: "image1",
  image2: "image2",
  image3: "image3",
};
