import {
	Box,
	Checkbox,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import React from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Style.scss";
import { paginationNumber } from "./Constants";
import {
	Add as AddIcon,
	Edit as EditIcon,
	Visibility as ViewIcon,
	Delete as DeleteIcon,
	Search as SearchIcon,
} from "@mui/icons-material";
// import { CommonContainer } from "../../utils/CommonFunction";
import { TableContainer } from "./Container";
// import secureLocalStorage from "react-secure-storage";
// import { storageConstants } from "../../utils/globalConstant";
// import DropdownField from "../../common/DropdownField";

const TableComponent = ({
	columnFields,
	url,
	statusf = true,
	subscriptionStatusF = "Active",
	roleF = "All",
	onClickDelete,
	onClickAddForm,
	onClickEditForm,
	onClickViewForm,
	showViewBtn = true,
	showEditBtn = true,
	showDeleteBtn = true,
	showAddBtn = true,
}) => {
	// const { onClickAddForm, onClickEditForm, onClickViewForm } =
	//   CommonContainer();

	const {
		tableData,
		defaultColDef,
		gridRef,
		rowsPerPage,
		onPageSizeChanged,
		onSelectionChanged,
		onChangeSelectedColumnFields,
		selectedFields,
		columnDefs,
		headerNames,
		selectedRowData,
		onChangeTextFilter,
		statusFilter,
		subscriptionStatusFilter,
		onStatusFilterChange,
		onSubscriptionStatusFilterChange,
		// onRoleChangeFilter,
		// roleFilter
	} = TableContainer({
		columnFields,
		url,
		statusf,
		subscriptionStatusF,
		roleF,
	});

	const hasStatusColumn = headerNames?.includes("Status");
	// const hasRoleColumn = headerNames?.includes("Role");
	const hasSubscriptionStatusColumn = headerNames?.includes(
		"Subscription Status"
	);
	// const userRole = secureLocalStorage.getItem(storageConstants.role);

	return (
		<Grid
			className="table-con"
			style={{
				height: "100%",
			}}
		>
			<Grid
				className="tb-operation-header"
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexWrap: "wrap",
				}}
			>
				<Grid
					sx={{
						display: "flex",
						alignItems: "center",
						flexWrap: "wrap",
					}}
				>
					<FormControl size="small">
						<InputLabel id="demo-multiple-checkbox-label">
							Selected Fields
						</InputLabel>
						<Select
							labelId="demo-multiple-checkbox-label"
							id="demo-multiple-checkbox"
							multiple
							value={selectedFields}
							onChange={onChangeSelectedColumnFields}
							input={<OutlinedInput label="Selected Fields" />}
							renderValue={(selected) => selected.join(", ")}
							sx={{ mr: 1 }}
							size="small"
							className="tb-head-text-field"
						>
							{headerNames?.map((name, index) => (
								<MenuItem key={index} value={name}>
									<Checkbox checked={selectedFields.indexOf(name) > -1} />
									<ListItemText primary={name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<TextField
						id="outlined-controlled"
						label={"Search"}
						className="tb-head-search-text-field tb-head-text-field"
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<IconButton>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
						onChange={onChangeTextFilter}
					// sx={{ width: 300 }}
					/>
					{hasStatusColumn && (
						<FormControl size="small" sx={{ ml: 1 }}>
							<InputLabel id="status-filter-label">Status</InputLabel>
							<Select
								labelId="status-filter-label"
								id="status-filter"
								value={statusFilter ? "true" : "false"}
								onChange={onStatusFilterChange}
								input={<OutlinedInput label="Status" />}
								sx={{ minWidth: 120 }}
							>
								<MenuItem value="true">Active</MenuItem>
								<MenuItem value="false">Inactive</MenuItem>
							</Select>
						</FormControl>
					)}
					{hasSubscriptionStatusColumn && (
						<FormControl size="small" sx={{ ml: 1 }}>
							<InputLabel id="subscription-status-filter-label">
								Subscription Status
							</InputLabel>
							<Select
								labelId="subscription-status-filter-label"
								id="subscription-status-filter"
								value={subscriptionStatusFilter}
								onChange={onSubscriptionStatusFilterChange}
								input={<OutlinedInput label="Subscription Status" />}
								sx={{ minWidth: 160 }}
							>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Expired">Expired</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
							</Select>
						</FormControl>
					)}
					{/* {hasRoleColumn && (
						<FormControl size="small" sx={{ ml: 1 }}>
						<InputLabel id="subscription-status-filter-label">
							Role
						</InputLabel>
						<Select
							labelId="subscription-status-filter-label"
							id="subscription-status-filter"
							value={roleFilter}
							onChange={onRoleChangeFilter}
							input={<OutlinedInput label="Subscription Status" />}
							sx={{ minWidth: 160 }}
						>
							<MenuItem value="All">All</MenuItem>
							<MenuItem value="SAD">SAD</MenuItem>
							<MenuItem value="SM">SM</MenuItem>
							<MenuItem value="DEV">DEV</MenuItem>
							<MenuItem value="CSAD">CSAD</MenuItem>
							<MenuItem value="SP">SP</MenuItem>
							<MenuItem value="MAD">MAD</MenuItem>
						</Select>
					</FormControl>
					)} */}

				</Grid>
				<Grid className="tab-head-act-icons">
					{Object.keys(selectedRowData).length > 0 && (
						<>
							{showViewBtn && (
								<Tooltip title="View" arrow placement="top">
									<IconButton
										className="tb-icon-btn-view"
										onClick={onClickViewForm}
									>
										<ViewIcon className="tb-act-icons-view" />
									</IconButton>
								</Tooltip>
							)}
							{showEditBtn && (
								<Tooltip title="Edit" arrow placement="top">
									<IconButton className="tb-icon-btn" onClick={onClickEditForm}>
										<EditIcon className="tb-act-icons" />
									</IconButton>
								</Tooltip>
							)}
							{showDeleteBtn && (
								<Tooltip title="Delete" arrow placement="top">
									<IconButton
										className="tb-icon-btn-del"
										onClick={onClickDelete}
									>
										<DeleteIcon className="tb-act-icons-del" />
									</IconButton>
								</Tooltip>
							)}
						</>
					)}
					{showAddBtn && (
						<Tooltip title="Add" arrow placement="top">
							<IconButton className="tb-icon-btn-add" onClick={onClickAddForm}>
								<AddIcon className="tb-act-icons-add" />
							</IconButton>
						</Tooltip>
					)}
				</Grid>
			</Grid>

			<Grid id="myGrid" className={`ag-theme-alpine grid-table`}>
				<Box className="tb-page-number-container">
					<FormControl>
						<InputLabel id="demo-simple-select-label">Rows</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={rowsPerPage}
							label="Rows"
							onChange={onPageSizeChanged}
							sx={{
								height: 30,
							}}
						>
							{paginationNumber.map((eachNumber, index) => (
								<MenuItem value={eachNumber.value} key={index}>
									{eachNumber.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				<AgGridReact
					ref={gridRef}
					rowData={tableData}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					pagination={true}
					paginationPageSize={rowsPerPage}
					rowSelection="single"
					onSelectionChanged={onSelectionChanged}
					animateRows={true}
				></AgGridReact>
			</Grid>
		</Grid>
	);
};

export default TableComponent;
