import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  userValidateEmail,
  updateUserEmail,
} from "../../redux/reducer/loginReducer";
import { toggleLoadingView } from "../../redux/reducer/appReducer";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

export default function ForgotPasswordFunctionality() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const { userLogin } = useSelector((state) => state.userLoginDetails);
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    dispatch(updateUserEmail(newEmail));
    setEmailError(false);
    setUserNotFound(false);
  };

  useEffect(() => {
    if (userLogin.status === "success") {
      if (userLogin.validateEmail?.result !== null ) {
        dispatch(toggleLoadingView(false));
        navigate("/otp");
      } else {
        setUserNotFound(true);
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogin.status]);

  const onSubmit = () => {
    if (email === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
      dispatch(userValidateEmail({ email }));
      dispatch(toggleLoadingView(true));
      secureLocalStorage.setItem(storageConstants.forgotPasswordEmail, email);
      secureLocalStorage.setItem(storageConstants.setTimer, 60);
    }
  };

  return {
    email,
    handleEmailChange,
    onSubmit,
    emailError,
    userNotFound,
    userLogin
  };
}
