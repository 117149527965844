import { apiServices } from "../../service/apiService";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

const url  =  `analytics/totaluserscount`
const url2 =  `analytics/getEndUserData`
const url3 =  `pricingPlan/table`

const TotalUsersCount = async (data) => {
  const response = await apiServices.getApi(url, data);
  return response.result;
};

const companyUserDetails = async (data) => {
  const companyId = secureLocalStorage.getItem(storageConstants.companyId);
  const companyID = companyId !== null;
  let url1 = `analytics/companyUserDetails`;
  if (companyID) {
    url1 += `?id=${companyId}`;
  }
  const response = await apiServices.getApi(url1, data);
  return response.result;
};

const getAllEndUserDetails = async (data) => {
  const response = await apiServices.getApi(url2, data);
  return response.result;
};

const getAllPricingPlans = async (data) => {
  const response = await apiServices.getApi(url3, data);
  return response.result;
};

 const getAllPricingPlansForCompany = async (data) => {
   const url = `analytics/pricing?selected=${data}`
   const response = await apiServices.getApi(url,data);
   return response?.result;
}

export const usersCountService = {
  TotalUsersCount,
  companyUserDetails,
  getAllEndUserDetails,
  getAllPricingPlans,
  getAllPricingPlansForCompany
};
