
import { apiServices } from "../../service/apiService";
import { contactWebUrlConstants } from "./Constants";

const getSelectedContactWebService = async (id) => {
  return await apiServices.getApi(
    `${contactWebUrlConstants.getSelectedContactWebUrl}/${id}`
  );
};

const deleteSelectedContactWebService = async (id) => {
    return await apiServices.deleteApi(
      `${contactWebUrlConstants.deleteSelectedContactWebUrl}/${id}`
    );
  };


export const contactWebService = {
    getSelectedContactWebService,
    deleteSelectedContactWebService
};
