import { apiServices } from '../../service/apiService';
import { profileUrlConstants } from './Constants';


const updateProfileDataService = async (data) => {
	return await apiServices.updateApi(
		profileUrlConstants.updateProfileDataurl,
		data
	);
};


const changePasswordService = async (data) => {
	return await apiServices.postApi(
		profileUrlConstants.changePasswordurl,
		data
	);
};

export const ProfileService = {
    updateProfileDataService,
	changePasswordService
};
