import React from "react";
import { AiOutlineGlobal } from "react-icons/ai";

const Offline = () => {
  return (
    <div
      style={{
        padding: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 150,
      }}
    >
      <div>
        <AiOutlineGlobal size={70} />
        <h2>No internet connection</h2>
        <p>Try: </p>
        <div>
          <ul>
            <li>Checking the network calbes, modem and router</li>
            <li>Reconnection to Wi-Fi</li>
            <li>Running Windows Network Diagnostics</li>
          </ul>
        </div>
        <h5>Please check your internet connection</h5>
      </div>
    </div>
  );
};

export default Offline;
