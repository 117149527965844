
import { apiServices } from "../../service/apiService";
import { subscriptionWebUrlConstants } from "./Constants";

const getSelectedSubscriptionWebService = async (id) => {
	return await apiServices.getApi(
		`${subscriptionWebUrlConstants.getSelectedSubscriptionWebUrl}/${id}`
	);
};

const deleteSelectedSubscriptionWebService = async (id) => {
	return await apiServices.deleteApi(
		`${subscriptionWebUrlConstants.deleteSelectedSubscriptionWebUrl}/${id}`
	);
};


export const subscriptionWebService = {
	getSelectedSubscriptionWebService,
	deleteSelectedSubscriptionWebService
};
