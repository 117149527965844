import React from 'react'
import { useSelector } from 'react-redux';
import LicenseRequestTable from './LicenseRequestTable';
import LicenseRequestForm from './licenseRequestForm';

export default function LicenseRequest() {
	const { showTable, showViewForm } = useSelector(
		(state) => state.app
	);

	return (
		<>
			{showTable && <LicenseRequestTable />}
			{(showViewForm) && <LicenseRequestForm/>}
		</>
	);
};

