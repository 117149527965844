import React from "react";
import { useSelector } from "react-redux";
import PlatformAdminUserTable from "./PlatformAdminUserTable";
import PlatformAdminUserForm from "./PlatformAdminUserForm";
import { useLocation } from "react-router-dom";

const PlatformAdminUser = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

   const {state }= useLocation()
  const roleF = state?.roleF ?? "All"

  return (
    <>
      {showTable && <PlatformAdminUserTable roleF = {roleF}/>}
      {(showAddForm || showEditForm || showViewForm) && (
        <PlatformAdminUserForm />
      )}
    </>
  );
};

export default PlatformAdminUser;
