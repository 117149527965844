import { useEffect, useRef, useState } from "react";
import { companyProjectConstants, companyProjectsUrlConstants } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import {
	setSelectedRowData,
	toggleLoadingView,
	toggleToastView,
} from "../../redux/reducer/appReducer";
import { companyProjectsService } from "./Service";
import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";
import {
	checkNumericExpression,
	deleteFiles,
	deleteImage,
	fileUpload,
	imageUpload,
} from "../../utils/helper";
import { apiStatus, fileUploadPaths } from "../../utils/globalConstant";

export const CompanyProjectsContainer = () => {
	const { showEditForm, showAddForm, showViewForm, selectedRowData } =
		useSelector((state) => state.app);

	const { status, data: tableData } = useSelector(
		(state) => state.table.tableData
	);

	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState("1");
	const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
		CommonContainer();
	const [companyData, setCompanyData] = useState([]);
	const [statusFilter, setStatusFilter] = useState(false); // Set default to "Active"

	const gridRef = useRef();

	useEffect(() => {
		if ([apiStatus.success, apiStatus.failed].includes(status)) {
			dispatch(toggleLoadingView(false));
		} else {
			dispatch(toggleLoadingView(true));
		}
	}, [status, dispatch]);

	useEffect(() => {
		dispatch(setSelectedRowData({}));
		dispatch(toggleLoadingView(true));
		dispatch(getTableData(companyProjectsUrlConstants.getTableDataUrl));
	}, [dispatch]);

	const companyConfigInfo = {
		projectId: "",
		companyId: "",
		name: "",
		shortDescription: "",
		description: "",
		delete: false,
		metarobApi: companyProjectConstants.metarobApi[0].id,
		photonId: "",
		appIdRealtime: "",
		appIdFusion: "",
		appIdChat: "",
		appIdVoice: "",
		appVersion: "",
		userNameServer: false,
		fixedRegion: "",
		server: "",
		port: 0,
		proxyServer: "",
		protocol: "",
		enableProtocalFallback: false,
		authMode: 0,
		enableLobbyStatistics: false,
		networkLogging: 0,
		previewImage: "",
		showCaseImages: [],
		android: {
			sceneGuid: "",
			agoraId: "",
			json: [],
			bundle: [],
			hash: [],
		},
		ios: {
			sceneGuid: "",
			agoraId: "",
			json: [],
			bundle: [],
			hash: [],
		},
		windows: {
			sceneGuid: "",
			agoraId: "",
			json: [],
			bundle: [],
			hash: [],
		},
		webgl: {
			sceneGuid: "",
			agoraId: "",
			json: [],
			bundle: [],
			hash: [],
		},
		mac: {
			sceneGuid: "",
			agoraId: "",
			json: [],
			bundle: [],
			hash: [],
		},
	};

	const [rowData, setRowData] = useState(companyConfigInfo);
	const [filesToRemove, setFilestoRemove] = useState([]);
	const [imagesToRemove, setImagesToRemove] = useState([]);
	const [previewImageToRemove, setPreviewImageToRemove] = useState([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [fileTypeToRemove, setFileTypeToRemove] = useState(null);
	const [fileIndexToRemove, setFileIndexToRemove] = useState(null);
	const [fileToRemove, setFileToRemove] = useState(null);
	const [platformTypeToRemove, setPlatformTypeToRemove] = useState(null);
	const [confirmationDialogMessage, setConfirmationDialogMessage] = useState("");


	const [filteredData, setFilteredData] = useState(tableData);

	useEffect(() => {
		setFilteredData(tableData);
	}, [tableData]);

	const onSelectionChanged = (selectedRow) => {
		dispatch(setSelectedRowData(selectedRow));
	};

	const [resetData, setResetData] = useState({});

	const getSelectedRowData = async () => {
		dispatch(toggleLoadingView(true));
		const data =
			await companyProjectsService.getSelectedCompanyProjectsDataService(
				selectedRowData?._id
			);
		setRowData({ ...companyConfigInfo, ...data.result,  projectId: data.result._id || ""}) // Map _id to id});
		setResetData({ ...companyConfigInfo, ...data.result });
		dispatch(toggleLoadingView(false));
	};

	const getCompanyData = async () => {
		const data = await companyProjectsService.getCompanyNamesService();
		setCompanyData(data.result);
		showAddForm && setRowData({ ...rowData, companyId: data.result[0]?.id });
	};

	useEffect(() => {
		if (showEditForm || showViewForm) {
			getSelectedRowData();
		}
		if (showEditForm || showViewForm || showAddForm) {
			getCompanyData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeTextFilter = async (event) => {
		const searchText = event.target.value.toLowerCase();
		const filtered = tableData.filter((item) => {
			return (
				item.companyName.toLowerCase().includes(searchText) ||
				item.name.toLowerCase().includes(searchText)
			);
		});
		setFilteredData(searchText === "" ? tableData : filtered);
	};

	const filterProjectsByStatus = (projects, status) => {
		return projects.filter((project) => {
			if (status === "false") return !project.delete; // Active projects
			if (status === "true") return project.delete; // Inactive projects
			return true; // No filter applied
		});
	};
	useEffect(() => {
		setStatusFilter("false");
	}, [setStatusFilter]);


	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};

	const onChangeCompanyName = (event) => {
		setRowData({ ...rowData, name: event.target.value });
	};

	const onChangeStatus = (event) => {
		setRowData({ ...rowData, delete: event.target.value });
	};

	const onChangeMetarobAPI = (event) => {
		setRowData({ ...rowData, metarobApi: event.target.value })
	}

	const onStatusFilterChange = (event) => {
		const value = event.target.value;
		setStatusFilter(value);
	};

	const onChangeCompany = (event) => {
		const newCompanyId = event.target.value;
		if (rowData.companyId !== newCompanyId) {
			setRowData({
				...companyConfigInfo,
				companyId: newCompanyId,
				name: "",
				shortDescription: "",
				description: "",
				photonId: "",
				appIdRealtime: "",
				appIdFusion: "",
				appIdChat: "",
				appIdVoice: "",
				appVersion: "",
				userNameServer: false,
				fixedRegion: "",
				server: "",
				port: 0,
				proxyServer: "",
				protocol: "",
				enableProtocalFallback: false,
				authMode: 0,
				enableLobbyStatistics: false,
				networkLogging: 0,
				previewImage: "",
				showCaseImages: [],
				android: {
					sceneGuid: "",
					agoraId: "",
					json: [],
					bundle: [],
					hash: [],
				},
				ios: {
					sceneGuid: "",
					agoraId: "",
					json: [],
					bundle: [],
					hash: [],
				},
				windows: {
					sceneGuid: "",
					agoraId: "",
					json: [],
					bundle: [],
					hash: [],
				},
				webgl: {
					sceneGuid: "",
					agoraId: "",
					json: [],
					bundle: [],
					hash: [],
				},
				mac: {
					sceneGuid: "",
					agoraId: "",
					json: [],
					bundle: [],
					hash: [],
				},
			});
		} else {
			setRowData({ ...rowData, companyId: newCompanyId });
		}
	};
	const onChangeShortDescription = (event) => {
		setRowData({ ...rowData, shortDescription: event.target.value });
	};
	const onChangeDescription = (event) => {
		setRowData({ ...rowData, description: event.target.value });
	};

	const onChangeSceneGuid = (event, platform) => {
		setRowData({
			...rowData,
			[platform]: {
				...rowData[platform],
				sceneGuid: event.target.value,
			},
		});
	};

	const onChangePhotonSettings = (event, fieldName) => {
		setRowData({
			...rowData,
			[fieldName]: event.target.value,
		});
	};

	const onChangeAgoraId = (event, platform) => {
		setRowData({
			...rowData,
			[platform]: {
				...rowData[platform],
				agoraId: event.target.value,
			},
		});
	};

	const onChangePhotonId = (event) => {
		setRowData({ ...rowData, photonId: event.target.value });
	};

	const handleNumericInput = (event, field) => {
		if (checkNumericExpression(event.target.value)) {
			const value = parseInt(event.target.value);
			setRowData({ ...rowData, [field]: value.toString() });
		} else if (event.target.value === "") {
			setRowData({ ...rowData, [field]: 0 });
		}
	};

	const onHandleCheckBox = (event, fieldName) => {
		setRowData({ ...rowData, [fieldName]: event.target.checked });
	};

	const onchangeJSONFiles = async (type, event) => {
		dispatch(toggleLoadingView(true));
		let filePath;
		switch (type) {
			case "androidJSON":
			case "androidHash":
			case "androidBundle":
				filePath = fileUploadPaths.androidPath(rowData?.companyId);
				break;
			case "iosJSON":
			case "iosHash":
			case "iosBundle":
				filePath = fileUploadPaths.iosPath(rowData?.companyId);
				break;
			case "windowsJSON":
			case "windowsHash":
			case "windowsBundle":
				filePath = fileUploadPaths.windowsPath(rowData?.companyId);
				break;
			case "webglJSON":
			case "webglHash":
			case "webglBundle":
				filePath = fileUploadPaths.webglPath(rowData?.companyId);
				break;
			case "macJSON":
			case "macHash":
			case "macBundle":
				filePath = fileUploadPaths.macPath(rowData?.companyId);
				break;
			default:
				break;
		}

		const files = Array.from(event.target.files); // Handle single or multiple files
		const uploadedFiles = [];
		const uploadResponse = await fileUpload(files, filePath);

		if (uploadResponse.success) {
			const uploadedFiles = uploadResponse.files;

			// Check for duplicates and update state
			uploadedFiles.forEach((fileName) => {
				const isFileExists = checkIfFileExists(fileName, type);

				if (isFileExists) {
					dispatch(
						toggleToastView({
							showToast: true,
							message:
								"File with the same name already exists. Upload a different file.",
							isError: true,
						})
					);
				} else {
					updateRowData([fileName], type); // Update state for each successful file
				}
			});

			dispatch(
				toggleToastView({
					showToast: true,
					message: `${uploadedFiles.length} file(s) uploaded successfully.`,
					isError: false,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "File upload failed. Please try again.",
					isError: true,
				})
			);
		}


		// Update rowData for uploaded files
		if (uploadedFiles.length > 0) {
			updateRowData(uploadedFiles, type);
			dispatch(
				toggleToastView({
					showToast: true,
					message: `${uploadedFiles.length} file(s) uploaded successfully.`,
					isError: false,
				})
			);
		}

		dispatch(toggleLoadingView(false));
	};

	const checkIfFileExists = (fileName, type) => {
		switch (type) {
			case "androidJSON":
				return rowData.android.json.includes(fileName);
			case "androidHash":
				return rowData.android.hash.includes(fileName);
			case "androidBundle":
				return rowData.android.bundle.includes(fileName);
			case "iosJSON":
				return rowData.ios.json.includes(fileName);
			case "iosHash":
				return rowData.ios.hash.includes(fileName);
			case "iosBundle":
				return rowData.ios.bundle.includes(fileName);
			case "windowsJSON":
				return rowData.windows.json.includes(fileName);
			case "windowsHash":
				return rowData.windows.hash.includes(fileName);
			case "windowsBundle":
				return rowData.windows.bundle.includes(fileName);
			case "webglJSON":
				return rowData.webgl.json.includes(fileName);
			case "webglHash":
				return rowData.webgl.hash.includes(fileName);
			case "webglBundle":
				return rowData.webgl.bundle.includes(fileName);
			case "macJSON":
				return rowData.mac.json.includes(fileName);
			case "macHash":
				return rowData.mac.hash.includes(fileName);
			case "macBundle":
				return rowData.mac.bundle.includes(fileName);
			default:
				return false;
		}
	};

	const updateRowData = (files, type) => {
		switch (type) {
			case "androidJSON":
				setRowData((prevData) => ({
					...prevData,
					android: {
						...prevData.android,
						json: [...prevData.android.json, ...files],
					},
				}));
				break;
			case "androidHash":
				setRowData((prevData) => ({
					...prevData,
					android: {
						...prevData.android,
						hash: [...prevData.android.hash, ...files],
					},
				}));
				break;
			case "androidBundle":
				setRowData((prevData) => ({
					...prevData,
					android: {
						...prevData.android,
						bundle: [...prevData.android.bundle, ...files],
					},
				}));
				break;
			case "iosJSON":
				setRowData((prevData) => ({
					...prevData,
					ios: {
						...prevData.ios,
						json: [...prevData.ios.json, ...files],
					},
				}));
				break;
			case "iosHash":
				setRowData((prevData) => ({
					...prevData,
					ios: {
						...prevData.ios,
						hash: [...prevData.ios.hash, ...files],
					},
				}));
				break;
			case "iosBundle":
				setRowData((prevData) => ({
					...prevData,
					ios: {
						...prevData.ios,
						bundle: [...prevData.ios.bundle, ...files],
					},
				}));
				break;
			case "windowsJSON":
				setRowData((prevData) => ({
					...prevData,
					windows: {
						...prevData.windows,
						json: [...prevData.windows.json, ...files],
					},
				}));
				break;
			case "windowsHash":
				setRowData((prevData) => ({
					...prevData,
					windows: {
						...prevData.windows,
						hash: [...prevData.windows.hash, ...files],
					},
				}));
				break;
			case "windowsBundle":
				setRowData((prevData) => ({
					...prevData,
					windows: {
						...prevData.windows,
						bundle: [...prevData.windows.bundle, ...files],
					},
				}));
				break;
			case "webglJSON":
				setRowData((prevData) => ({
					...prevData,
					webgl: {
						...prevData.webgl,
						json: [...prevData.webgl.json, ...files],
					},
				}));
				break;
			case "webglHash":
				setRowData((prevData) => ({
					...prevData,
					webgl: {
						...prevData.webgl,
						hash: [...prevData.webgl.hash, ...files],
					},
				}));
				break;
			case "webglBundle":
				setRowData((prevData) => ({
					...prevData,
					webgl: {
						...prevData.webgl,
						bundle: [...prevData.webgl.bundle, ...files],
					},
				}));
				break;
			case "macJSON":
				setRowData((prevData) => ({
					...prevData,
					mac: {
						...prevData.mac,
						json: [...prevData.mac.json, ...files],
					},
				}));
				break;
			case "macHash":
				setRowData((prevData) => ({
					...prevData,
					mac: {
						...prevData.mac,
						hash: [...prevData.mac.hash, ...files],
					},
				}));
				break;
			case "macBundle":
				setRowData((prevData) => ({
					...prevData,
					mac: {
						...prevData.mac,
						bundle: [...prevData.mac.bundle, ...files],
					},
				}));
				break;
			default:
				break;
		}
	};

	const onchangeImage = async (event) => {
		dispatch(toggleLoadingView(true));

		const file = event.target.files[0]; // Ensure we only process the first file
		if (!file) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "No file selected. Please try again.",
					isError: true,
				})
			);
			dispatch(toggleLoadingView(false));
			return;
		}

		const res = await imageUpload(file); // Assume imageUpload handles a single file

		if (res.error || !res.result?.uploadedFiles?.[0]?.fileUrl) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "Please upload again",
					isError: true,
				})
			);
		} else {
			setRowData({
				...rowData,
				previewImage: res.result.uploadedFiles[0].fileUrl, // Correctly extract fileUrl
			});
		}

		dispatch(toggleLoadingView(false));
	};

	const onchangeMultipleImages = async (event) => {
		const files = event.target.files; // Extract files
		if (!files || files.length === 0) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "No files selected. Please try again.",
					isError: true,
				})
			);
			return;
		}

		dispatch(toggleLoadingView(true));

		const uploadResults = await Promise.all(
			Array.from(files).map(async (file) => {
				const res = await imageUpload(file); // Upload each file

				if (res.error || res.result?.uploadedFiles?.length === 0) {
					// Handle errors or empty uploads
					return { success: false, originalname: file.name };
				}

				// Extract the file URL from the response
				const uploadedFile = res.result.uploadedFiles[0];
				return {
					success: true,
					fileUrl: uploadedFile.fileUrl, // Only retain fileUrl
				};
			})
		);

		// Filter successful uploads
		const successfulUploads = uploadResults
			.filter((file) => file.success)
			.map((file) => file.fileUrl); // Only file URLs

		if (successfulUploads.length > 0) {
			setRowData((prevData) => {
				const existingImages = prevData?.showCaseImages || [];
				const uniqueImages = Array.from(
					new Set([...existingImages, ...successfulUploads]) // Ensure unique URLs
				);
				return {
					...prevData,
					showCaseImages: uniqueImages,
				};
			});
		}

		// Handle failures
		const failedUploads = uploadResults.filter((file) => !file.success);
		if (failedUploads.length > 0) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: `${failedUploads.length} files failed to upload. Please try again.`,
					isError: true,
				})
			);
		}

		dispatch(toggleLoadingView(false));
	};



	const deleteShowCaseImages = async () => {
		imagesToRemove.map(async (removeImages) => {
			await deleteImage(removeImages);
		});
	};

	const deletePreviewImage = async () => {
		previewImageToRemove.map(async (removePreviewImage) => {
			await deleteImage(removePreviewImage);
		});
	};

	const deleteRemovedFiles = async () => {
		filesToRemove.map(async (removedFile) => {
			await deleteFiles(removedFile);
		});

		//  if (deleteResponse.error) {
		//    dispatch(
		//      toggleToastView({
		//        showToast: true,
		//        message: deleteResponse.message,
		//        isError: true,
		//      })
		//    );
		//  } else {
		//    dispatch(
		//      toggleToastView({
		//        showToast: true,
		//        message: deleteResponse.message,
		//        isError: false,
		//      })
		//    );}
	};

	const onClickSave = async () => {
		if (rowData?.companyId.trim() === "" ||
			rowData?.name.trim() === "" ||
			rowData?.shortDescription.trim() === "" ||
			rowData?.description.trim() === "") {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await companyProjectsService.saveCompanyProjectsDataService(
					rowData
				);
			} else {
				await deletePreviewImage();
				await deleteRemovedFiles();
				await deleteShowCaseImages();
				response =
					await companyProjectsService.updateCompanyProjectsDataService(
						rowData
					);
			}
			if (response.result.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(
					getTableData(companyProjectsUrlConstants.getTableDataUrl)
				);
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};
	const onClickReset = () => {
		setRowData(resetData);
		setPreviewImageToRemove([]);
		setImagesToRemove([]);
		setFilestoRemove([]);
	};

	const onClickCompanyConfigAddForm = () => {
		onClickAddForm();
	};
	const onClickCompanyConfigEditForm = () => {
		onClickEditForm();
	};
	const onClickCompanyConfigViewForm = () => {
		onClickViewForm();
	};

	const onClickDelete = async () => {
		dispatch(toggleLoadingView(true));
		let response;
		if (statusFilter === 'false') {
			response =
				await companyProjectsService.deleteCompanyProjectsDataService(
					selectedRowData?._id
				);
		} else {
			response =
				await companyProjectsService.hardDeleteCompanyProjectsDataService(
					selectedRowData?._id
				);
		}

		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(companyProjectsUrlConstants.getTableDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};
	const onRemovePreviewImage = () => {
		const previewImage = rowData.previewImage;
		previewImageToRemove.push({ image: previewImage });
		setPreviewImageToRemove(previewImageToRemove);

		setRowData({
			...rowData,
			previewImage: "",
		});
	};

	const onRemoveShowCaseImages = (platform, index) => {
		const updatedRowData = {
			...rowData,
			showCaseImages: [...rowData.showCaseImages],
		};

		if (index < updatedRowData[platform]?.length) {
			updatedRowData[platform] = updatedRowData[platform].filter(
				(file, fileIndex) => {
					if (fileIndex === index) {
						imagesToRemove?.push({ image: file });
					}
					return fileIndex !== index;
				}
			);
		}
		setRowData(updatedRowData);
		setImagesToRemove(imagesToRemove);
	};

	const onRemoveAllFiles = (platform, fileType) => {
		const removeAllFiles = (platform, fileType) => {
			const files = [...rowData[platform][fileType]];
			if (files.length > 0) {
				setRowData({
					...rowData,
					[platform]: {
						...rowData[platform],
						[fileType]: [], // Clear all files of the specific file type
					},
				});

				setFilestoRemove([
					...filesToRemove,
					...files.map((file) => ({
						companyId: rowData?.companyId,
						device: platform,
						fileType,
						file,
					})),
				]);
			}
		};

		removeAllFiles(platform, fileType);
	};

	const onRemoveFile = (type, index) => {
		const removeFile = (platform, fileType) => {
			const files = [...rowData[platform][fileType]];
			if (index < files.length) {
				const fileToRemove = files[index];

				const updatedFiles = files.filter((file) => file !== fileToRemove);
				setRowData({
					...rowData,
					[platform]: {
						...rowData[platform],
						[fileType]: updatedFiles,
					},
				});

				setFilestoRemove([
					...filesToRemove,
					{
						companyId: rowData?.companyId,
						device: platform,
						fileType,
						file: fileToRemove,
					},
				]);
			}
		};



		switch (type) {
			case "androidJSON":
				removeFile("android", "json");
				break;
			case "androidHash":
				removeFile("android", "hash");
				break;
			case "androidBundle":
				removeFile("android", "bundle");
				break;
			case "iosJSON":
				removeFile("ios", "json");
				break;
			case "iosHash":
				removeFile("ios", "hash");
				break;
			case "iosBundle":
				removeFile("ios", "bundle");
				break;
			case "windowsJSON":
				removeFile("windows", "json");
				break;
			case "windowsHash":
				removeFile("windows", "hash");
				break;
			case "windowsBundle":
				removeFile("windows", "bundle");
				break;
			case "webglJSON":
				removeFile("webgl", "json");
				break;
			case "webglHash":
				removeFile("webgl", "hash");
				break;
			case "webglBundle":
				removeFile("webgl", "bundle");
				break;
			case "macJSON":
				removeFile("mac", "json");
				break;
			case "macHash":
				removeFile("mac", "hash");
				break;
			case "macBundle":
				removeFile("mac", "bundle");
				break;
			default:
				break;
		}
	};

	const handleRemoveClick = (type, index) => {

		if (index === "bundle" || index === "hash" || index === "json") {
			// Removing all files
			setFileToRemove(index);
			setPlatformTypeToRemove(type)
			setConfirmationDialogMessage("Are you sure you want to remove all files?");
		} else {
			// Removing a single file
			setFileTypeToRemove(type);
			setFileIndexToRemove(index);
			setConfirmationDialogMessage("Are you sure you want to remove this file?");
		}
		setConfirmationDialogOpen(true);
	};


	const handleRemoveConfirm = () => {
		console.log("State Details:", platformTypeToRemove, fileToRemove, fileTypeToRemove, fileIndexToRemove);

		if (platformTypeToRemove && fileToRemove) {
			// Remove all files
			onRemoveAllFiles(platformTypeToRemove, fileToRemove);
		} else if (fileIndexToRemove !== null && fileTypeToRemove) {
			// Remove a single file
			onRemoveFile(fileTypeToRemove, fileIndexToRemove);
		} else {
			console.warn("Invalid state for removal operation.");
		}
		setPlatformTypeToRemove(null);
		setFileToRemove(null);
		setFileTypeToRemove(null);
		setFileIndexToRemove(null);
		setConfirmationDialogOpen(false);
	};



	const handleRemoveCancel = () => {
		setConfirmationDialogOpen(false);
	};

	const handleDeleteClick = () => {
		setShowDeleteConfirmation(true);
	};

	const handleDeleteConfirm = () => {
		onClickDelete();
		setShowDeleteConfirmation(false);
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};

	return {
		handleRemoveClick,
		handleRemoveConfirm,
		handleRemoveCancel,
		confirmationDialogOpen,
		onChangeCompany,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		onClickSave,
		onClickReset,
		rowData,
		onClickDelete,
		companyData,
		onClickCompanyConfigAddForm,
		onClickCompanyConfigEditForm,
		onClickCompanyConfigViewForm,
		onChangeShortDescription,
		onChangeDescription,
		onchangeImage,
		onChangeCompanyName,
		onchangeMultipleImages,
		onchangeJSONFiles,
		onChangeSceneGuid,
		onChangeAgoraId,
		onRemoveFile,
		onRemoveShowCaseImages,
		onRemovePreviewImage,
		onChangePhotonSettings,
		handleNumericInput,
		onHandleCheckBox,
		handleDeleteClick,
		handleDeleteConfirm,
		handleDeleteCancel,
		showDeleteConfirmation,
		onChangePhotonId,
		tableData,
		onSelectionChanged,
		onChangeTextFilter,
		filteredData,
		onChangeStatus,
		onChangeMetarobAPI,
		onStatusFilterChange,
		statusFilter,
		filterProjectsByStatus,
		onRemoveAllFiles,
		confirmationDialogMessage
	};
};