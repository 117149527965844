import React, { useState } from 'react';
import TableComponent from '../tableComponent/TableComponent';
import { MetaRobDataContainer } from './Container';
import { MetaRobDataUrlConstants } from './Constants';
import { propertyTypeTableColumns } from '../../utils/tableColumns/propertyTypeData';
import { OutlinedInput, FormControl, InputLabel, Select, MenuItem } from '@mui/material'; // Import FormControl, InputLabel, Select, and MenuItem
import './Style.scss'
import { facingTableColumns } from '../../utils/tableColumns/facingData';
import secureLocalStorage from 'react-secure-storage';
import { storageConstants } from '../../utils/globalConstant';

const MetaRobDataTable = () => {
	const propertyTypeColumnFields = propertyTypeTableColumns();
	const facingColumnFields = facingTableColumns();

	const {
		onClickFacingDelete,
		onClickPropertyTypeDelete,
		onClickPropertyTypeAddForm,
		onClickFacingAddForm,
	} = MetaRobDataContainer();
	const userRole = secureLocalStorage.getItem(storageConstants.role);

	const [selectedFilter, setSelectedFilter] = useState('propertyType'); // State to track the selected filter

	const handleFilterChange = (event) => {
		setSelectedFilter(event.target.value); // Update the selected filter
	};

	return (
		<>
		   
			<FormControl style={{ marginTop: "35px", marginLeft: "10px"}}>
				<InputLabel id="filter-select-label">Filter</InputLabel>
				<Select
					labelId="filter-select-label"
					id="filter-select"
					value={selectedFilter}
					onChange={handleFilterChange}
					input={<OutlinedInput label="Filter" />}
					sx={{ mr: 1 }}
					size="small"
					className="tb-head-text-field"
				>
					<MenuItem value="propertyType">Property Type</MenuItem>
					<MenuItem value="facing">Facing</MenuItem>
				</Select>
			</FormControl>

			{selectedFilter === 'propertyType' && (
				<TableComponent
					columnFields={propertyTypeColumnFields}
					url={MetaRobDataUrlConstants.getTablePropertyTypeDataUrl}
					onClickDelete={onClickPropertyTypeDelete}
					onClickAddForm={onClickPropertyTypeAddForm}
					showAddBtn={!["SM"].includes(userRole)}
					showEditBtn={!["SM"].includes(userRole)}
					showDeleteBtn={!["SM"].includes(userRole)}
					showViewBtn={!["SM"].includes(userRole)}
				/>
			)}

			{selectedFilter === 'facing' && (
				<TableComponent
					columnFields={facingColumnFields}
					url={MetaRobDataUrlConstants.getTableFacingDataUrl}
					onClickDelete={onClickFacingDelete}
					onClickAddForm={onClickFacingAddForm}
					showAddBtn={!["SM"].includes(userRole)}
					showEditBtn={!["SM"].includes(userRole)}
					showDeleteBtn={!["SM"].includes(userRole)}
					showViewBtn={!["SM"].includes(userRole)}
				/>
			)}
		</>
	);
};

export default MetaRobDataTable;
