import { Box, Grid, Tab } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import LicenseRequestContainer from "./Container";
import FormActionButton from "../../common/FormActionButton";
import { licenseRequestLabels } from "../../assets/labels/licenseRequest";
import { getDateTimeFormat } from "../../utils/helper";


const LicenseRequestForm = () => {
	const { tabIndex, onChangeTabIndex, showViewForm, rowData } =
		LicenseRequestContainer();

	return (
		<Grid className="global-form">
			<Box
				className="global-form-container"
				sx={{
					typography: "body1",
					height: "95%",
				}}
			>
				<TabContext value={tabIndex}>
					<Box className="global-form-tab-header">
						<TabList
							onChange={onChangeTabIndex}
							aria-label="lab API tabs example"
						>
							<Tab label="Company Form" value="1" />
						</TabList>
					</Box>
					<TabPanel value="1" className="global-form-fields-container">
						<TextInputField
							label={licenseRequestLabels.company_name}
							value={rowData?.companyName}
							isRequired={true}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={licenseRequestLabels.user_name}
							value={rowData?.userName}
							isRequired={true}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={licenseRequestLabels.email}
							value={rowData?.email}
							isRequired={true}
							isMail={true}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={licenseRequestLabels.mobile}
							value={rowData?.mobile}
							isRequired={true}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={licenseRequestLabels.type_of_subscription}
							value={rowData?.licenseType}
							isRequired={false}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={licenseRequestLabels.requested_date}
							value={getDateTimeFormat(rowData?.createdAt)}
							isRequired={true}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={licenseRequestLabels.message}
							value={rowData?.message}
							isRequired={true}
							isDisabled={showViewForm}
							descriptionStyling={{ width: "1000px" }}
							size="large"
							multiline={true}
							rows={3}
						/>
					</TabPanel>
					<FormActionButton
					// onClickSave={onClickSave}
					// onClickReset={onClickReset}
					/>
				</TabContext>
				<DialogBox />
			</Box>
		</Grid>
	);
};

export default LicenseRequestForm;
