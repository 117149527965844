import { Link } from "react-router-dom";
export const constants = {
  sorry: "Sorry!..",
  title: "We can't seem to find the resource you're looking for.",
  para1: "Please check that the Web site address is spelled correctly.",
  para2: (
    <span>
      Or go to our{" "}
      <Link
        to="/"
        style={{ textDecoration: "none", color: "blue", fontWeight: "400" }}
      >
        home page
      </Link>
      , and use the menu to navigate to a specific section.
    </span>
  ),
};
