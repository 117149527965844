import { apiServices } from "../../service/apiService";
import { companyUrlConstants } from "./Constants";

const getSelectedCompanyDataService = async (id) => {
  return await apiServices.getApi(
    `${companyUrlConstants.getSelectedCompanyDataUrl}/${id}`
  );
};

const getProfileDataService = async (id) => {
  return await apiServices.getApi(
    `${companyUrlConstants.getProfileDataUrl}/${id}`
  );
};

const saveCompanyDataService = async (data) => {
  return await apiServices.postApi(
    companyUrlConstants.saveCompanyDataurl,
    data
  );
};

const updateCompanyDataService = async (data) => {
  return await apiServices.updateApi(
    companyUrlConstants.updateCompanyDataurl,
    data
  );
};

const deleteCompanyDataService = async (id) => {
  return await apiServices.deleteApi(
    `${companyUrlConstants.deleteCompanyDataUrl}/${id}`
  );
};

const getPricingPlanService = async () => {
  return await apiServices.getApi("pricingPlan/table");
};

export const companyService = {
  getSelectedCompanyDataService,
  getProfileDataService,
  saveCompanyDataService,
  updateCompanyDataService,
  deleteCompanyDataService,
  getPricingPlanService,
};
