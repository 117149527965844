import { Button, Grid } from "@mui/material";
import React from "react";
import { CommonContainer } from "../utils/CommonFunction";
import { useSelector } from "react-redux";

const FormActionButton = ({ onClickSave, onClickReset }) => {
  const { onClickExitForm } = CommonContainer();
  const { showEditForm, showAddForm } = useSelector((state) => state.app);
  return (
    <Grid
      className="global-form-action-btns"
      sx={{
        width: "100%",
      }}
    >
      {showEditForm && (
        <Button
          variant="outlined"
          className="global-form-action-button"
          onClick={onClickReset}
        >
          Reset
        </Button>
      )}
      <Button
        variant="outlined"
        className="global-form-action-button"
        onClick={onClickExitForm}
      >
        Exit
      </Button>
      {(showEditForm || showAddForm) && (
        <Button
          variant="outlined"
          className="global-form-action-button"
          onClick={onClickSave}
        >
          {showEditForm ? "Update" : "Save"}
        </Button>
      )}
    </Grid>
  );
};

export default FormActionButton;
