import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CommonContainer } from '../../utils/CommonFunction';
import { contactWebService } from './Service';
import { setSelectedRowData, toggleLoadingView, toggleToastView } from '../../redux/reducer/appReducer';
import { contactWebUrlConstants } from './Constants';
import { getTableData } from '../tableComponent/tableComponent.slice';

export default function ContactWebContainer() {

  const { showViewForm, selectedRowData } =
	useSelector((state) => state.app);

  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { onClickViewForm } = CommonContainer();

  const contactWebInfo = {
	type: "",
	fullName: "",
	mobile: "",
	email: "",
	subject: "",
	message: "",
	createdAt: "",
  }

  const [rowData, setRowData] = useState(contactWebInfo);

  const getSelectedRowData = async () => {
	dispatch(toggleLoadingView(true));
	const data = await contactWebService.getSelectedContactWebService(
	  selectedRowData?._id
	);
	setRowData(data?.result);
	dispatch(toggleLoadingView(false));
  };

  useEffect(() => {
	if (showViewForm) {
	  getSelectedRowData();
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTabIndex = (event, newValue) => {
	setTabIndex(newValue);
  };
  const onClickContactWebViewForm = () => {
	onClickViewForm();
  }

  const onClickDelete = async () => {
	dispatch(toggleLoadingView(true));
	const response = await contactWebService.deleteSelectedContactWebService(
	  selectedRowData?._id
	);
	dispatch(setSelectedRowData({}));
	if (response.error) {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: response.message,
		  isError: true,
		})
	  );
	} else {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: response.message,
		  isError: false,
		})
	  );
	  await dispatch(getTableData(contactWebUrlConstants.getTableDataUrl));
	  dispatch(toggleLoadingView(false));
	}
  };

  const handleDeleteClick = () => {
	setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
	onClickDelete();
	setShowDeleteConfirmation(false);
  };

  const handleDeleteCancel = () => {
	setShowDeleteConfirmation(false);
  };
  return {
	tabIndex,
	onChangeTabIndex,
	showViewForm,
	rowData,
	onClickContactWebViewForm,
	onClickDelete,
	handleDeleteClick,
	handleDeleteConfirm,
	handleDeleteCancel,
	showDeleteConfirmation
  }
}
