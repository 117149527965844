import { Button, Container, Tooltip, Typography } from "@mui/material";
import React from "react";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Option = ({
  index,
  showSideMenu,
  Icon = null,
  onChangeIndex,
  title,
  value,
  isToggleBtn = false,
  handleClick,
  showArrow = false,
  arrowOpen = false,
  showArrayIcon = false,
  to = null,
}) => {
  const { showTable } = useSelector((state) => state.app);

  const handleeClick = (event) => {
    if (!showTable) {
      event.preventDefault();
    }
  };

  const OptionContent = () => (
    <Container
      sx={{
        backgroundColor: index === value ? "#fff" : "",
        ml: 1,
      }}
      className={`${
        showSideMenu
          ? "sd-select-option sd-select-option_show"
          : "sd-select-option"
      }`}
      onClick={() => {
        if (isToggleBtn) {
          handleClick();
        } else {
          onChangeIndex(value);
        }
      }}
    >
      {index === value && (
        <>
          <div className="top-curve"></div>
          <div className="bottom-curve"></div>
        </>
      )}
      <Button
        // onClick={() => {
        //   if (isToggleBtn) {
        //     handleClick();
        //   } else {
        //     onChangeIndex(value);
        //   }
        // }}
        startIcon={
          <Tooltip title={showSideMenu ? null : title} placement="right" arrow>
            <Icon
              sx={{
                color: index === value ? "" : "#fff",
                mr: 2,
                fontSize: "25px !important",
              }}
            />
          </Tooltip>
        }
        size="large"
        sx={{
          color: index === value ? "" : "#fff",
        }}
        // endIcon={
        //   showArrow ? (
        //     arrowOpen ? (
        //       <KeyboardArrowDownIcon />
        //     ) : (
        //       <KeyboardArrowRightIcon />
        //     )
        //   ) : null
        // }
      >
        {showSideMenu && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: index === value ? "" : "#fff",
                fontWeight: "500",
                textTransform: "capitalize",
                textAlign: "left",
                width: "120px",
              }}
            >
              {title}
            </Typography>
            {showArrayIcon && <KeyboardArrowDownIcon />}
          </div>
        )}
      </Button>
    </Container>
  );

  return to ? (
    // <NavLink to={to} onClick={()=>{
    <NavLink
      to={!showTable ? "#" : to}
      onClick={handleeClick}
      className="side-option"
    >
      <OptionContent />
    </NavLink>
  ) : (
    <OptionContent />
  );
};

export default Option;
