import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	toggleAddForm,
	toggleEditForm,
	// toggleViewDialog,
	toggleViewForm,
	toggleViewTable,
} from "../../redux/reducer/appReducer";
import { reset } from "../../redux/reducer/loginReducer";
// import { storageConstants } from "../../utils/globalConstant";
// import { HeaderContainer } from "../header/Container";

export const NavBarContainer = (props) => {
	const { showEditForm, showTable, showAddForm, showViewForm } = useSelector(
		(state) => state.app
	);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const onClicklogout = () => {
		secureLocalStorage.clear();
		Cookies.remove("jwtToken");
		dispatch(toggleViewTable(true));
		dispatch(toggleEditForm(false));
		dispatch(toggleViewForm(false));
		dispatch(toggleAddForm(false));
		dispatch(reset());
		navigate("/login");
	};

	const handleeClick = (event) => {
		if (!showTable) {
			event.preventDefault();
		}
	};

	const getIndexBasedOnRole = (role) => {
		if (role === "DEV") {
			return 1;
		} else if (role === "MAD") {
			return 4;
		} else {
			return 0;
		}
	};

	return {
		handleCloseUserMenu,
		handleOpenUserMenu,
		onClicklogout,
		anchorElUser,
		handleeClick,
		getIndexBasedOnRole,
		showEditForm,
		showTable,
		showAddForm,
		showViewForm,
		dispatch,
		navigate,
	};
};
