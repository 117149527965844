import React from 'react'
import { useSelector } from 'react-redux';
import ContactWebTable from './ContactWebTable';
import ContactWebForm from './ContactWebForm';

export default function ContactWeb() {
	const { showTable, showViewForm } = useSelector(
		(state) => state.app
	);

	return (
		<>
			{showTable && <ContactWebTable />}
			{(showViewForm) && <ContactWebForm/>}
		</>
	);
};

