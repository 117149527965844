export const resetPasswordHeadings={
    resetPassword : "Reset Password",
    submit : "Submit"
}

export const resetPasswordTextMessage={
   emailText : "Don't worry! It happens. Create new password",
   error : "error",
   passwordErrorText : "Password does not match",
   passwordLength:"password must be greater than 5 characters"  
}
