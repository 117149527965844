import { blogPostLabels } from "../../assets/labels/blogPostLabels";
import { getDateFormat } from "../helper";

export const blogPostColumns = () => {

	const today = new Date();

	const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

	const blog_post_columns = [
		{
			field: blogPostLabels.blog_title_field,
			headerName: blogPostLabels.blog_title,
			// filter: false
		},
		{
			field: blogPostLabels.category_field,
			headerName: blogPostLabels.category,
		},
		{
			field: blogPostLabels.short_description_field,
			headerName: blogPostLabels.short_description,
			// filter: false
		},
		// {
		// 	field: blogPostLabels.created_date_field,
		// 	headerName: blogPostLabels.created_date,
		// },
		// {
		// 	field: blogPostLabels.status_field,
		// 	headerName: blogPostLabels.status,
		// },
		{
			field: blogPostLabels.created_date_field,
			headerName: blogPostLabels.created_date,
			filter: 'agDateColumnFilter',
			filterParams: {
				minValidDate: "2000-01-08",
				maxValidDate: today,
				comparator: (filterLocalDateAtMidnight, cellValue) => {
					// debugger;
					var cellValue = getDateFormat(cellValue)
					// console.log("cellValue---------", cellValue)
					if (!cellValue) return -1;
				
					// Extract only the date part if cellValue contains time (e.g., "2024-10-29T06:04:48.514Z")
					const datePart = cellValue.includes('T') ? cellValue.split('T')[0] : cellValue;
					// console.log("datePart-------", datePart)
				
					// Split the date in DD-MM-YYYY format
					const [day, month, year] = datePart.split('-');
					// console.log("day, month, year-----", day, month, year)
					if (!day || !month || !year) return -1;
				
					// Parse the extracted date into a JavaScript Date object
					const cellDate = new Date(Number(year), Number(month) - 1, Number(day));
				
					// console.log("cellDate:", cellDate); 
				
					// Compare cellDate with filterLocalDateAtMidnight
					if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
						return 0;
					}
					return cellDate < filterLocalDateAtMidnight ? -1 : 1;
				},
				
			},
			valueFormatter: (params) => getDateFormat(params.value),
		},
		{
			field: blogPostLabels.status_field,
			headerName: blogPostLabels.status,
			valueFormatter: (params) => (params.value ? "Active" : "Inactive"),
		  },
		

	];

	return blog_post_columns;
};
