import { TextField } from "@mui/material";
import React from "react";

const NumericInputField = ({
  label,
  value,
  onChangeEvent,
  showLeftIcon = false,
  showRightIcon = false,
  leftComponent,
  rightComponent,
  isDisabled = false,
  isRequired = false,
}) => {
  return (
    <TextField
      size="small"
      type="number"
      label={label}
      value={value}
      required={isRequired}
      disabled={isDisabled}
      id="outlined-start-adornment"
      onChange={onChangeEvent}
      InputProps={{
        startAdornment: showLeftIcon && leftComponent,
        endAdornment: showRightIcon && rightComponent,
      }}
      className="global-form-text-input-field"
    />
  );
};

export default NumericInputField;
