import React from 'react';
import { Checkbox, Typography } from '@mui/material';

const CheckBox = ({ id, label, checked, onChange,isDisabled }) => {
  return (
    <div className="login-MuiBox-root">
      <Checkbox
        id={id}
        size="small"
        checked={checked}
        onChange={onChange}
        className = "login-MuiCheckbox-root" 
        disabled ={isDisabled}
      />
      <Typography>{label}</Typography>
    </div>
  );
};

export default CheckBox;