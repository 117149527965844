import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tab, Typography } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import BlogPostContainer from "./Container";
import FormActionButton from "../../common/FormActionButton";
import { blogPostLabels } from "../../assets/labels/blogPostLabels";
// import { getDateTimeFormat } from "../../utils/helper";
import InputFileUpload from "../../common/InputFileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { blogPostConstants } from "./Constants";
import DropdownField from "../../common/DropdownField";
import RichTextEditor from "../../common/BodyEditorField";

export default function BlogPostsForm() {
	const {
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		rowData,
		onChangeCategory,
		onChangeTitle,
		onChangeShortDesc,
		onChangeBlogDetailImage,
		onChangeImage,
		onClickSave,
		onClickReset,
		handleEditorChange,
		confirmationDialogOpen,
		handleRemoveConfirm,
		handleRemoveCancel,
		handleRemoveClick,
		onChangeStatus
	} = BlogPostContainer();
	return (
		<Grid className="global-form">
			<Box
				className="global-form-container"
				sx={{
					typography: "body1",
					height: "95%",
				}}
			>
				<TabContext value={tabIndex}>
					<Box className="global-form-tab-header">
						<TabList
							onChange={onChangeTabIndex}
							aria-label="lab API tabs example"
						>
							<Tab label="Blog Post Form" value="1" />
						</TabList>
					</Box>
					<TabPanel value="1" className="global-form-fields-container">
						<TextInputField
							label={blogPostLabels.blog_title}
							value={rowData?.title}
							helperText="min: 20char and max: 80char"
							onChangeEvent={onChangeTitle}
							isRequired={true}
							isDisabled={showViewForm}
							maxLength={80}
							minLength={20}
						/>

						<DropdownField
							label={blogPostLabels.category}
							value={rowData?.type}
							onChangeEvent={onChangeCategory}
							data={blogPostConstants.category}
							isDisabled={showViewForm}
							isRequired={true}
						/>

						<DropdownField
							label={blogPostLabels.status}
							value={rowData?.active}
							onChangeEvent={onChangeStatus}
							data={blogPostConstants.status}
							isDisabled={showViewForm}
							isRequired={false}
						/>
						{/* Blog Image */}
						<Grid
							sx={{
								display: "flex",
							}}
						>
							<InputFileUpload
								label={blogPostLabels.image}
								onChangeEvent={onChangeImage}
								isDisabled={showViewForm}
								isRequired={true}
							/>

							{rowData?.image ? (
								<>
									<img
										src={rowData?.image}
										alt="profile_image"
										style={{
											width: 200,
											height: 100,
											margin: 8,
											objectFit: "cover",
										}}
									/>
									{!showViewForm && (
										<CancelIcon
											onClick={() => handleRemoveClick('image')}
											sx={{ color: "#ff0000", fontSize: 16 }}
										/>

									)}
								</>
							) : (
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#00000099",
										fontSize: 12,
									}}
								>
									No blog image uploaded
								</Typography>
							)}
						</Grid>

						{/* Blog Detail Image */}
						<Grid
							sx={{
								display: "flex",
							}}
						>
							<InputFileUpload
								label={blogPostLabels.blog_detail_image}
								onChangeEvent={onChangeBlogDetailImage}
								isDisabled={showViewForm}
								isRequired={true}
							/>

							{rowData?.blogDetailImage ? (
								<>
									<img
										src={rowData?.blogDetailImage}
										alt="blogDetailImage"
										style={{
											width: 200,
											height: 100,
											margin: 8,
											objectFit: "cover",
										}}
									/>
									{!showViewForm && (
										<CancelIcon
											onClick={() => handleRemoveClick('blogDetailImage')}
											sx={{ color: "#ff0000", fontSize: 16 }}
										/>
									)}
								</>
							) : (
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#00000099",
										fontSize: 12,
									}}
								>
									No blog detail image uploaded
								</Typography>
							)}
						</Grid>
						{/* <TextInputField
							label={blogPostLabels.created_date}
							value={getDateTimeFormat(rowData?.createdDate)}
							isRequired={true}
							isDisabled={showViewForm}
						/> */}

						<TextInputField
							label={blogPostLabels.short_description}
							value={rowData?.shortDescription}
							onChangeEvent={onChangeShortDesc}
							isRequired={true}
							isDisabled={showViewForm}
							descriptionStyling={{ width: "1000px" }}
							size="large"
							multiline={true}
							rows={3}
							maxLength={200}
							minLength={100}
							helperText="min: 100char and max: 200char"
						/>
						{/* 
						<TextInputField
							label={blogPostLabels.desc}
							value={rowData?.desc}
							onChangeEvent={onChangeDesc}
							isRequired={true}
							isDisabled={showViewForm}
							descriptionStyling={{ width: "1000px" }}
							size="large"
							multiline={true}
							rows={8}
						/> */}

						<RichTextEditor
							label={blogPostLabels.desc}
							value={rowData?.desc}
							onChangeEvent={handleEditorChange}
							isRequired={true}
							// showErrorOnBlur={true}
							isDisabled={showViewForm}
							descriptionStyling={{ padding: "10px", borderRadius: "5px" }}
							className="custom-rich-text-editor"
							size="medium"
							helperText="min: 500char"
						/>
					</TabPanel>
					<FormActionButton
						onClickSave={onClickSave}
						onClickReset={onClickReset}
					/>
				</TabContext>
				<DialogBox />
			</Box>

			<Dialog
				open={confirmationDialogOpen} // This ensures the dialog is shown based on state.
				onClose={handleRemoveCancel} // Closes the dialog when clicking outside or pressing escape.
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to remove this image?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRemoveCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleRemoveConfirm} color='primary' autoFocus>
						Remove
					</Button>
				</DialogActions>
			</Dialog>



		</Grid>
	);
}
