import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  userValidateEmail,
  emailChange,
} from "../../redux/reducer/loginReducer";
import { otpValidationService } from "./Service";
import { toggleLoadingView } from "../../redux/reducer/appReducer";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

export default function OtpFunctionality() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpValidation, setOtpValidation] = useState("");
  const [otpMissMatch, setOTPMissMatch] = useState(false);
  const [otpResendText, setOtpResendText] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const savedUserLoginEmail = secureLocalStorage.getItem(
    storageConstants.forgotPasswordEmail
  );
  const setTimerInitialValue = secureLocalStorage.getItem(
    storageConstants.setTimer
  );
  const [timer, setTimer] = useState(setTimerInitialValue);

  const updateTimer = () => {
    if (timer > 0) {
      setTimer(timer - 1);
    }
  };

  const onChangeEmail = () => {
    dispatch(emailChange({ status: "idle" }));
    navigate("/forgotpassword");
  };

  const onResendOtp = () => {
    dispatch(userValidateEmail({ email: savedUserLoginEmail }));
    setOtpResendText(true);
    setOtpExpired(false);
    setTimer(60);
    secureLocalStorage.setItem(storageConstants.setTimer, 60);
    setOtp("");
    setOtpValidation(false);
    const otpExpirationTimeout = setTimeout(() => {
      setOtpExpired(true);
      setOtpResendText(false);
    }, 60000);
    return () => clearTimeout(otpExpirationTimeout);
  };

  const onChangeOtp = (event) => {
    setOtp(event);
    setOTPMissMatch(false);
  };

  const onSubmit = async () => {
    dispatch(toggleLoadingView(true));
    if (!otpExpired) {
      const otpComparision = await otpValidationService.validateOtp({
        email: savedUserLoginEmail,
        otp,
      });
      setOtpValidation(otpComparision?.message);
      if (otpComparision?.result !== null) {
        navigate("/resetPassword");
        setOtpResendText(false);
        dispatch(toggleLoadingView(false));
      } else {
        setOTPMissMatch(true);
        dispatch(toggleLoadingView(false));
      }
    }
    setOTPMissMatch(true);
  };

  useEffect(() => {
    setOtpResendText(true);
    setOtpExpired(false);
    const otpExpirationInterval = setInterval(() => {
      updateTimer();
      secureLocalStorage.setItem(storageConstants.setTimer, timer);
      if (timer <= 0) {
        clearInterval(otpExpirationInterval);
        setOtpExpired(true);
        setOtpResendText(false);
      }
    }, 1000);

    return () => clearInterval(otpExpirationInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return {
    onSubmit,
    onChangeOtp,
    onResendOtp,
    onChangeEmail,
    otpMissMatch,
    otp,
    otpValidation,
    otpResendText,
    otpExpired,
    timer,
  };
}
