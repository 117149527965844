import { apiServices } from "../../service/apiService";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

const url = `company/`;

const validateEmail = async (data) => {
  const response = await apiServices.postApi(
    `${url}${window.location.hostname}/${secureLocalStorage.getItem(
      storageConstants.secretkey
    )}/forgotPassword`,
    data
  );
  return response;
};

export const forgotPasswordService = {
  validateEmail,
};
