import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tableComponentService } from "./service";

const initialState = {
  tableData: {
    status: "idle",
    data: [],
  },
};

export const getTableData = createAsyncThunk("table/data", async (url) => {
  const response = await tableComponentService.getTableData(url);
  return response;
});

const tableReducerSlice = createSlice({
  name: "table",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTableData.pending, (state, action) => {
        state.tableData.status = "loading";
      })
      .addCase(getTableData.fulfilled, (state, action) => {
        state.tableData.status = "success";
        state.tableData.data = action.payload?.result;
      })
      .addCase(getTableData.rejected, (state, action) => {
        state.tableData.status = "failed";
      });
  },
});

// export const {} = tableReducerSlice.actions;
export default tableReducerSlice.reducer;
