import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { userColumns } from "../../utils/tableColumns/user";
import { UserContainer } from "./Container";
import secureLocalStorage from "react-secure-storage";
import { userUrlConstants } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";

const UserTable = ({statusf}) => {
  const userColumnFields = userColumns();
  const {
    onClickDelete,
    onClickUserAddForm,
    onClickUserEditForm,
    onClickUserViewForm,
  } = UserContainer();

  return (
    <TableComponent
      columnFields={userColumnFields}
      url={`${userUrlConstants.getTableDataUrl}/${secureLocalStorage.getItem(
        storageConstants.companyId
      )}`}
      statusf = {statusf}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickUserAddForm}
      onClickEditForm={onClickUserEditForm}
      onClickViewForm={onClickUserViewForm}
    />
  );
};

export default UserTable;
