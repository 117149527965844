import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { EndUsersColumns } from "../../utils/tableColumns/allEndUsers";
import { UsersContainer } from "./Container";
import { endUserUrlConstants } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";
import secureLocalStorage from "react-secure-storage";

const UsersTable = ({statusf}) => {
  const usersColumnFields = EndUsersColumns();
  const {
    onClickDelete,
    onClickCompanyAddForm,
    onClickCompanyEditForm,
    onClickCompanyViewForm,
  } = UsersContainer();
  const userRole = secureLocalStorage.getItem(storageConstants.role);


  return (
    <TableComponent
      columnFields={usersColumnFields}
      url={endUserUrlConstants.getTableDataUrl}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickCompanyAddForm}
      onClickEditForm={onClickCompanyEditForm}
      onClickViewForm={onClickCompanyViewForm}
      statusf = {statusf}
      showViewBtn = {!["SAD","MSAD", "SM"].includes(userRole)}
      showAddBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
      showEditBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
      showDeleteBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
    />
  );
};

export default UsersTable;
