import { useEffect, useState } from 'react';
import { MetaRobDataUrlConstants } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedRowData,
	toggleLoadingView,
	toggleToastView,
} from '../../redux/reducer/appReducer';
import { metaRobDataService } from './Service';
import { getTableData } from '../tableComponent/tableComponent.slice';
import { CommonContainer } from '../../utils/CommonFunction';


export const MetaRobDataContainer = () => {
	const { showEditForm, showAddForm, showViewForm, selectedRowData } =
		useSelector((state) => state.app);
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState('1');
	const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
		CommonContainer();

	const metaRobDataInfo = {
		propTypeTitle: '',
		facingTitle: '',
	}

	const [rowData, setRowData] = useState(metaRobDataInfo);
	const [resetData, setResetData] = useState({});


	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};


	const onChangePropertyType = (event) => {
		setRowData({ ...rowData, propTypeTitle: event.target.value });
	};
	const onChangeFacing = (event) => {
		setRowData({ ...rowData, facingTitle: event.target.value });
	};

	const onClickPropertyTypeSave = async () => {
		if (rowData?.propTypeTitle.trim() === "" ) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill  the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await metaRobDataService.savePropertyTypeDataService(rowData);
			} 
			// else {
			// 	response = await metaRobDataService.updatePropertyDataService(rowData);
			// }
			if (response.result.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(getTableData(MetaRobDataUrlConstants.getTablePropertyTypeDataUrl));
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};

	const onClickFacingSave = async () => {
		if (rowData?.facingTitle.trim() === "" ) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill  the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await metaRobDataService.saveFacingDataService(rowData);
			} 
			// else {
			// 	response = await metaRobDataService.updatePropertyDataService(rowData);
			// }
			if (response.result.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(getTableData(MetaRobDataUrlConstants.getTableFacingDataUrl));
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};

	const onClickReset = () => {
		setRowData(resetData);
	};

	const onClickPropertyTypeAddForm = () => {
		onClickAddForm();
	};
	const onClickFacingAddForm = () => {
		onClickAddForm();
	};

	const onClickPropertyTypeDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await metaRobDataService.deletePropertyTypeDataService(
			selectedRowData?._id
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(MetaRobDataUrlConstants.getTablePropertyTypeDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};
	
	const onClickFacingDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await metaRobDataService.deleteFacingDataService(
			selectedRowData?._id
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(MetaRobDataUrlConstants.getTableFacingDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};

	return {
		onClickPropertyTypeAddForm,
		onClickFacingAddForm,
		onClickPropertyTypeDelete,
		onClickFacingDelete,
		onChangePropertyType,
		onChangeFacing,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		rowData,
		onClickPropertyTypeSave,
		onClickFacingSave,
		onClickReset,
	};
};