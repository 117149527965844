export const licenseRequestLabels = {

    company_name_field: "companyName",
    company_name: "Company Name",

    user_name_field: "userName",
    user_name: "User Name",

    email_field: "email",
    email: "Email",

    mobile_field: "mobile",
    mobile: "Mobile",

    type_of_subscription_field: "licenseType",
    type_of_subscription: "Type Of Subscription",

    requested_date_field: 'createdAt',
    requested_date: 'Requested Date',

    message_field: 'message',
    message: 'Message',

    // status_field: 'status',
    // status: 'Status'
  
  };
  