import { apiServices } from "../../service/apiService";
import { licenseUrlConstants } from "./Constants";

const sendLicensDataService = async (data) => {
  return await apiServices.postApi(
    licenseUrlConstants.sendLicenseDataurl,
    data
  );
};

export const licenseService = {
    sendLicensDataService
};
