import React from 'react'
import TableComponent from '../tableComponent/TableComponent';
// import secureLocalStorage from 'react-secure-storage';
// import { storageConstants } from '../../utils/globalConstant';
import { blogPostColumns } from '../../utils/tableColumns/blogPostColumns';
import { blogPostUrlConstants } from './Constants';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import BlogPostContainer from './Container';

export default function BlogPostsTable() {

    const blogPostColumnFields = blogPostColumns();
	const {
		onClickBlogPostViewForm,
        onClickBlogPostAddForm,
        onClickBlogPostEditForm,
		showDeleteConfirmation,
		handleDeleteConfirm,
		handleDeleteCancel,
		handleDeleteClick,
	} = BlogPostContainer();

	// const userRole = secureLocalStorage.getItem(storageConstants.role);
    return (
		<>
			<Dialog
				open={showDeleteConfirmation}
				onClose={handleDeleteCancel}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to delete this item?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color='primary' autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<TableComponent
			columnFields={blogPostColumnFields}
			url={blogPostUrlConstants.getTableDataUrl}
			onClickViewForm={onClickBlogPostViewForm}
            onClickAddForm={onClickBlogPostAddForm}
            onClickEditForm={onClickBlogPostEditForm}
            onClickDelete={handleDeleteClick}
			// showAddBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
			// showEditBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
			// showDeleteBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
		// showViewBtn = {!["SAD", "MSAD", "SM"].includes(userRole)}
		/>
		</>
	
	);
}
