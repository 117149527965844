import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginService } from "../../components/login/Service";
import { forgotPasswordService } from "../../components/forgotPassword/Service";
import { userLoginAction } from "./appReducer";
const initialState = {
  userLogin: {
    status: "idle",
    data: {},
    validateEmail:{},
    validateOtp:[],
    email: ''
  },
};

export const userLoginActions = createAsyncThunk("app/login", async (data) => {
    const response = await loginService.loginApi(data)
    return response;
});

export const userValidateEmail = createAsyncThunk("app/validateEmail", async (data) => {
  const response = await forgotPasswordService.validateEmail(data)
  return response;
});

const loginReducerSlice = createSlice({
  name: "user/login",
  initialState,
  reducers: {
    updateUserEmail: (state, action) => {
      state.userLogin.email = action.payload;
    },
    emailChange : (state, action) => {
      state.userLogin.status = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginActions.pending, (state, action) => {
        state.userLogin.status = "loading";
      })
      .addCase(userLoginActions.fulfilled, (state, action) => {
        state.userLogin.status = "success";
        state.userLogin.data = action.payload
      })
      .addCase(userLoginActions.rejected, (state, action) => {
        state.userLogin.status = "failed";
      })
      .addCase(userValidateEmail.pending, (state, action) => {
        state.userLogin.status = "loading";
      })
      .addCase(userValidateEmail.fulfilled, (state, action) => {
        state.userLogin.status = "success";
        state.userLogin.validateEmail = action.payload
      })
      .addCase(userValidateEmail.rejected, (state, action) => {
        state.userLogin.status = "failed";
      })
  },
});

export const { updateUserEmail,emailChange,reset } = loginReducerSlice.actions;
export default loginReducerSlice.reducer;