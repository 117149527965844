import React from 'react';
import { useSelector } from 'react-redux';
import MetaRobDataTable from './MRDataTable';
import PropertyTypeForm from './MetaRobDataForm';

const MetaRobDataDashboard = () => {
	const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
		(state) => state.app
	  );
	
	  return (
		<>
		  {showTable && <MetaRobDataTable/>}
		  {(showAddForm || showEditForm || showViewForm) && <PropertyTypeForm/> }
		</>
	  );

}

export default MetaRobDataDashboard;
