import React from "react";
import {
	Typography,
	Button,
	Grid,
	InputAdornment,
	IconButton,
} from "@mui/material";
import "./Style.scss";
import profile from "../../assets/images/profile_img.png";
import { ProfileContainer } from "./Container";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import { profileConstants, profileLabels } from "./Constants";
import InputFileUpload from "../../common/InputFileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

export default function Profile() {
	const {
		tabIndex,
		onChangeTabIndex,
		onChangeFirstName,
		onChangeLastName,
		onChangeCompanyName,
		onChangeEmail,
		onChangeMobile,
		onChangeRole,
		onClickProfileEditForm,
		onClickCancel,
		onClickProfileEditContent,
		showEditForm,
		rowData,
		showTable,
		onClickProfileUpdate,
		showAddForm,
		onChangeProfileImage,
		onRemoveImage,
		onChangeNewPassword,
		onChangeConfirmPassword,
		onClickChangePasswordUpdate,
		onClickChangePasswordCancel,
		onClickChangePasswordContent,
		onChangeOldPassword,
		handleEditProfileClick,
		handleChangePasswordClick,
		showProfileForm,
		showPasswordForm,
		passwordValidation,
		passwordData,
		userDataa,
		validateOldPassword,
		validatePassword
	} = ProfileContainer();

	return (
		<>
			<div className="global-configurations-profile-card">
				{showTable && (
					<div className="profile-container">
						<div className="profile">
							<div>
								<img src={rowData.image || profile} className="profile-img" />
							</div>
							<div className="profile-buttons">
								<Button
									variant="outlined"
									className="global-form-action-button edit-profile-button"
									onClick={handleEditProfileClick}
								>
									{profileConstants.editProfileContent}
								</Button>
								<Button
									variant="outlined"
									className="global-form-action-button change-password"
									onClick={handleChangePasswordClick}
								>
									{profileConstants.changePassword}
								</Button>
							</div>
						</div>
						<div className="userdata">
							<div>
								<Typography className="data-label">
									{profileLabels.firstName}
								</Typography>
								<div className="box">{rowData.firstName || ""}</div>
							</div>
							<div>
								<Typography className="data-label">
									{profileLabels.lastName}
								</Typography>
								<div className="box">{rowData.lastName || ""}</div>
							</div>
							<div>
								<Typography className="data-label">
									{profileLabels.companyName}
								</Typography>
								<div className="box">{rowData.companyName || ""}</div>
							</div>
							<div>
								<Typography className="data-label">
									{profileLabels.mobile}
								</Typography>
								<div className="box">{rowData.mobile || ""}</div>
							</div>
							<div>
								<Typography className="data-label">
									{profileLabels.email}
								</Typography>
								<div className="box">{rowData.email || ""}</div>
							</div>
							<div>
								<Typography className="data-label">
									{profileLabels.role}
								</Typography>
								<div className="box">{rowData.role || ""}</div>
							</div>
						</div>
					</div>
				)}

				<DialogBox />

				{showEditForm && showProfileForm && (
					<div className="global-configurations-profile-styles">
						<Grid
							className="profile-upload"
							sx={{
								display: "grid",
							}}
						>
							{rowData?.image ? (
								<>
									<CancelIcon
										onClick={() => onRemoveImage()}
										sx={{ color: "#ff0000", fontSize: 16 }}
									/>
									<img
										src={rowData?.image}
										alt="profile_image"
										style={{
											width: 350,
											height: 350,
											// margin: 8,
											objectFit: "cover",
											borderRadius: 10,
										}}
									/>
								</>
							) : (
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#00000099",
										fontSize: 12,
									}}
								>
									No image uploaded
								</Typography>
							)}
							<InputFileUpload
								className="upload"
								label={profileLabels.image}
								onChangeEvent={onChangeProfileImage}
								isRequired={false}
							/>
						</Grid>

						<div className="profile-edit-form">
							<TextInputField
								className="input-text"
								label={profileLabels.firstName}
								value={rowData?.firstName}
								isRequired={true}
								onChangeEvent={onChangeFirstName}
							/>
							<TextInputField
								className="input-text"
								label={profileLabels.lastName}
								value={rowData?.lastName}
								onChangeEvent={onChangeLastName}
							/>
							<TextInputField
								className="input-text"
								label={profileLabels.companyName}
								value={rowData?.companyName}
								isRequired={true}
								isDisabled={showEditForm}
								onChangeEvent={onChangeCompanyName}
							/>
							<TextInputField
								className="input-text"
								label={profileLabels.mobile}
								value={rowData?.mobile}
								isRequired={true}
								onChangeEvent={onChangeMobile}
							/>
							<TextInputField
								className="input-text"
								label={profileLabels.email}
								value={rowData?.email}
								isRequired={true}
								onChangeEvent={onChangeEmail}
								isDisabled={showEditForm}
							/>
							<TextInputField
								className="input-text"
								label={profileLabels.role}
								value={rowData?.role}
								isRequired={true}
								onChangeEvent={onChangeRole}
								isDisabled={showEditForm}
							/>
						</div>
					</div>
				)}

				{showEditForm && showProfileForm && (
					<div className="update-cancel">
						<Button
							variant="outlined"
							className="global-form-action-button"
							onClick={onClickProfileUpdate}
						>
							{profileConstants.update}
						</Button>
						<Button
							variant="outlined"
							className="global-form-action-button"
							onClick={onClickCancel}
						>
							{profileConstants.cancel}
						</Button>
					</div>
				)}

				{showEditForm && showPasswordForm && (
					<>
						<div className="global-configurations-profile-styles">
							
							<div className="password-edit-form">
							{/* <div className="change-password">Change Password</div> */}
								<TextInputField
									className="input-text"
									label={profileLabels.oldPassword}
									onChangeEvent={onChangeOldPassword}
									isRequired={true}
									showRightIcon={!!passwordData.oldPassword}
									rightComponent={
										passwordData.oldPassword && (
											<InputAdornment position="end">
												<IconButton>
													{validateOldPassword(passwordData.oldPassword) ? (
														<CheckIcon color="success" />
													) : (
														<CancelIcon color="error" />
													)}
												</IconButton>
											</InputAdornment>
										)
									}
									fullWidth
								/>
								<TextInputField
									className="input-text"
									label={profileLabels.newPassword}
									onChangeEvent={onChangeNewPassword}
									type="password"
									isRequired={true}
									showRightIcon={!!passwordData.newPassword}
									rightComponent={
										passwordData.newPassword && (
											<InputAdornment position="end">
												<IconButton>
													{Object.values(passwordValidation).every((valid) => valid) ? (
														<CheckIcon color="success" />
													) : (
														<CancelIcon color="error" />
													)}
												</IconButton>
											</InputAdornment>
										)
									}
								/>


								<div className="password-validation">
								<Typography
										variant="body2"
										// color={passwordValidation.minLength ? "green" : "red"}
									>
										Password must fulfill following criteria:
									</Typography>
									
									<Typography
										variant="body2"
										color={passwordValidation.minLength ? "green" : "red"}
									>
										* Use 8 or more characters
									</Typography>
									<Typography
										variant="body2"
										color={passwordValidation.uppercase ? "green" : "red"}
									>
										* Use At least one uppercase letter(e.g. ABC)
									</Typography>
									<Typography
										variant="body2"
										color={passwordValidation.lowercase ? "green" : "red"}
									>
										* Use At least one lowercase letter(e.g. abc)
									</Typography>
									<Typography
										variant="body2"
										color={passwordValidation.numeric ? "green" : "red"}
									>
										* Use a Number(e.g. 123)
									</Typography>
									<Typography
										variant="body2"
										color={passwordValidation.specialChar ? "green" : "red"}
									>
										* Use At least one special character(e.g. @#$%*&)
									</Typography>
									<Typography
										variant="body2"
										color={passwordValidation.samePassword ? "green" : "red"}
									>
										* New password shouldn't match with old password
									</Typography>
								</div>


								<TextInputField
									className="input-text"
									label={profileLabels.confirmPassword}
									onChangeEvent={onChangeConfirmPassword}
									type="password"
									isRequired={true}
									showRightIcon={!!passwordData.confirmPassword}
									rightComponent={
										passwordData.confirmPassword && (
											<InputAdornment position="end">
												<IconButton>
													{passwordData.newPassword === passwordData.confirmPassword ? (
														<CheckIcon color="success" />
													) : (
														<CancelIcon color="error" />
													)}
												</IconButton>
											</InputAdornment>
										)
									}
								/>
								<Typography
										variant="body2"
										className="password-validation"
										// color={passwordValidation.minLength ? "green" : "red"}
									>
									Note: The New Password and the Confirm Password should be same.  
									</Typography>
							</div>
						</div>
						<div className="password-update-cancel">
							<Button
								variant="outlined"
								className="global-form-action-button"
								onClick={onClickChangePasswordUpdate}
							>
								{profileConstants.update}
							</Button>
							<Button
								variant="outlined"
								className="global-form-action-button"
								onClick={onClickCancel}
							>
								{profileConstants.cancel}
							</Button>
						</div>
					</>
				)}
			</div>
		</>
	);
}
