import React from 'react'
import { LicenseContainer } from './Container';
import "./Style.scss";
export default function License() {

	const {
		showTable,
		userData,
		rowData,
		onClickSave,
		onChangeMessage
	} = LicenseContainer();

	return (
		<>
			<div className="global-configurations-profile-card">
				{showTable && (
					<div className='license-container'>

						<div className='license-data'>
							<div>LICENSE TYPE :  {rowData?.licenseType}</div>
							<div>START DATE : {rowData?.startDate}</div>
							<div>EXPIRE DATE: {rowData?.expireDate}</div>
						</div>


						<div className='license-request'>
							<div>Request to your company upgrade license:</div>
							<textarea
								name="text"
								rows="14"
								cols="10"
								wrap="soft"
								value={rowData.message}
								onChange={onChangeMessage}
							/>
							<button className='send-req-btn' onClick={onClickSave}>Send Request</button>

						</div>


					</div>

				)}

			</div>
		</>
	)
}
