import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";
import { setActiveIndex } from "../../redux/reducer/appReducer";

export const DashboardContainer = () => {
  const userRole = secureLocalStorage.getItem(storageConstants.role);
  const dispatch = useDispatch();

  const getInitialIndex = () => {
    const selectedOption = secureLocalStorage.getItem(
      storageConstants.selectedOption
    );
    if (selectedOption === null) {
      if (["DEV"].includes(userRole)) {
        dispatch(setActiveIndex(1));
      } else if (["MAD"].includes(userRole)) {
        dispatch(setActiveIndex(4));
      } else {
        dispatch(setActiveIndex(0));
      }
    } else {
      dispatch(setActiveIndex(selectedOption));
      // return Number(selectedOption);  // Ensure it returns a number type
    }
  };
  useEffect(() => {
    getInitialIndex();
  }, []);

  // const [index, setIndex] = useState(getInitialIndex);

  // const [index, setIndex] = useState(
  //   secureLocalStorage.getItem(storageConstants.selectedOption) === null
  //     ? 0
  //     : secureLocalStorage.getItem(storageConstants.selectedOption)
  // );
  const { showTable, showSideMenu, activeIndex } = useSelector(
    (state) => state.app
  );

  const onChangeIndex = (value) => {
    if (showTable) {
      // setIndex(value);
      dispatch(setActiveIndex(value));
      secureLocalStorage.setItem(storageConstants.selectedOption, value);
    }
  };

  return {
    index: activeIndex,
    onChangeIndex,
    showSideMenu,
  };
};
