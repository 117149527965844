import { platformAdminLabels } from "../../assets/labels/platformAdmin";

export const plaformAdminColumns = () => {
  const platform_admin_columns = [
    {
      field: platformAdminLabels.platform_admin_username_field,
      headerName: platformAdminLabels.platform_admin_username,
    },
    {
      field: platformAdminLabels.platform_admin_email_field,
      headerName: platformAdminLabels.platform_admin_email,
    },
    {
      field: platformAdminLabels.platform_admin_mobile_field,
      headerName: platformAdminLabels.platform_admin_mobile,
    },
    {
      field: platformAdminLabels.platform_admin_role_field,
      headerName: platformAdminLabels.platform_admin_role,
    },
    {
      field: platformAdminLabels.platform_admin_status_field,
      headerName: platformAdminLabels.platform_admin_status,
      valueFormatter: (params) => (params.value ? "Active" : "Inactive"),
    },
  ];

  return platform_admin_columns;
};
