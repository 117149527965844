import { apiServices } from '../../service/apiService';
import { AppConfigUrlConstants } from './Constants';

const getSelectedAppConfigDataService = async (id) => {
	return await apiServices.getApi(
		`${AppConfigUrlConstants.getSelectedAppConfigDataUrl}/${id}`
	);
};

const getAppConfigDataService = async () => {
		return await apiServices.getApi(
			`${AppConfigUrlConstants.getTableDataUrl}`
		);
	};

const saveAppConfigDataService = async (data) => {
	return await apiServices.postApi(
		AppConfigUrlConstants.saveAppConfigDataurl,
		data
	);
};

const updateAppConfigDataService = async (data) => {
	return await apiServices.updateApi(
		AppConfigUrlConstants.updateAppConfigDataurl,
		data
	);
};

const deleteAppConfigDataService = async (id) => {
	return await apiServices.deleteApi(
		`${AppConfigUrlConstants.deleteAppConfigDataUrl}/${id}`
	);
};

export const AppConfigService = {
		getSelectedAppConfigDataService,
		saveAppConfigDataService,
		updateAppConfigDataService,
		deleteAppConfigDataService,
		getAppConfigDataService
};
