import { Box, Grid, Tab, FormControl, OutlinedInput, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState } from "react";
import '../../themes/globalStyle.scss';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DialogBox from '../../common/DialogBox';
import TextInputField from '../../common/TextInputField';
import { MetaRobDataContainer } from './Container';
import FormActionButton from '../../common/FormActionButton';
import { metaRobDataLabels } from '../../assets/labels/metaRobDataLabels';



const MetaRobDataForm = () => {
	const {
		onChangePropertyType,
		onChangeFacing,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		rowData,
		onClickPropertyTypeSave,
		onClickReset,
		onClickFacingSave,

	} = MetaRobDataContainer();

	const [selectedFilter, setSelectedFilter] = useState('propertyType'); // State to track the selected filter

	const handleFilterChange = (event) => {
		setSelectedFilter(event.target.value); // Update the selected filter
	};


	return (
		<>

			<FormControl style={{ marginTop: "35px", marginLeft: "10px" }}>
				<InputLabel id="filter-select-label">Filter</InputLabel>
				<Select
					labelId="filter-select-label"
					id="filter-select"
					value={selectedFilter}
					onChange={handleFilterChange}
					input={<OutlinedInput label="Filter" />}
					sx={{ mr: 1 }}
					size="small"
					className="tb-head-text-field"
				>
					<MenuItem value="propertyType">Property Type</MenuItem>
					<MenuItem value="facing">Facing</MenuItem>
				</Select>
			</FormControl>

			<Grid className="global-form">
				<Box
					className="global-form-container"
					sx={{
						typography: "body1",
						height: "95%",
					}}
				>
					<TabContext value={tabIndex}>
						<Box className="global-form-tab-header">
							<TabList
								onChange={onChangeTabIndex}
								aria-label="lab API tabs example"
							>
								<Tab label="MetaRob Data Form" value="1" />
							</TabList>
						</Box>

						<TabPanel value="1" className="global-form-fields-container">

							{selectedFilter === 'propertyType' && (
								<TextInputField
									label={metaRobDataLabels.property_type}
									value={rowData?.propTypeTitle}
									onChangeEvent={onChangePropertyType}
									isRequired={true}
									isDisabled={showViewForm}
								/>
							)}

							{selectedFilter === 'facing' && (
								<TextInputField
									label={metaRobDataLabels.facing}
									value={rowData?.facingTitle}
									onChangeEvent={onChangeFacing}
									isRequired={true}
									isDisabled={showViewForm}
								/>
							)}

						</TabPanel>
						<FormActionButton
							onClickSave={() => {
								onClickPropertyTypeSave();
								onClickFacingSave();
							}}
							onClickReset={onClickReset}
						/>
					</TabContext>
					<DialogBox />


				</Box>


			</Grid>

		</>
	);
};
export default MetaRobDataForm;