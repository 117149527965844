import { configureStore } from "@reduxjs/toolkit";
import appReducerSlice from "./reducer/appReducer";
import tableReducerSlice from "../components/tableComponent/tableComponent.slice";
import loginReducerSlice from './reducer/loginReducer';

const store = configureStore({
  reducer: {
    app: appReducerSlice,
    table: tableReducerSlice,
    userLoginDetails: loginReducerSlice,
    resetList: (state) => {
      return (state = {});
    },
  },
});

export default store;
