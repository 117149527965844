import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleToastView } from "../redux/reducer/appReducer";

const SnackBar = () => {
  const dispatch = useDispatch();

  const { toasting } = useSelector((state) => state.app);
  const { isError, showToast, message } = toasting;

  const handleClose = () => {
    dispatch(
      toggleToastView({
        showToast: false,
        message: message,
        isError: isError,
      })
    );
  };
  return (
    <Snackbar
      open={showToast}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={isError ? "error" : "success"}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
