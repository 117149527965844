import { subscriptionWebLabels } from "../../assets/labels/subscriptionWebLabels";
import { getDateTimeFormat } from "../helper";

export const subscriptionWebColumns = () => {

	const subscription_web_columns = [

		{
			field: subscriptionWebLabels.email_field,
			headerName: subscriptionWebLabels.email,
		},
		{
			field: subscriptionWebLabels.created_date_field,
			headerName: subscriptionWebLabels.created_date,
			valueFormatter: (params) => getDateTimeFormat(params.value),
		},


	];

	return subscription_web_columns;
};
