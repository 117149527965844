import React from 'react'
import { Typography, TextField, Button} from "@mui/material";
import "./Style.scss";
import ResetPasswordFunctionality from './Container';
import CircleLoader from "../../common/CircleLoader";
import { useSelector } from "react-redux";
import { resetPasswordHeadings, resetPasswordTextMessage } from './Constants';
import { LoginFunctionality } from "../login/Container";
import Carousel from "react-material-ui-carousel"; 
import { headings } from '../login/Constants';
import aautiLogo from "../../assets/images/aauti_logo.png";

export default function ResetPassword(props) {

  const { isLoading } = useSelector((state) => state.app);
  const {
    newPassword,
    handleNewPasswordChange,
    confirmPassword,
    handleConfirmPasswordChange,
    onSubmit,
    passwordMatch,
    passwordValidation,
    passwordLength
  } = ResetPasswordFunctionality(props)
  const { items } = LoginFunctionality(props);

  const Item = (props) => {
    return (
      <div>
        <img
          src={props.item.image}
          className="image-styles"
          alt={props.item.name}
          key={props.item.key}
        />
        <div className="text-container">
          <Typography className="about-us-styles">
            {headings.aboutUs}
          </Typography>
          <Typography className="show-case-text-styles">
            {headings.showcase}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
    {isLoading && <CircleLoader/> }
    <div className="custom-card-container">
        <div className="sub-custom-card-container">
          <img src={aautiLogo}
            className="logo-styles"
            alt="ss"
            />
        <div className='reset-password-sub-custom-card-container'>
          <Typography className='reset-password-text'>{resetPasswordHeadings.resetPassword}</Typography>
                        <Typography variant='body2' className='reset-password-description'>{resetPasswordTextMessage.emailText}</Typography>
                        <TextField id="outlined-reset-new-password" label="New Password" variant="outlined" 
                        size="small"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        className="reset-password-textfield-custom-styles"
                        required
                        placeholder='New Password'
                        />
                         <TextField id="outlined-reset-confirm-password" label="Confirm Password" variant="outlined" 
                        size="small"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="reset-password-textfield-custom-styles"
                        required
                        placeholder='Confirm Password'
                        />
                 {passwordValidation && !passwordMatch && (
                  <Typography className='reset-password-error-styling'>
                    {resetPasswordTextMessage.passwordErrorText}
                  </Typography>
                )}
                 {passwordLength && <Typography className="reset-password-error-styling">{resetPasswordTextMessage.passwordLength}</Typography>}
            <Button variant="contained" type="submit" onClick={onSubmit} className='reset-password-submit-button'>
              {resetPasswordHeadings.submit}
            </Button>
      </div>
    </div>
    <div className="custom-image-card">
          <Carousel
            animation="slide"
            indicatorIconButtonProps={{
              className: "indicator-icon-button-props",
            }}
            activeIndicatorIconButtonProps={{
              className: "active-indicator-icon-button-props",
            }}
            indicatorContainerProps={{
              className: "indicator-container-props",
            }}
            navButtonsWrapperProps={{
              className: "nav-button-wrapper-props",
            }}>
            {items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </Carousel>
        </div>
    </div>
  </div>
  )
}