import React from "react";
import { useSelector } from "react-redux";
import CompanyAdminUserTable from "./CompanyAdminUserTable";
import CompanyAdminUserForm from "./CompanyAdminUserForm";
import { useLocation } from "react-router-dom";

const CompanyAdminUser = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

     const {state }= useLocation()
  const roleF = state?.roleF ?? "All"

  return (
    <>
      {showTable && <CompanyAdminUserTable roleF = {roleF} />}
      {(showAddForm || showEditForm || showViewForm) && (
        <CompanyAdminUserForm />
      )}
    </>
  );
};

export default CompanyAdminUser;
