import { useState } from "react";
import { useSelector } from "react-redux";

export const SidebarContainer = () => {
  const { showSideMenu } = useSelector((state) => state.app);

  const [expandData, setExpandData] = useState({
    pricing: true,
    configuration: true,
    casualGames: true,
    settings: true,
  });

  const onChangeExpandCollapse = (value) => {
    setExpandData({
      ...expandData,
      [value]: !expandData[value],
    });
  };

  return {
    showSideMenu,
    expandData,
    onChangeExpandCollapse,
  };
};
