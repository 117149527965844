export const companyProjectConstants = {
  status: [
    {
      id: false,
      title: "Active",
    },
    {
      id: true,
      title: "Inactive",
    },
  ],
  metarobApi: [
    {
      id: "dialogflow",
      title: "Dialogflow",
    },
    {
      id: "convai",
      title: "Convai",
    },
  ],
};


export const companyProjectsUrlConstants = {
  getTableDataUrl: 'companyProject/table',
  getSelectedCompanyProjectsDataUrl: 'companyProject/table',
  saveCompanyProjectsDataurl: 'companyProject/register',
  updateCompanyProjectsDataurl: 'companyProject/update',
  deleteCompanyProjectsDataUrl: 'companyProject/delete',
  hardDeleteCompanyProjectsDataUrl: 'companyProject/hard/delete',
  companyConfigCompanyData: 'companyConfig/companyData',
  getCompanyNamesWithProjectsData: 'companyProject/companyWithProjects',
};