export const userConstants = {
  userRoles: [{ id: "EU", title: "End User" }],
  userGenders: [
    {
      id: "M",
      title: "Male",
    },
    {
      id: "F",
      title: "Female",
    },
  ],
  userLimitExceeds: "Users limits exceeded",
  status: [
    {
      id: true,
      title: "Active",
    },
    {
      id: false,
      title: "Inactive",
    },
  ],
};

export const userUrlConstants = {
  getTableDataUrl: "user/table",
  getSelectedUserDataUrl: "user/table",
  saveUserDataurl: "user/register",
  updateUserDataurl: "user/update",
  deleteUserDataUrl: "user/delete",
  userLimitDataUrl: "user/limit",
};
