export const platformAdminLabels = {
  platform_admin_username_field: "userName",
  platform_admin_username: "User Name",

  platform_admin_firstname_field: "firstName",
  platform_admin_firstname: "First Name",

  platform_admin_lastname_field: "lastName",
  platform_admin_lastname: "Last Name",

  platform_admin_email_field: "email",
  platform_admin_email: "Email",

  platform_admin_mobile_field: "mobile",
  platform_admin_mobile: "Mobile",

  platform_admin_role_field: "role",
  platform_admin_role: "Role",

  platform_admin_profile_image: "Profile Image",

  platform_admin_status_field: "active",
  platform_admin_status: "Status",
};
