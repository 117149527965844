import React from 'react';
import { useSelector } from 'react-redux';
import CompanyProjectsTable from './CompanyProjectsTable';
import CompanyProjectsForm from './CompanyProjectsForm';

const CompanyProjects = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

  return (
    <>
      {showTable && <CompanyProjectsTable />}
      {(showAddForm || showEditForm || showViewForm) && <CompanyProjectsForm />}
    </>
  );
};

export default CompanyProjects;