import React from 'react'
import { Typography, TextField, Button} from "@mui/material";
import "./Style.scss";
import ForgotPasswordFunctionality from './Container';
import { errorMessage } from '../login/Constants';
import { forgotPasswordHeadings, forgotPasswordTextMessage } from '../forgotPassword/Constants';
import CircleLoader from "../../common/CircleLoader";
import { useSelector } from "react-redux";
import { LoginFunctionality } from "../login/Container";
import Carousel from "react-material-ui-carousel"; 
import { headings } from '../login/Constants';
import aautiLogo from "../../assets/images/aauti_logo.png";


export default function ForgotPassword(props) {
  const { isLoading } = useSelector((state) => state.app);

  const {
    email,
    handleEmailChange,
    onSubmit,
    emailError,
    userNotFound,
    userLogin
  } = ForgotPasswordFunctionality(props)
  const { items } = LoginFunctionality(props);

  const Item = (props) => {
    return (
      <div>
        <img
          src={props.item.image}
          className="image-styles"
          alt={props.item.name}
          key={props.item.key}
        />
        <div className="text-container">
          <Typography className="about-us-styles">
            {headings.aboutUs}
          </Typography>
          <Typography className="show-case-text-styles">
            {headings.showcase}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
    {isLoading && <CircleLoader/> }
    <div className="custom-card-container">
        <div className="sub-custom-card-container">
          <img
            src={aautiLogo}
            className="logo-styles"
            alt="ss"
            />
        <div className='forgot-password-sub-custom-card-container'>
          <Typography className='forgot-password-text'>{forgotPasswordHeadings.forgotPassword}</Typography>
                        <Typography variant='body2' className='forgot-password-description'>{forgotPasswordTextMessage.emailText}</Typography>
                        <TextField id="outlined-email" label="Email" variant="outlined" 
                        size="small"
                        value={email}
                        onChange={handleEmailChange}
                        className="textfield-custom-styles"
                        required
                        placeholder='Enter your Email ID'
                        />
                 {emailError && <Typography className="forgot-password-error-styling">{errorMessage.email}</Typography>}
                 {userNotFound && <Typography className="forgot-password-error-styling">
                {userLogin?.validateEmail?.message}
                 </Typography>}
                 
            <Button variant="contained" type="submit" onClick={onSubmit} className='forgot-password-submit-button'>
              {forgotPasswordHeadings.sendOTP}
            </Button>
        </div>
    </div>
    <div className="custom-image-card">
          <Carousel
            animation="slide"
            indicatorIconButtonProps={{
              className: "indicator-icon-button-props",
            }}
            activeIndicatorIconButtonProps={{
              className: "active-indicator-icon-button-props",
            }}
            indicatorContainerProps={{
              className: "indicator-container-props",
            }}
            navButtonsWrapperProps={{
              className: "nav-button-wrapper-props",
            }}
          >
            {items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </Carousel>
        </div>
    </div>
    </div>
  )
}
