export const MetaRobDataUrlConstants = {
	//-------------------------Property Type----------------------------
	
	getTablePropertyTypeDataUrl: 'propertyType/getPropertyTypes',
	// getSelectePropertyTypeDataUrl:  'propertyType/findOne',
	savePropertyTypeDataurl: 'propertyType/insertPropertyType', 
	// updatePropertyTypeDataurl: 'propertyType/update', 
	deletePropertyTypeDataUrl: 'propertyType/deletePropertyType',

	//-----------------------------------------Facing---------------------------------------

	getTableFacingDataUrl: 'facing/getFacingbits',
	// getSelecteFacingDataUrl:  'facing/findOne',
	saveFacingDataurl: 'facing/insertFacing', 
	// updateFacingDataurl: 'facing/update', 
	deleteFacingDataUrl: 'facing/deleteFacing',

};