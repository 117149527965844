import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  userLogin: {
    status: "idle",
    data: {},
  },
  showSideMenu: true,
  showEditForm: false,
  showAddForm: false,
  showViewForm: false,
  showTable: true,
  showDialog: false,
  isLoading: false,
  toasting: {
    showToast: false,
    message: "",
    isError: false,
  },
  selectedRowData: {},
  activeIndex: 0
};

export const userLoginAction = createAsyncThunk("app/login", async (data) => {
  //   const response = await ""; import login service and use
  //   return response.data;
});

const appReducerSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSideMenu: (state, action) => {
      state.showSideMenu = action.payload;
    },
    toggleEditForm: (state, action) => {
      state.showEditForm = action.payload;
    },
    toggleAddForm: (state, action) => {
      state.showAddForm = action.payload;
    },
    toggleViewForm: (state, action) => {
      state.showViewForm = action.payload;
    },
    toggleViewTable: (state, action) => {
      state.showTable = action.payload;
    },
    toggleViewDialog: (state, action) => {
      state.showDialog = action.payload;
    },
    toggleLoadingView: (state, action) => {
      state.isLoading = action.payload;
    },
    toggleToastView: (state, action) => {
      state.toasting.showToast = action.payload.showToast;
      state.toasting.message = action.payload.message;
      state.toasting.isError = action.payload.isError;
    },
    setSelectedRowData: (state, action) => {
      state.selectedRowData = action.payload;
    },

    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAction.pending, (state, action) => {
        state.userLogin.status = "loading";
      })
      .addCase(userLoginAction.fulfilled, (state, action) => {
        state.userLogin.status = "success";
      })
      .addCase(userLoginAction.rejected, (state, action) => {
        state.userLogin.status = "failed";
      });
  },
});

export const {
  toggleEditForm,
  toggleAddForm,
  toggleViewForm,
  toggleViewTable,
  toggleViewDialog,
  toggleLoadingView,
  toggleToastView,
  setSelectedRowData,
  toggleSideMenu,
  setActiveIndex
} = appReducerSlice.actions;
export default appReducerSlice.reducer;
