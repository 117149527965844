import { apiServices } from "../../service/apiService";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

const url = `company/`;

const validateResetPassword = async (data) => {
  const response = await apiServices.postApi(
    `${url}${window.location.hostname}/${secureLocalStorage.getItem(
      storageConstants.secretkey
    )}/updatePassword`,
    data
  );
  return response;
};

export const resetPasswordService = {
  validateResetPassword,
};
