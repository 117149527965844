export const allEndUsersLabels = {
    
    first_name_field: "firstName",
    first_name: "First Name",

    last_name_field: "lastName",
    last_name: "Last Name",

    company_name_field: "companyName",
    company_name: "Company Name",

    email_field: "email",
    email: "Email",

    mobile_field: "mobile",
    mobile: "Mobile",

    status_field: "active",
    status: "Status"
  };
  