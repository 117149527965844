import {
	BrowserRouter,
	// createBrowserRouter,
	// createRoutesFromElements,
	Route,
	Routes,
} from "react-router-dom";
import Login from "../components/login/Login";
import Dashboard from "../components/dashboard/Dashboard";
import NotFound from "../components/notFound/NotFound";
import ForgotPassword from "../components/forgotPassword/ForgotPassword";
import ResetPassword from "../components/resetPassword/ResetPassword";
import OTPScreen from "../components/otp/OTPScreen";
import ProtectedRoute from "./ProtectedRoute";
import Company from "../components/company/Company";
// import MetaRobDashboard from "../components/metaRob/MetaRob";
import DropDownList from "../components/metaRob/DropDownList";
import CompanyProjects from "../components/CompanyProjects/CompanyProjects";
// import NewSideBar from "../components/sidebar/NewSideBar";
import Analytics from "../components/analytics/Analytics";
// import PropertyTable from "../components/metaRob/PropertyTable";
import AppConfiguration from "../components/appConfiguration/appConfiguration";
import CompanyAdminUser from "../components/companyAdminUser/CompanyAdminUser";
// import secureLocalStorage from "react-secure-storage";
// import { storageConstants } from "../utils/globalConstant";
import User from "../components/user/User";
// import { Typography } from "@mui/material";
import PlatformAdminUser from "../components/platformAdminUser/PlatformAdminUser";
import MetaRobDataDashboard from "../components/metaRobData/MetaRobData";
import Profile from "../components/profile/Profile";
import NotAuthorized from "../components/notAuthorized/NotAuthorized";
import AllEndUsers from "../components/allEndUsers/AllEndUsers";
import ChangePassword from "../components/profile/ChangePassword";
import License from "../components/license/License";
import LicenseRequest from "../components/licenseRequest/LicenseRequest";
import ContactWeb from "../components/contactUsWeb/ContactWeb";
import SubscriptionWeb from "../components/subscriptionWeb/SubscriptionWeb";
import BlogPosts from "../components/blogPosts/BlogPosts";

const Router = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/login" element={<Login />} />
			{/* <Route
				path="/"
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
			/> */}

			<Route path="/" element={<Dashboard />}>
				{/* {["SAD", "SP", "CSAD", "CM", "SM"].includes(
			secureLocalStorage.getItem(storageConstants.role)
		) && [ */}

				<Route
					path="/"
					element={
						<ProtectedRoute path="/">
							<Analytics />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/company"
					element={
						<ProtectedRoute path="/company">
							<Company />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dashboard/company"
					element={
						<ProtectedRoute path="/dashboard/company">
							<Company />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/company-projects"
					element={
						<ProtectedRoute path="/company-projects">
							<CompanyProjects />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/app-config"
					element={
						<ProtectedRoute path="/app-config">
							<AppConfiguration />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/license"
					element={
						<ProtectedRoute path="/license">
							<License />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/license-requests"
					element={
						<ProtectedRoute path="/license-requests">
							<LicenseRequest />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/contact-us-website"
					element={
						<ProtectedRoute path="/contact-us-website">
							<ContactWeb />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/subscription-website"
					element={
						<ProtectedRoute path="/subscription-website">
							<SubscriptionWeb />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/blog-website"
					element={
						<ProtectedRoute path="/blog-website">
							<BlogPosts />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/admin-users"
					element={
						<ProtectedRoute path="/admin-users">
							<CompanyAdminUser />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dashboard/admin-users"
					element={
						<ProtectedRoute path="/dashboard/admin-users">
							<CompanyAdminUser />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/users"
					element={
						<ProtectedRoute path="/users">
							<User />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dashboard/users"
					element={
						<ProtectedRoute path="/dashboard/users">
							<User />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/metarob"
					element={
						<ProtectedRoute path="/metarob">
							<DropDownList />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/metarob-data"
					element={
						<ProtectedRoute path="/metarob-data">
							<MetaRobDataDashboard />
						</ProtectedRoute>
					}
				/>

				{/* <Route
					path="/user-settings"
					element={
						<ProtectedRoute path="/user-settings">
							<Typography>User Data</Typography>
						</ProtectedRoute>
					}
				/> */}

				<Route
					path="/platform-admin-users"
					element={
						<ProtectedRoute path="/platform-admin-users">
							<PlatformAdminUser />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dashboard/platform-admin-users"
					element={
						<ProtectedRoute path="/dashboard/platform-admin-users">
							<PlatformAdminUser />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/all-users"
					element={
						<ProtectedRoute path="/all-users">
							<AllEndUsers />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dahsboard/all-users"
					element={
						<ProtectedRoute path="/dahsboard/all-users">
							<AllEndUsers />
						</ProtectedRoute>
					}
				/>

				{/* {["SAD", "SM", "DEV"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && [
					<Route path="company" element={<Company />} />,
					<Route path="company-projects" element={<CompanyProjects />} />,
					<Route path="/app-config" element={<AppConfiguration />} />,
				]} */}

				{/* {["CM", "CSAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && [
					<Route path="/license" element={<Typography>License</Typography>} />,
					<Route path="/admin-users" element={<CompanyAdminUser />} />,
				]}

				{["CM", "CSAD", "SP"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && [<Route path="/users" element={<User />} />]}

				{["SAD", "SM", "DEV", "MAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && [
					<Route path="/metarob-data" element={<MetaRobDataDashboard />} />,
					<Route
						path="/user-settings"
						element={<Typography>User Data</Typography>}
					/>,
				]}

				{["SAD", "SM", "DEV", "CSAD", "CM", "MAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && [
					<Route
						path="/metarob/:companyId/:projectId/:agentName"
						element={<MetaRobDashboard />}
					/>,
					<Route path="/metarob" element={<DropDownList />} />,
				]}

				{["SAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && [
					<Route
						path="/platform-admin-users"
						element={<PlatformAdminUser />}
					/>,
				]} */}
				<Route
					path="/profile"
					element={
						<ProtectedRoute path="/profile">
							<Profile />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/change-password"
					element={
						<ProtectedRoute path="/change-password">
							<ChangePassword />
						</ProtectedRoute>
					}
				/>

				{/* <Route path="/metarob/company=:companyId/projectName=:projectName/agentName=:agentName" element={<MetaRobDashboard />} /> */}
			</Route>

			<Route path="*" element={<NotFound />} />
			<Route path="/forgotpassword" element={<ForgotPassword />} />
			<Route path="/otp" element={<OTPScreen />} />
			<Route path="/resetPassword" element={<ResetPassword />} />
			<Route path="/not-authorized" element={<NotAuthorized />} />
		</Routes>
	</BrowserRouter>
);

export default Router;
