import React from 'react'
import TableComponent from '../tableComponent/TableComponent';
// import LicenseRequestContainer from './Container';
import secureLocalStorage from 'react-secure-storage';
import { storageConstants } from '../../utils/globalConstant';
import { licenseRequestColumns } from '../../utils/tableColumns/licenseRequest';
import { licenseRequestUrlConstants } from './Constants';
import LicenseRequestContainer from './Container';

export default function LicenseRequestTable() {

	const licenseRequestedColumnFields = licenseRequestColumns();
	const {
		onClickLicenseRequestViewForm,
	} = LicenseRequestContainer();

	const userRole = secureLocalStorage.getItem(storageConstants.role);

	return (
		<TableComponent
			columnFields={licenseRequestedColumnFields}
			url={licenseRequestUrlConstants.getTableDataUrl}
			onClickViewForm={onClickLicenseRequestViewForm}
			showAddBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
			showEditBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
			showDeleteBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
		// showViewBtn = {!["SAD", "MSAD", "SM"].includes(userRole)}
		/>
	);
}
