import React from "react";
import { useSelector } from "react-redux";
import UsersTable from "./UsersTable";
import { useLocation } from "react-router-dom";

const AllEndUsers = () => {
  const { showTable } = useSelector(
    (state) => state.app
  );

  const {state }= useLocation()
  const statusf = state?.statusf ?? true

  return (
    <>
      {showTable && <UsersTable statusf={statusf} />}
      {/* {(showAddForm || showEditForm || showViewForm) && (
        <PlatformAdminUserForm />
      )} */}
    </>
  );
};

export default AllEndUsers;
