import moment from "moment";
import { baseUrl } from "../service/baseUrl";
import Cookies from "js-cookie";

export const checkNumericExpression = (value) => {
	var numbers = /^[0-9]+$/;
	return value.match(numbers);
};

export const checkEmailValidation = (value) => {
	var mailExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
	return value.match(mailExp);
};

export const getDateTimeFormat = (date) => {
	if ([undefined, null, ""].includes(date)) {
		return "";
	} else {
		const dateObject = new Date(date);
		const options = { timeZone: "Asia/Kolkata" };
		const dateIST = dateObject?.toLocaleString("en-US", options);
		const moMdateObject = moment(dateIST, "M/D/YYYY, h:mm:ss A");
		const dateTime = moMdateObject.format("Do MMMM YYYY, h:mm A");
		return dateTime;
	}
};

export const getDateFormat = (date) => {
	if ([undefined, null, ""].includes(date)) {
		return "";
	} else {
		const dateObject = new Date(date);
		const dateIST = dateObject.toLocaleString("en-US", {
			timeZone: "Asia/Kolkata",
		});
		return moment(dateIST, "M/D/YYYY, h:mm:ss A").format("DD-MM-YYYY"); // Change to DD-MM-YYYY format
	}
};

// export const imageUpload = async (event) => {
//   debugger;
//   const formData = new FormData();
//   const files = event.target.files;
//   // files.forEach(file => formData.append("images", file));

//   // Append each selected file to the FormData object

//   for (let i = 0; i < files.length; i++) {
//     formData.append('images', files[i]); // Use 'images' as the key for the server to handle multiple files
//   }
//   // console.log("formdata-----", formData)
//   // formData.append('image', event.target.files[0]);
//   try {
//     const response = await fetch(`${baseUrl}common/imageUpload`, {
//       method: 'POST',
//       headers: {
//         Authorization: Cookies.get('jwtToken'),
//       },
//       body: formData,
//     });
//     return await response.json();
//   } catch (err) {
//     console.log('error---', err);
//   }
// };

export const imageUpload = async (file) => {
	const formData = new FormData();
	formData.append("image", file);
	try {
		const response = await fetch(`${baseUrl}common/imageUpload`, {
			method: "POST",
			headers: {
				Authorization: Cookies.get("jwtToken"),
			},
			body: formData,
		});
		return await response.json();
	} catch (error) {
		console.error("File upload error:", error);
		return { error: true };
	}
};

export const fileUpload = async (files, path) => {
	const uploadedFiles = [];
	try {
		const formData = new FormData();
		for (const file of files) {
			formData.append("file", file);
		}
		formData.append("filepath", path);
		
		const response = await fetch(`${baseUrl}common/fileUpload`, {
			method: "POST",
			headers: {
				Authorization: Cookies.get("jwtToken"),
			},
			body: formData,
		});

		const res = await response.json();
		// console.log("file upload res---------", res);
		

		if (res && res.result && Array.isArray(res.result.uploadedFiles)) {
			// Combine all uploaded files into the main array
			uploadedFiles.push(...res.result.uploadedFiles.map((f) => f.fileUrl));
		} else {
			console.error(
				"Invalid response for file:",
				// file.name,
				res
			);
		}

		return { success: true, files: uploadedFiles };
	} catch (err) {
		console.error("Error uploading files:", err);
		return { success: false, error: err.message };
	}
};

export const deleteFiles = async (data) => {
	try {
		const response = await fetch(`${baseUrl}common/fileDelete`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Accept: "application/json",
				Authorization: Cookies.get("jwtToken"),
			},
			body: JSON.stringify(data),
		});
		return await response.json();
	} catch (err) {
		console.log("error---", err);
	}
};

export const deleteImage = async (data) => {
	try {
		const response = await fetch(`${baseUrl}common/imageDelete`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Accept: "application/json",
				Authorization: Cookies.get("jwtToken"),
			},
			body: JSON.stringify(data),
		});
		return await response.json();
	} catch (err) {
		console.log("error---", err);
	}
};
