import { useDispatch } from "react-redux";
import {
  setSelectedRowData,
  toggleAddForm,
  toggleEditForm,
  toggleViewDialog,
  toggleViewForm,
  toggleViewTable,
} from "../redux/reducer/appReducer";

export const CommonContainer = () => {
  const dispatch = useDispatch();

  const onClickAddForm = () => {
    dispatch(toggleAddForm(true));
    dispatch(toggleEditForm(false));
    dispatch(toggleViewForm(false));
    dispatch(toggleViewTable(false));
  };
  const onClickEditForm = () => {
    dispatch(toggleAddForm(false));
    dispatch(toggleEditForm(true));
    dispatch(toggleViewForm(false));
    dispatch(toggleViewTable(false));
  };
  const onClickViewForm = () => {
    dispatch(toggleAddForm(false));
    dispatch(toggleEditForm(false));
    dispatch(toggleViewForm(true));
    dispatch(toggleViewTable(false));
  };
  const onClickExitForm = () => {
    dispatch(toggleViewDialog(true));
  };

  const onClickShowTable = () => {
    dispatch(setSelectedRowData({}));
    dispatch(toggleAddForm(false));
    dispatch(toggleEditForm(false));
    dispatch(toggleViewForm(false));
    dispatch(toggleViewTable(true));
  };

  return {
    onClickAddForm,
    onClickEditForm,
    onClickViewForm,
    onClickExitForm,
    onClickShowTable,
  };
};
