import React from 'react'
import "./Style.scss";


function NotAuthorized() {
  return (
    <div className='not-authorized-screen'>
        <div className='error'>
            401 Error- Not Authorized
        </div>
        <div className='not-authorized'>
            You are not allowed. Please try refreshing the page or Go back and fill the correct login details.
        </div>
       
    </div>
  )
}

export default NotAuthorized;