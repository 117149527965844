import { apiServices } from "../../service/apiService";
import { userUrlConstants } from "./Constants";

const getSelectedUserDataService = async (id, companyId) => {
  return await apiServices.getApi(
    `${userUrlConstants.getSelectedUserDataUrl}/${id}/${companyId}`
  );
};

const saveUserDataService = async (data) => {
  return await apiServices.postApi(userUrlConstants.saveUserDataurl, data);
};

const updateUserDataService = async (data) => {
  return await apiServices.updateApi(userUrlConstants.updateUserDataurl, data);
};

const deleteUserDataService = async (id, companyId) => {
  return await apiServices.deleteApi(
    `${userUrlConstants.deleteUserDataUrl}/${id}/${companyId}`
  );
};

const userLimitservice = async (companyId) => {
  return await apiServices.getApi(
    `${userUrlConstants.userLimitDataUrl}/${companyId}`
  );
};

export const userService = {
  getSelectedUserDataService,
  saveUserDataService,
  updateUserDataService,
  deleteUserDataService,
  userLimitservice,
};
