import {
  // CircularProgress,
  Container,
} from "@mui/material";
import React from "react";

const CircleLoader = () => {
  return (
    // <Container
    //   sx={{
    //     // border: "2px solid red",
    //     zIndex: 1000,
    //     height: "100%",
    //     width: "100%",
    //     position: "absolute",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     backgroundColor: "#00000045",
    //     maxWidth: "100% !important",
    //   }}
    // >
    //   <CircularProgress color="secondary" />
    // </Container>
    <Container
      sx={{
        zIndex: 1000,
        height: "100%",
        width: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00000045",
        maxWidth: "100% !important",
      }}
    >
      <div id="loader">
        <svg height="80" width="160" viewBox="-20 -20 240 240">
          <path
            className="ring left"
            d="M100,0 a100,100 0 0,1 0,200 a100,100 0 0,1 0,-200"
            fill="none"
          />
          <path
            className="ring right"
            d="M100,0 a100,100 0 0,1 0,200 a100,100 0 0,1 0,-200"
          />
        </svg>
      </div>
    </Container>
  );
};

export default CircleLoader;
