import React, { useState } from "react";
import TableComponent from "../tableComponent/TableComponent";
import { PropertyContainer } from "./Container";
import { propertyUrlConstants } from "./Constants";
import { propertyTableColumns } from "../../utils/tableColumns/propertyTableData";

import InputFileUpload from "../../common/InputFileUpload";
import "./Style.scss";
import FileDownload from "../../common/FileDownload";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Tooltip,
  Grid,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom/dist";

const DropDownList = ({ onAgentClick }) => {
  const propertyColumnFields = propertyTableColumns();
  const {
	showDeleteConfirmation,
	handleDeleteConfirm,
	handleDeleteCancel,
	handleDeleteClick,
	onClickPropertyAddForm,
	onClickPropertyEditForm,
	onClickPropertyViewForm,
	showViewForm,
	rowData,
  } = PropertyContainer();
  const navigate = useNavigate();

  const filteredData = Object.keys(rowData)
	.filter((key) => !isNaN(parseInt(key))) // Filter out properties with numeric keys
	.map((key) => rowData[key]); // Map over the values of the remaining properties

  const uniqueCompanyNames = [
	...new Set(filteredData?.map((row) => row.companyName)),
  ];
  const uniqueCompanyId = [
	...new Set(filteredData?.map((row) => row.companyId)),
  ];

  const uniqueCompanyData = filteredData?.map(row => ({ companyId: row.companyId, companyName: row.companyName }));
const uniqueCompanyNamesAndIds = [...new Set(uniqueCompanyData.map(row => JSON.stringify(row)))].map(JSON.parse);


//   console.log("filtereddataaa-----", uniqueCompanyNamesAndIds[0].companyId )
 
  return (
	<>
	  <Dialog
		open={showDeleteConfirmation}
		onClose={handleDeleteCancel}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	  >
		<DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
		<DialogContent>
		  <DialogContentText id="alert-dialog-description">
			Are you sure you want to delete this item?
		  </DialogContentText>
		</DialogContent>
		<DialogActions>
		  <Button onClick={handleDeleteCancel} color="primary">
			Cancel
		  </Button>
		  <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
			Delete
		  </Button>
		</DialogActions>
	  </Dialog>

	  <Grid className="metarob-list">
		{uniqueCompanyNamesAndIds.map((company, companyIndex) => {
		  // Filter unique project names for the current company
		  const uniqueProjectNames = [
			...new Set(
			  filteredData
				?.filter((item) => item.companyId === company.companyId)
				.map((item) => item.projectName)
				.filter(Boolean)
			),
		  ]; // Filter out undefined project names
		  const uniqueProjectData = filteredData?.map(row => ({ projectId: row.projectId, projectName: row.projectName }));
		  const uniqueProjectNamesAndIds = [...new Set(uniqueProjectData.map(row => JSON.stringify(row)))].map(JSON.parse);

		  return (
			<Accordion key={companyIndex}>
			  <AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`company-panel-${companyIndex}-content`}
				id={`company-panel-${companyIndex}-header`}
				style={{ margin: "10px", fontSize: "20px", fontWeight: "500" }}
			  >
				{company.companyName}
			  </AccordionSummary>

			  <AccordionDetails>
				{uniqueProjectNamesAndIds.map((project, projectIndex) => {
				  // Filter unique agent names for the current project
				  const uniqueAgentNames = [
					...new Set(
					  filteredData
						?.filter(
						  (item) =>
							item.companyId === company.companyId &&
							item.projectId === project.projectId
						)
						.map((item) => item.agentName)
						.filter(Boolean)
					),
					
				  ]; // Filter out undefined agent names
				
				//   console.log('uniquuu---', project)

				  return (
					<Accordion key={projectIndex}>
					  <AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`project-panel-${projectIndex}-content`}
						id={`project-panel-${projectIndex}-header`}
						style={{
						  margin: "10px",
						  fontSize: "20px",
						  fontWeight: "500",
						}}
					  >
						{project.projectName}
					  </AccordionSummary>

					  <AccordionDetails>
						{/* Render unique agent names for this project */}
						{uniqueAgentNames.map((agentName, agentIndex) => (
						  <NavLink
							// to={`/metarob/company=${companyName}/projectName=${projectName}/agentName=${agentName}`}   //companyId=65c36b813d45d38e663547c5/projectName=65c36b993d45d38e663547cc/agentName=realestate
							key={agentIndex}
							to={`/metarob/companyId=${company.companyId}/projectId=${project.projectId}/agentName=${agentName}`}  
							title="Click here to view properties"
							style={{
							  textDecoration: "none",
							  
							}}
						  >
							<h3>{agentName}</h3>
						  </NavLink>

						  // 	<ListItem
						  // 	key={agentIndex}
						  // 	style={{ margin: "10px", fontSize: "20px", fontWeight: "500" }}
						  // 	component={Link}
						  // 	to="/metarob"
						  // 	title="Click here to view properties"
						  // 	onClick={onAgentClick}

						  //   >

						  // 	{agentName}
						  //   </ListItem>

						  // {/* <AccordionDetails component={Link} to="/metarob" /> */}
						  // {/* {filteredData
						  // ?.filter((item) => item.companyName === companyName && item.projectName === projectName && item.agentName === agentName)
						  // .map((selectedRow, historyIndex) => (
						  // 	<ListItem key={historyIndex} >
						  // 		<ListItemText primary={selectedRow.propertyID} style={{ cursor: "pointer" }} />
						  // 		{renderActions(selectedRow)}
						  // 	</ListItem>

						  // ))} */}

						  // </Accordion>
						))}
					  </AccordionDetails>
					</Accordion>
				  );
				})}
			  </AccordionDetails>
			</Accordion>
		  );
		})}
	  </Grid>
	</>
  );
};

export default DropDownList;
