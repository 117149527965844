import { metaRobDataLabels } from "../../assets/labels/metaRobDataLabels";

export const facingTableColumns = () => {
  const facing_config_columns = [
   
	{
		field: metaRobDataLabels.facing_field,
		headerName: metaRobDataLabels.facing,
	}
  ];

  return facing_config_columns;
};