import { useEffect, useState } from 'react';
import { propertyConstants, propertyUrlConstants } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedRowData,
	toggleLoadingView,
	toggleToastView,
} from '../../redux/reducer/appReducer';
import { propertyService } from './Service';
import { getTableData } from '../tableComponent/tableComponent.slice';
import { CommonContainer } from '../../utils/CommonFunction';
import { companyProjectsService } from '../CompanyProjects/Service';

export const PropertyContainer = () => {
	const { showEditForm, showAddForm, showViewForm, selectedRowData } =
		useSelector((state) => state.app);
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState('1');
	const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
		CommonContainer();

	const propertyInfo = {
		companyId: "",
		companyName: "",
		projectName: "",
		agentName: "",
		propertyID: '',
		propertyName: '',
		propertyType: '',
		facing: '',
		capacity: '',
		aminities: [],
		description: '',
		price: 0
	}

	const [rowData, setRowData] = useState(propertyInfo);
	const [resetData, setResetData] = useState({});
	const [companyData, setCompanyData] = useState([]);
	const [companyNameData, setCompanyNameData] = useState([]);
	const [projectData, setProjectData] = useState([]);
	const [propertyTypeData, setPropertyTypeData] = useState([]);
	const [propType, setPropType] = useState([]);
	const [propertyFacingValues, setPropertyFacingValues] = useState([]);
	const [facing, setFacing] = useState([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const getSelectedRowData = async () => {
		dispatch(toggleLoadingView(true));
		const data =
			await propertyService.getSelectedPropertyDataService(
				selectedRowData?._id
			);
		setRowData(data.result);
		setResetData(data.result);
		dispatch(toggleLoadingView(false));
	};

	const getData = async () => {
		dispatch(toggleLoadingView(true));
		const data = await propertyService.getPropertiesDataService();
		setRowData({ ...propertyInfo, ...data?.result });
		// setRowData(data?.result)
		// setTableData(data?.result);
		dispatch(toggleLoadingView(false));
	};

	const filteredRows = Object.keys(rowData)
		.filter(key => !isNaN(parseInt(key))) // Filter out properties with numeric keys
		.map(key => rowData[key]); // Map over the values of the remaining properties

	const getCompanyData = async () => {
		// const dataa = await companyProjectsService.getCompanyNamesService();
		const data = await companyProjectsService.getCompanyNamesWithProjects();
		setCompanyData(data?.result);
		let companytemp = [];
		await data?.result.map((each) =>
			companytemp.push({
				id: each?.companyId,
				title: each?.companyName
			}),

		)
		
		setCompanyNameData(companytemp)

		showAddForm && setRowData({
			 ...rowData,
			companyId: data?.result[0]?.companyId,
			projectName: data?.result[0]?.projects[0]?.projectId
		});
	};


	const getPropertyTypeData = async () => {
		const data = await propertyService.getPropertyTypeService();
		setPropType(data.result);
		let temp = [];
		// console.log('fcdfffd', data.result)
		await data?.result.map((each) =>
			temp.push({
				id: each?._id,
				title: each?.propTypeTitle,
			})
		);
		setPropertyTypeData(temp);
		// showAddForm && setRowData({ ...rowData, propertyType: temp[0]?.id });
		// console.log("temp----", temp);
	};

	const getFacingData = async () => {
		const data = await propertyService.getFacingService();
		setFacing(data.result);
		let temp = [];
		// console.log('fcdfffd', data.result)
		await data?.result.map((each) =>
			temp.push({
				id: each?._id,
				title: each?.facingTitle,
			})
		);
		setPropertyFacingValues(temp);
	};

	useEffect(() => {
		if (showEditForm || showViewForm) {
			getSelectedRowData();
		}
		if (showEditForm || showViewForm || showAddForm) {
			getCompanyData();
		}
		if (showEditForm || showViewForm || showAddForm) {
			getPropertyTypeData();
		}
		if (showEditForm || showViewForm || showAddForm) {
			getFacingData();
		}
		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};

	const onChangeCompany = (event) => {
        const newCompanyId = event.target.value;
        const selectedCompany = companyData?.find(company => company.companyId === newCompanyId);
        if (selectedCompany) {
            const filteredProjects = selectedCompany?.projects?.map(project => ({
                id: project?.projectId,
                title: project?.projectName
            }));
            setProjectData(filteredProjects);
            setRowData({
                ...rowData,
                companyId: newCompanyId,
				projectName: selectedCompany?.projects[0]?.projectId
            });
        }
    };

    const onChangeProject = (event) => {
        setRowData({ ...rowData, projectName: event.target.value });
    };


	// debugger;
	const onChangePropertyId = (event) => {
		setRowData({ ...rowData, propertyID: event.target.value });
	};

	const onChangeAgentName = (event) => {
		setRowData({ ...rowData, agentName: event.target.value });
	};

	const onChangePropertyName = (event) => {
		setRowData({ ...rowData, propertyName: event.target.value });
	};

	const onChangePropertyType = (event) => {
		const data = propType.filter(
			(each) => each._id === event.target.value
		)[0];
		// console.log('data---', data)

		setRowData({
			...rowData,
			propertyType: event.target.value,
		});
	};

	const onChangeFacing = (event) => {
		setRowData({ ...rowData, facing: event.target.value });
	};
	const onChangeCapacity = (event) => {
		setRowData({ ...rowData, capacity: event.target.value });
	};
	const onChangeAminities = (event) => {
		setRowData({ ...rowData, aminities: event.target.value });
	};
	const onChangeDescription = (event) => {
		setRowData({ ...rowData, description: event.target.value });
	};
	const onChangePrice = (event) => {
		setRowData({ ...rowData, price: event.target.value });
	};


	const onClickSave = async () => {
		if (rowData?.propertyID.trim() === "" ||
			rowData?.propertyName.trim() === "" ||
			rowData?.propertyType.trim() === "" ||
			rowData?.facing.trim() === "" ||
			rowData?.capacity.trim() === "" ||
			rowData?.aminities.trim() === "" ||
			rowData?.description.trim() === "" ||
			rowData?.price.trim() === ""
		) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill all the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await propertyService.savePropertyDataService(rowData);
			} else {
				response = await propertyService.updatePropertyDataService(rowData);
			}
			if (response.result.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(getTableData(propertyUrlConstants.getTableDataUrl));
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};

	const onClickReset = () => {
		setRowData(resetData);
	};

	const onClickPropertyAddForm = () => {
		onClickAddForm();
	};
	const onClickPropertyEditForm = () => {
		onClickEditForm();
	};
	const onClickPropertyViewForm = () => {
		onClickViewForm();
	};

	const onClickDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await propertyService.deletePropertyDataService(
			selectedRowData?._id
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(propertyUrlConstants.getTableDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};

	const handleDeleteClick = () => {
		setShowDeleteConfirmation(true);
	};

	const handleDeleteConfirm = () => {
		onClickDelete();
		setShowDeleteConfirmation(false);
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};

	return {
		onClickPropertyAddForm,
		onClickPropertyEditForm,
		onClickPropertyViewForm,
		onClickDelete,
		showDeleteConfirmation,
		handleDeleteClick,
		handleDeleteConfirm,
		handleDeleteCancel,
		onChangeAgentName,
		onChangePropertyId,
		onChangePropertyName,
		onChangePropertyType,
		onChangeFacing,
		onChangeCapacity,
		onChangeAminities,
		onChangeDescription,
		onChangePrice,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		showAddForm,
		rowData,
		onClickSave,
		onClickReset,
		onChangeCompany,
		companyData,
		propertyTypeData,
		propertyFacingValues,
		projectData,
		onChangeProject,
		companyNameData
	};
};