import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { PlatformAdminUserContainer } from "./Container";
import { platformAdminUrlConstants } from "./Constants";
import { plaformAdminColumns } from "../../utils/tableColumns/platformAdmin";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

const PlatformAdminUserTable = ({roleF}) => {
  const companyColumnFields = plaformAdminColumns();
  const {
    onClickDelete,
    onClickPlatformAdminAddForm,
    onClickPlatformAdminEditForm,
    onClickPlatformAdminViewForm,
  } = PlatformAdminUserContainer();

  return (
    <TableComponent
      columnFields={companyColumnFields}
      url={`${platformAdminUrlConstants.getTableDataUrl}/${secureLocalStorage.getItem(storageConstants.companyId)}`}
      roleF = {roleF}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickPlatformAdminAddForm}
      onClickEditForm={onClickPlatformAdminEditForm}
      onClickViewForm={onClickPlatformAdminViewForm}
    />
  );
};

export default PlatformAdminUserTable;
