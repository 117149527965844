export const companyAdminConstants = {
  userRoles: [
    { id: "CSAD", title: "Client Super Admin" },
    { id: "SP", title: "Sales Person" },
    { id: "MAD", title: "MetaRob Admin" },
  ],
  status: [
    {
      id: true,
      title: "Active",
    },
    {
      id: false,
      title: "Inactive",
    },
  ],
};

export const companyAdminUrlConstants = {
  getTableDataUrl: "companyAdmin/table",
  getSelectedUserDataUrl: "companyAdmin/table",
  saveUserDataurl: "companyAdmin/register",
  updateUserDataurl: "companyAdmin/update",
  deleteUserDataUrl: "companyAdmin/delete",
  userLimitDataUrl: "companyAdmin/limit",
};
