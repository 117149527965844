import React from "react";
import "./Style.scss";
import notFoundImage from "../../assets/images/not-found.jpg";
import { constants } from "./Constants";

const NotFound = () => {
  return (
    <div className="nf_container">
      <div className="nf_text_container">
        <h2>Sorry!..</h2>
        <p className="nf_title">{constants.title}</p>
        <p className="nf_para">{constants.para1}</p>
        <p className="nf_para2 nf_para">{constants.para2}</p>
      </div>
      <div className="nf_image_container">
        <img src={notFoundImage} alt="not found" className="nf_image" />
      </div>
    </div>
  );
};

export default NotFound;
