import React from "react";
import { CompanyProjectsContainer } from "./Container";
import { companyProjectColumns } from "../../utils/tableColumns/companyProjects";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItem,
	ListItemText,
	TextField,
	InputAdornment,
	Tooltip,
	Grid,
	Typography,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	OutlinedInput,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	Visibility as ViewIcon,
	Search as SearchIcon,
	Add as AddIcon,
} from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Style.scss";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

const CompanyProjectsTable = () => {
	const companyProjectColumnFields = companyProjectColumns();
	const {
		showDeleteConfirmation,
		onClickCompanyConfigAddForm,
		onClickCompanyConfigEditForm,
		onClickCompanyConfigViewForm,
		handleDeleteClick,
		handleDeleteConfirm,
		handleDeleteCancel,
		rowData,
		showTable,
		selectedRowData,
		onSelectionChanged,
		onChangeTextFilter,
		gridRef,
		tableData,
		filteredData,
		onStatusFilterChange,
		statusFilter,
		filterProjectsByStatus
	} = CompanyProjectsContainer();

	const userRole = secureLocalStorage.getItem(storageConstants.role);

	const filteredProjects = filterProjectsByStatus(filteredData, statusFilter);

	const uniqueCompanyNames = [
		...new Set(
			filteredProjects?.map((row) => row.companyName)
		),
	];

	const renderActions = (selectedRow) => (
		<>
			{!["SM"].includes(userRole) && (
				<>
					<Tooltip title="Edit" arrow placement="top">
						<IconButton
							className="tb-icon-btn"
							onClick={onClickCompanyConfigEditForm}
						>
							<EditIcon className="tb-act-icons" />
						</IconButton>
					</Tooltip>
					{(((statusFilter === 'false') && ["DEV"].includes(userRole)) || ["SAD", "MSAD"].includes(userRole)) && (
						<Tooltip title="Delete" arrow placement="top">
							<IconButton className="tb-icon-btn-del" onClick={handleDeleteClick}>
								<DeleteIcon className="tb-act-icons-del" />
							</IconButton>
						</Tooltip>
					)}
				</>
			)}
			<Tooltip title="View" arrow placement="top">
				<IconButton
					className="tb-icon-btn-view"
					onClick={onClickCompanyConfigViewForm}
				>
					<ViewIcon className="tb-act-icons-view" />
				</IconButton>
			</Tooltip>
		</>
	);

	return (
		<>
			<Dialog
				open={showDeleteConfirmation}
				onClose={handleDeleteCancel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
				<DialogContent>
					
					<DialogContentText id="alert-dialog-description">
						{(statusFilter === 'false') ? 'Are you sure you want to delete this item?' : 'Are you sure you want to permanently delete this item?'}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color="primary" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Grid className="search-add">
				<div>
					<TextField
						id="outlined-controlled"
						label={"Search"}
						className="tb-head-search-text-field tb-head-text-field"
						size="small"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<IconButton>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
						onChange={onChangeTextFilter}
						style={{ marginLeft: "20px" }}
					/>

					<FormControl size="small" sx={{ ml: 1 }}>
						<InputLabel id="status-filter-label">Status</InputLabel>
						<Select
							labelId="status-filter-label"
							id="status-filter"
							value={statusFilter}
							onChange={onStatusFilterChange}
							input={<OutlinedInput label="Status" />}
							sx={{ minWidth: 160 }}
						>
							<MenuItem value="false">Active</MenuItem>
							<MenuItem value="true">Inactive</MenuItem>
						</Select>
					</FormControl>
				</div>

				{!["SM"].includes(userRole) && (
					<Tooltip title="Add" arrow placement="top">
						<IconButton
							className="tb-icon-btn-add"
							onClick={onClickCompanyConfigAddForm}
						>
							<AddIcon className="tb-act-icons-add" />
						</IconButton>
					</Tooltip>
				)}
			</Grid>

			<Grid className="company-project-list" ref={gridRef}>
				{uniqueCompanyNames.length === 0 ? (
					<Typography className="no-data-found">No data found</Typography>
				) : (

					uniqueCompanyNames.map((companyName, index) => (
						<Accordion key={index} className="company-project-parent">
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1-content"
								id="panel1-header"
								style={{ margin: "10px", fontSize: "20px", fontWeight: "500" }}
							>
								Company Name: {companyName}
							</AccordionSummary>

							<Typography className="projects">Projects</Typography>

							<AccordionDetails>
								{filteredProjects
									?.filter((item) => item.companyName === companyName)
									.map((selectedRow, historyIndex) => (
										<ListItem
											key={historyIndex}
											className="project"
											ref={gridRef}
											onClick={() => onSelectionChanged(selectedRow)}
										>
											<ListItemText
												primary={selectedRow.name}
												style={{ cursor: "pointer" }}
											/>
											{renderActions(selectedRow)}
										</ListItem>
									))}
							</AccordionDetails>
						</Accordion>
					))
				)}
			</Grid>
		</>
	);
};

export default CompanyProjectsTable;
