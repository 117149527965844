import { userLabels } from "../../assets/labels/user";

const roles = {
  EU: "End User",
  CAD: "Admin",
  // CSAD: " Client Super Admin",
};

export const userColumns = () => {
  const user_columns = [
    {
      field: userLabels.user_first_name_field,
      headerName: userLabels.user_first_name,
    },
    {
      field: userLabels.user_last_name_field,
      headerName: userLabels.user_last_name,
    },

    {
      field: userLabels.user_email_field,
      headerName: userLabels.user_email,
    },
    {
      field: userLabels.user_role_field,
      headerName: userLabels.user_role,
      valueFormatter: (params) => roles[params.value],
    },
    {
      field: userLabels.user_mobile_field,
      headerName: userLabels.user_mobile,
    },
    {
      field: userLabels.user_status_field,
      headerName: userLabels.user_status,
      valueFormatter: (params) => (params.value ? "Active" : "Inactive"),
    },
  ];

  return user_columns;
};
