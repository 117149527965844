import React from 'react';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import InputFileUpload from './InputFileUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from "react-redux";

const UploadFiles = ({
	label,
	rowData = [], // Default to empty array
	onChangeEvent,
	uploadLabel,
	acceptType,
	initialFileUpload,
	isDisabled,
	onRemoveFile,
	onRemoveAllFiles,
	isMultiple = false,
}) => {
	// Ensure `fileNames` only processes valid strings
	const fileNames = rowData?.map((url) => {
		if (typeof url === 'string') {
			const name = url.split('/');
			return name[name.length - 1];
		}
		return 'Invalid File';
	});

	const { showViewForm } = useSelector((state) => state.app);

	return (
		<>
			<Typography className='global-form-text'>{label}</Typography>
			<Grid
				sx={{
					display: 'flex'
				}}>
				<InputFileUpload
					label={uploadLabel}
					onChangeEvent={onChangeEvent}
					isRequired={true}
					acceptType={acceptType}
					initialFileUpload={initialFileUpload}
					isDisabled={isDisabled}
					isMultiple={isMultiple}
				/>
				{/* <div className='remove-all'> */}
					{rowData?.length > 0 && !showViewForm && (
						<IconButton
							onClick={() => onRemoveAllFiles()}
							sx={{ color: '#ff0000', fontSize: 16 }}
						>
							Remove All<CancelIcon />
						</IconButton>
					)}
				{/* </div> */}


				<div className='project-files'>
					{rowData?.length > 0 ? (
						rowData?.map((url, index) => (
							<div
								key={index}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '4px',
									margin: 10
								}}
							>
								<Typography
									sx={{
										color: '#00000099',
										fontSize: 16,
										fontWeight: 'bold',
										fontStyle: 'italic',
									}}
								>
									{fileNames[index]}
								</Typography>
								{!showViewForm && (
									<IconButton
										onClick={() => onRemoveFile(index)}
										sx={{ color: '#ff0000', fontSize: 16 }}
									>
										<CancelIcon />
									</IconButton>
								)}
							</div>
						))
					) : (
						<Typography
							sx={{
								display: 'flex',
								alignItems: 'center',
								color: '#00000099',
								fontSize: 12,
							}}
						>
							{uploadLabel}
						</Typography>
					)}
				</div>

			</Grid>

		</>
	);
};

export default UploadFiles;
