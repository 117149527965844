import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { companyColumns } from "../../utils/tableColumns/company";
import { CompanyContainer } from "./Container";
import { companyUrlConstants } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";
import secureLocalStorage from "react-secure-storage";

const CompanyTable = ({subscriptionStatusF}) => {
  const companyColumnFields = companyColumns();
  const {
    onClickDelete,
    onClickCompanyAddForm,
    onClickCompanyEditForm,
    onClickCompanyViewForm,
  } = CompanyContainer();
  const userRole = secureLocalStorage.getItem(storageConstants.role);


  return (
    <TableComponent
      columnFields={companyColumnFields}
      url={companyUrlConstants.getTableDataUrl}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickCompanyAddForm}
      onClickEditForm={onClickCompanyEditForm}
      onClickViewForm={onClickCompanyViewForm}
      subscriptionStatusF = {subscriptionStatusF}
      showAddBtn={!["DEV"].includes(userRole)}
      showEditBtn={!["DEV"].includes(userRole)}
      showDeleteBtn={!["DEV"].includes(userRole)}
    />
  );
};

export default CompanyTable;
