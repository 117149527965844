export const propertyConstants = {
propertyTypeValues: [
 {id: "Villa", title: "Villa"},
 {id: "Flat", title: "Flat"},
 {id: "Independent house", title: "Independent house"}
],

propertyFacingValues: [
	{id: "North", title: "North"},
	{id: "South", title: "South"},
	{id: "East", title: "East"},
	{id: "West", title: "West"},
	{id: "North East", title: "North East"},
	{id: "South East", title: "South East"},
	{id: "North West", title: "North West"},
	{id: "South West", title: "South West"}
]
}

export const propertyUrlConstants = {
		getTableDataUrl: 'metaRob/getPropertyData',
		getSelectedPropertyDataUrl:  'metaRob/getOneProperty',
		savePropertyDataurl: 'metaRob/insertProperty', 
		updatePropertyDataurl: 'metaRob/updateProperty', 
		deletePropertyDataUrl: 'metaRob/deleteProperty',
		getPropertiesBasedOnCompanyProjectAndAgent: 'metaRob/getProperties'
	};