import { apiServices } from '../../service/apiService';
import { MetaRobDataUrlConstants } from './Constants';

//-------------------------------------property Type--------------------------

const getSelectedPropertyTypeDataService = async (id) => {
  return await apiServices.getApi(
	`${MetaRobDataUrlConstants.getSelectePropertyTypeDataUrl}/${id}`
  );
};

const savePropertyTypeDataService = async (data) => {
  return await apiServices.postApi(
	MetaRobDataUrlConstants.savePropertyTypeDataurl,
	data
  );
};

// const updatePropertyTypeDataService = async (data) => {
//   return await apiServices.updateApi(
//     MetaRobDataUrlConstants.updatePropertyDataurl,
//     data
//   );
// };

const deletePropertyTypeDataService = async (id) => {
  return await apiServices.deleteApi(
	`${MetaRobDataUrlConstants.deletePropertyTypeDataUrl}/${id}`
  );
};

// const getCompanyNamesService = async () => {
//   return await apiServices.getApi(
//     MetaRobDataUrlConstants.companyConfigCompanyData
//   );
// };

//------------------------------Facing-------------------------------------------------------------------

const getSelectedFacingDataService = async (id) => {
	return await apiServices.getApi(
	  `${MetaRobDataUrlConstants.getSelecteFacingDataUrl}/${id}`
	);
  };
  
  const saveFacingDataService = async (data) => {
	return await apiServices.postApi(
	  MetaRobDataUrlConstants.saveFacingDataurl,
	  data
	);
  };
  
  // const updateFacingDataService = async (data) => {
  //   return await apiServices.updateApi(
  //     MetaRobDataUrlConstants.updatePropertyDataurl,
  //     data
  //   );
  // };
  
  const deleteFacingDataService = async (id) => {
	return await apiServices.deleteApi(
	  `${MetaRobDataUrlConstants.deleteFacingDataUrl}/${id}`
	);
  };
  
  // const getCompanyNamesService = async () => {
  //   return await apiServices.getApi(
  //     MetaRobDataUrlConstants.companyConfigCompanyData
  //   );
  // };
  

export const metaRobDataService = {
	getSelectedPropertyTypeDataService,
	savePropertyTypeDataService,
	// updatePropertyTypeDataService,
	deletePropertyTypeDataService,
	getSelectedFacingDataService,
	saveFacingDataService,
	deleteFacingDataService,
};
