import React from "react";
import { useSelector } from "react-redux";
import UserTable from "./UserTable";
import UserForm from "./UserForm";
import { useLocation } from "react-router-dom";

const User = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );
  const {state }= useLocation()
  const statusf = state?.statusf ?? true

  return (
    <>
      {showTable && <UserTable statusf={statusf} />}
      {(showAddForm || showEditForm || showViewForm) && <UserForm />}
    </>
  );
};

export default User;
