import React from 'react'
import { useSelector } from 'react-redux';
import SubscriptionWebTable from './SubscriptionTable';
import SubscriptionWebForm from './SubscriptionForm';

export default function SubscriptionWeb() {
	const { showTable, showViewForm } = useSelector(
		(state) => state.app
	);

	return (
		<>
			{showTable && <SubscriptionWebTable />}
			{(showViewForm) && <SubscriptionWebForm />}
		</>
	);
}
