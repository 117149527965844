import { apiServices } from "../../service/apiService";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

const url = `company/`;
const hostUrl = "common/host/";

const loginApi = async (data) => {
  const response = await apiServices.postApi(
    `${url}${window.location.hostname}/${secureLocalStorage.getItem(
      storageConstants.secretkey
    )}/login`,
    data
  );
  return response;
};

const getHostnameDetails = async (hostname) => {
  const response = await apiServices.getApi(`${hostUrl}${hostname}`);
  return response;
};

export const loginService = {
  loginApi,
  getHostnameDetails,
};
