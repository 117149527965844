import { companyLabels } from "../../assets/labels/company";
import { getDateTimeFormat } from "../helper";

export const companyColumns = () => {
  const company_columns = [
    {
      field: companyLabels.company_name_field,
      headerName: companyLabels.company_name,
    },
    {
      field: companyLabels.user_name_field,
      headerName: companyLabels.user_name,
    },
    {
      field: companyLabels.email_field,
      headerName: companyLabels.email,
    },
    {
      field: companyLabels.mobile_field,
      headerName: companyLabels.mobile,
    },
    // {
    //   field: companyLabels.company_role_field,
    //   headerName: companyLabels.company_role,
    //   valueFormatter: (params) => params.value === "CM" && "Company",
    // },
    {
      field: companyLabels.created_date_field,
      headerName: companyLabels.created_date,
      valueFormatter: (params) => getDateTimeFormat(params.value),
    },
    {
      field: companyLabels.subscription_start_date_field,
      headerName: companyLabels.subscription_start_date,
      valueFormatter: (params) => getDateTimeFormat(params.value),
    },
    {
      field: companyLabels.subscription_end_date_field,
      headerName: companyLabels.subscription_end_date,
      valueFormatter: (params) => getDateTimeFormat(params.value),
    },
    {
      field: companyLabels.user_limit_field,
      headerName: companyLabels.user_limit,
    },
    {
      field: "title",
      headerName: companyLabels.payment_type,
    },
    {
      field: companyLabels.price_field,
      headerName: companyLabels.price,
    },
    {
      field: companyLabels.subscription_status_field,
      headerName: companyLabels.subscription_status,
    },
    // {
    //   field: companyLabels.company_status_field,
    //   headerName: companyLabels.company_status,
    //   valueFormatter: (params) => (params.value ? "Active" : "Inactive"),
    // },
  ];

  return company_columns;
};
