import {  Grid, Typography } from "@mui/material";
import React from "react";
// import { ReactComponent as Sun } from "../../assets/images/Sun.svg";
// import { ReactComponent as Moon } from "../../assets/images/Moon.svg";
import "./Style.scss";

import { HeaderContainer } from "./Container";
import home from "../../assets/images/home_logo.png";
import { Link, useLocation } from 'react-router-dom';


const Header = (props) => {
	// const { toggleTheme, appContext } = HeaderContainer();
	const { 
		// toggleSideMenuBar,
		displayName,
		handleLogoClick,
	} =
		HeaderContainer(props);

	const location = useLocation();

	return (
		<Grid className='head-container'>
			<Grid
				sx={{
					display: "flex",
				}}>
				{/* <Typography variant="h5" component={"h5"}>
					<IconButton
						size="large"
						aria-label="menuIcon"
						color="inherit"
						onClick={toggleSideMenuBar}
					>
						<MenuIcon />
					</IconButton>
				</Typography> */}
				<Link to="/" 
					onClick={(e) => {
						handleLogoClick(e)
					}}>
					<img
						src={home}
						alt='ss'
						style={{
							width: 25,
							height: 25,
							alignSelf: "center",
							color:"inherit"
						}}
					/>
				</Link>

				<Typography
					variant='h6'
					component={"h6"}
					sx={{
						textAlign: "center",
						alignSelf: "center",
						ml: 2,
					}}>
					{displayName(location.pathname)}
				</Typography>
			</Grid>

			<Grid className='hd-right-container'>
				{/* <div className="dark_mode">
					<input
						className="dark_mode_input"
						type="checkbox"
						id="darkmode-toggle"
						onChange={toggleTheme}
						value={appContext?.lightMode === "light"}
					/>
					<label className="dark_mode_label" htmlFor="darkmode-toggle">
						<Sun />
						<Moon />
					</label>
				</div> */}

				{/* <IconButton size="large" aria-label="notifications" color="inherit">
					<Badge badgeContent={2} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton> */}
				{/* <div className="profile-container">
					<img
						src="https://www.shutterstock.com/shutterstock/photos/1046605465/display_1500/stock-photo-charminar-hyderabad-india-background-with-copy-space-1046605465.jpg"
						alt="profile"
						className="profile-image"
					/>
					{/* <IconButton sx={{ mt: 1 }}>
						<KeyboardArrowDownIcon />
					</IconButton> */}
				{/* </div>  */}
				{/* <IconButton
					size="large"
					aria-label="logouticon"
					color="inherit"
					onClick={onClicklogout}
				>
					<LogoutIcon /> */}
				{/* </IconButton> */}
			</Grid>
		</Grid>
	);
};

export default Header;
