import { contactWebLabels } from "../../assets/labels/contactWeblabels";
import { getDateTimeFormat } from "../helper";

export const contactWebColumns = () => {

	const contact_web_columns = [
		{
			field: contactWebLabels.full_name_field,
			headerName: contactWebLabels.full_name,
		},
		{
			field: contactWebLabels.email_field,
			headerName: contactWebLabels.email,
		},
		{
			field: contactWebLabels.mobile_field,
			headerName: contactWebLabels.mobile,
		},
		{
			field: contactWebLabels.subject_field,
			headerName: contactWebLabels.subject,
		},

		{
			field: contactWebLabels.message_field,
			headerName: contactWebLabels.message,
		},
		{
			field: contactWebLabels.created_date_field,
			headerName: contactWebLabels.created_date,
			valueFormatter: (params) => getDateTimeFormat(params.value),
		},
		

	];

	return contact_web_columns;
};
