
import { apiServices } from "../../service/apiService";
import { blogPostUrlConstants } from "./Constants";

const getSelectedBlogPostService = async (id) => {
  return await apiServices.getApi(
    `${blogPostUrlConstants.getSelectedBlogPostUrl}/${id}`
  );
};

const saveBlogPostDataService = async (data) => {
    return await apiServices.postApi(
        blogPostUrlConstants.saveBlogPostDataurl,
      data
    );
  };
  
  const updateBlogPostDataService = async (data) => {
    return await apiServices.updateApi(
        blogPostUrlConstants.updateBlogPostDataurl,
      data
    );
  };

const deleteSelectedBlogPostService = async (id) => {
    return await apiServices.deleteApi(
      `${blogPostUrlConstants.deleteSelectedBlogPostUrl}/${id}`
    );
  };


export const blogPostService = {
    getSelectedBlogPostService,
    saveBlogPostDataService,
    updateBlogPostDataService,
    deleteSelectedBlogPostService
};
