import React from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../utils/globalConstant";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { reset } from "../redux/reducer/loginReducer";

const ProtectedRoute = ({ children, ...rest }) => {
	// here we need take token

	const dispatch = useDispatch();
	const token = Cookies.get("jwtToken");

	const role = secureLocalStorage.getItem(storageConstants.role); // take jwtToken from cookies

	if (!token) {
		return <Navigate to="/login" />;
	} else {
		if (rest?.path === "/") {
			if (["SAD", "MSAD", "SM", "CSAD", "SP", "CM"].includes(role)) {
				return children;
			} else if (["DEV"].includes(role)) {
				return <Navigate to="/company" />;
			} else if (["MAD"].includes(role)) {
				return <Navigate to="/metarob" />;
			} else {
				secureLocalStorage.clear();
				Cookies.remove("jwtToken");
				dispatch(reset());
				return <Navigate to="/login" />;
			}
		} else if (
			(rest?.path === "/company" || rest?.path === "/dashboard/company" ||
				rest?.path === "/company-projects" ||
				rest?.path === "/app-config") &&
			(["SAD", "MSAD", "SM", "DEV"].includes(role))
		) {
			return children;
		} else if (
			(rest?.path === "/license" || rest?.path === "/admin-users" || rest?.path === "/dashboard/admin-users") &&
			(["CM", "CSAD"].includes(role))
		) {
			return children;
		} else if ((rest?.path === "/users" || rest?.path === "/dashboard/users") && (["CM", "CSAD", "SP"].includes(role))) {
			return children;
		} else if (
			(rest?.path === "/metarob-data" || rest?.path === "/user-settings") &&
			["SAD", "MSAD", "SM", "DEV", "MAD"].includes(role)
		) {
			return children;
		} else if (
			rest?.path === "/metarob" &&
			["SAD", "MSAD", "SM", "DEV", "CSAD", "CM", "MAD"].includes(role)
		) {
			return children;
		} else if (
			(rest?.path === "/platform-admin-users" || rest?.path === "/dashboard/platform-admin-users") &&
			(["SAD", "MSAD"].includes(role))
		) {
			return children;
		} else if ((rest?.path === "/all-users" ||
			rest?.path === "/dahsboard/all-users" ||
			rest?.path === "/license-requests" ||
			rest?.path === "/contact-us-website" ||
			rest?.path === "/subscription-website" ||
			rest?.path === "/blog-website" ) &&
			(["SAD", "MSAD", "SM"].includes(role))) {
			return children;
		} else if (
			rest?.path === "/profile" &&
			["SAD", "MSAD", "SP", "CM", "CSAD", "SM", "DEV", "MAD"].includes(role)
		) {
			return children;
		} else if (
			rest?.path === "/change-password" &&
			["SAD", "MSAD", "SP", "CM", "CSAD", "SM", "DEV", "MAD"].includes(role)
		) {
			return children;
		} else {
			return <Navigate to="/not-authorized" />;
		}
	}
};

export default ProtectedRoute;
