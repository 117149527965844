import { metaRobDataLabels } from "../../assets/labels/metaRobDataLabels";

export const propertyTypeTableColumns = () => {
	const propertyType_config_columns = [
	 
		{
				field: metaRobDataLabels.property_type_field,
				headerName: metaRobDataLabels.property_type
		}
	];

	return propertyType_config_columns;
};