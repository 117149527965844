import { Box, Grid, Tab } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import FormActionButton from "../../common/FormActionButton";
import { subscriptionWebLabels } from "../../assets/labels/subscriptionWebLabels";
import { getDateTimeFormat } from "../../utils/helper";
import SubscriptionWebContainer from "./Container";


const SubscriptionWebForm = () => {
	const { tabIndex, onChangeTabIndex, showViewForm, rowData } =
		SubscriptionWebContainer();

	return (
		<Grid className="global-form">
			<Box
				className="global-form-container"
				sx={{
					typography: "body1",
					height: "95%",
				}}
			>
				<TabContext value={tabIndex}>
					<Box className="global-form-tab-header">
						<TabList
							onChange={onChangeTabIndex}
							aria-label="lab API tabs example"
						>
							<Tab label="Subscription Form" value="1" />
						</TabList>
					</Box>
					<TabPanel value="1" className="global-form-fields-container">

						<TextInputField
							label={subscriptionWebLabels.email}
							value={rowData?.email}
							isRequired={true}
							isMail={true}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={subscriptionWebLabels.created_date}
							value={getDateTimeFormat(rowData?.createdAt)}
							isRequired={true}
							isDisabled={showViewForm}
						/>
					</TabPanel>
					<FormActionButton
					// onClickSave={onClickSave}
					// onClickReset={onClickReset}
					/>
				</TabContext>
				<DialogBox />
			</Box>
		</Grid>
	);
};

export default SubscriptionWebForm;
