import { licenseRequestLabels } from "../../assets/labels/licenseRequest";
import { getDateTimeFormat } from "../helper";

export const licenseRequestColumns = () => {
    
  const company_columns = [
    {
      field: licenseRequestLabels.company_name_field,
      headerName: licenseRequestLabels.company_name,
    },
    {
      field: licenseRequestLabels.user_name_field,
      headerName: licenseRequestLabels.user_name,
    },
    {
      field: licenseRequestLabels.email_field,
      headerName: licenseRequestLabels.email,
    },
    {
      field: licenseRequestLabels.mobile_field,
      headerName: licenseRequestLabels.mobile,
    },
    {
        field: licenseRequestLabels.type_of_subscription_field,
        headerName: licenseRequestLabels.type_of_subscription,
      },
      {
        field: licenseRequestLabels.requested_date_field,
        headerName: licenseRequestLabels.requested_date,
        valueFormatter: (params) => getDateTimeFormat(params.value),
      },
      {
        field: licenseRequestLabels.message_field,
        headerName: licenseRequestLabels.message,
      },
    //   {
    //     field: licenseRequestLabels.status_field,
    //     headerName: licenseRequestLabels.status,
    //   },
    
  ];

  return company_columns;
};
