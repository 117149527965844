import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const DropdownField = ({
  label,
  value,
  onChangeEvent,
  data,
  isDisabled = false,
  isRequired = false,
  width=300
}) => {
  return (
    <FormControl sx={{ width, margin: "10px" }} size="small">
      <InputLabel id="demo-select-small-label" disabled={isDisabled}>
        {label} {isRequired && "*"}
      </InputLabel>

      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        disabled={isDisabled}
        value={value}
        label={label}
        onChange={onChangeEvent}
        required={isRequired}
      >
        {data.map((eachData, index) => (
          <MenuItem key={index} value={eachData.id}>
            {eachData.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownField;
