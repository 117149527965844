import { Box, Collapse, Grid } from "@mui/material";
import React from "react";
import "./Style.scss";
import {
	Business as CompanyIcon,
	ColorLens as Product,
	Person3 as PersonIcon,
	// ExpandLess,
	// ExpandMore,
	// Menu as MenuIcon,
	Dashboard as DashboardIcon,
	// AttachMoney as AttachMoneyIcon,
	// Sell as SellIcon,
	Category as CategoryIcon,
	// Campaign as CampaignIcon,
	// Settings as SettingsIcon,
	// NotificationsNone as NotificationIcon,
	// Info as InfoIcon,
	// ContactSupport as ContactSupportIcon,
	// Help as HelpIcon,
	// PrivacyTip as PrivacyTipIcon,
	// AcUnit as AcUnitIcon,
	// TipsAndUpdates as TipsAndUpdatesIcon,
	KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
	// ArrowForwardIos as ArrowForwardIosIcon,
	KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
	// SportsEsports as SportsEsportsIcon,
	// ManageAccounts as ManageAccountsIcon,
} from "@mui/icons-material";
import { SidebarContainer } from "./Container";
import secureLocalStorage from "react-secure-storage";
import Option from "./Option";
import { sidebarOptions } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";
import DialogBox from "../../common/DialogBox";
import { useDispatch, useSelector } from "react-redux";
import { toggleViewDialog } from "../../redux/reducer/appReducer";

const Sidebar = (props) => {
	const { index, onChangeIndex } = props;
	const { showSideMenu, expandData, onChangeExpandCollapse } =
		SidebarContainer();
	const dispatch = useDispatch();
	const { showEditForm, showAddForm, showViewForm } = useSelector(
		(state) => state.app
	);

	const handleClickOpen = (option) => {
		if (showEditForm || showAddForm || showViewForm) {
			dispatch(toggleViewDialog(true));
		} else {
			onChangeIndex(option.value);
		}
	};

	return (
		<Grid>
			<Box
				spacing={2}
				className="side-contains"
				sx={{
					mt: 10,
				}}
				direction={"column"}
			>
				{["SAD", "MSAD", "SP", "CSAD", "SM", "CM"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={DashboardIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 0 })}
								title={sidebarOptions.dashboard}
								value={0}
								to="/"
							/>
						</>
					)}

				{["SAD", "MSAD", "SM", "DEV"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={CompanyIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 1 })}
								title={sidebarOptions.company}
								value={1}
								to="/company"
							/>

							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={CategoryIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 2 })}
								title={sidebarOptions.companyProjects}
								value={2}
								to="/company-projects"
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={CategoryIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 3 })}
								title={sidebarOptions.appConfiguration}
								value={3}
								to="/app-config"
							/>
						</>
					)}
				{["SAD", "MSAD", "SM", "DEV", "CSAD", "CM", "MAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={CategoryIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 4 })}
								title={sidebarOptions.metaRob}
								value={4}
								to="/metarob"
							/>
						</>
					)}
				{["CM", "CSAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={Product}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 5 })}
								title={sidebarOptions.license}
								value={5}
								to="/license"
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 6 })}
								title={sidebarOptions.adminUsers}
								value={6}
								to="/admin-users"
							/>
						</>
					)}
				{["CM", "CSAD", "SP"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 7 })}
								title={sidebarOptions.users}
								value={7}
								to="/users"
							/>
						</>
					)}

				{["SAD", "MSAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={ManageAccountsIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.companyConfig}
							value={24}
						/> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={
								expandData.configuration
									? KeyboardDoubleArrowDownIcon
									: KeyboardDoubleArrowRightIcon
							}
							isToggleBtn={true}
							handleClick={() => onChangeExpandCollapse("configuration")}
							title={sidebarOptions.configuration}
							value={-1}
							showArrow={true}
							arrowOpen={expandData.configuration}
							showArrayIcon={true}
						/> */}
							{/* <Collapse
							in={expandData.configuration}
							timeout="auto"
							unmountOnExit
						> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.aboutUs}
								value={11}
								Icon={InfoIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.contactUs}
								value={12}
								Icon={ContactSupportIcon}
							/> */}

							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.privacyPolicy}
								value={13}
								Icon={AttachMoneyIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.refundPolicy}
								value={14}
								Icon={PrivacyTipIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.termsAndCondition}
								value={15}
								Icon={AcUnitIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.pushNotificationKey}
								value={16}
								Icon={NotificationIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.newUpdate}
								value={17}
								Icon={TipsAndUpdatesIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.faq}
								value={18}
								Icon={HelpIcon}
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.appDetails}
								value={19}
								Icon={InfoIcon}
							/> */}
							{/* </Collapse> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={SettingsIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.configurations}
							value={6}
						/> */}
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 8 })}
								title={sidebarOptions.adminUsers}
								value={8}
								to="/platform-admin-users"
							/>

							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CampaignIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.campaign}
							value={9}
						/> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.productList}
							value={10}
						/> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={
								expandData.casualGames
									? KeyboardDoubleArrowDownIcon
									: KeyboardDoubleArrowRightIcon
							}
							isToggleBtn={true}
							handleClick={() => onChangeExpandCollapse("casualGames")}
							title={sidebarOptions.casualGames}
							value={-1}
							showArrow={true}
							arrowOpen={expandData.casualGames}
						/> */}
							{/* <Collapse in={expandData.casualGames} timeout="auto" unmountOnExit> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.games}
								value={23}
								Icon={SportsEsportsIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.linePlans}
								value={3}
								Icon={SellIcon}
							/> */}
							{/* </Collapse> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.matchList}
							value={20}
						/> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.completedMatchList}
							value={21}
						/> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.withdrawMatchList}
							value={22}
						/> */}
							{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.companyProjects}
							value={25}
						/> */}
						</>
					)}
				{["SAD", "MSAD", "SM"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 9 })}
								title={sidebarOptions.allUsers}
								value={9}
								to="/all-users"
							/>

							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 11 })}
								title={sidebarOptions.licenseRequest}
								value={11}
								to="/license-requests"
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 12 })}
								title={sidebarOptions.contactUsWeb}
								value={12}
								to="/contact-us-website"
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 13 })}
								title={sidebarOptions.subscriptionWeb}
								value={13}
								to="/subscription-website"
							/>

							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={PersonIcon}
								//   onChangeIndex={onChangeIndex}
								onChangeIndex={() => handleClickOpen({ value: 14 })}
								title={sidebarOptions.blogPosts}
								value={14}
								to="/blog-website"
							/>
						</>
					)}
				{["SAD", "MSAD", "SM", "DEV", "MAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								Icon={
									expandData.settings
										? KeyboardDoubleArrowDownIcon
										: KeyboardDoubleArrowRightIcon
								}
								isToggleBtn={true}
								handleClick={() => onChangeExpandCollapse("settings")}
								title={sidebarOptions.settings}
								value={-1}
								showArrow={true}
								arrowOpen={expandData.settings}
							//   showArrayIcon={true}
							/>
							<Collapse in={expandData.settings} timeout="auto" unmountOnExit>
								<Option
									index={index}
									showSideMenu={showSideMenu}
									Icon={CategoryIcon}
									// onChangeIndex={onChangeIndex}
									onChangeIndex={() => handleClickOpen({ value: 10 })}
									title={sidebarOptions.metaRobData}
									value={10}
									to="/metarob-data"
								/>
								{/* <Option
									index={index}
									showSideMenu={showSideMenu}
									onChangeIndex={onChangeIndex}
									title={sidebarOptions.userData}
									value={30}
									Icon={SellIcon}
									to="/user-settings"
								/> */}
							</Collapse>
						</>
					)}
			</Box>
			<DialogBox />
		</Grid>
	);
};

export default Sidebar;
