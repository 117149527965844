export const blogPostUrlConstants = {
    getTableDataUrl: "website/getAllBlog",
    getSelectedBlogPostUrl: "website/getblog",
    saveBlogPostDataurl: "website/insertblog",
    updateBlogPostDataurl: "website/updateblog",
    deleteSelectedBlogPostUrl : "website/deleteblog"
  };

  export const blogPostConstants = {
    category: [
      {
        id: "Metaverse",
        title: "Metaverse",
      },
      {
        id: "AI",
        title: "AI",
      },
      {
        id: "Web3",
        title: "Web3",
      },
    ],
    status: [
      {
        id: true,
        title: "Active",
      },
      {
        id: false,
        title: "Inactive",
      },
    ],
  };