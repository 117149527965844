import  { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CommonContainer } from '../../utils/CommonFunction';
import { licenseRequestService } from './Service';
import { toggleLoadingView } from '../../redux/reducer/appReducer';

export default function LicenseRequestContainer() {

  const { showViewForm, selectedRowData } =
    useSelector((state) => state.app);

    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState("1");

    const { onClickViewForm } =
    CommonContainer();

    const licenseRequestsInfo = {
      companyName: "",
      userName: "",
      email: "",
      mobile: "",
      licenseType: "",
      createdAt: "",
      message: ""
    }

    const [rowData, setRowData] = useState(licenseRequestsInfo);
    
    const getSelectedRowData = async () => {
      dispatch(toggleLoadingView(true));
      const data = await licenseRequestService.getSelectedLicenseRequestService(
        selectedRowData?._id
      );
      setRowData(data.result);
      dispatch(toggleLoadingView(false));
    };

    useEffect(() => {
      if (showViewForm) {
        getSelectedRowData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeTabIndex = (event, newValue) => {
      setTabIndex(newValue);
    };
const onClickLicenseRequestViewForm = () => {
  onClickViewForm();
}
  return {
    tabIndex, 
    onChangeTabIndex,
     showViewForm,
      rowData,
      onClickLicenseRequestViewForm
  }
}
