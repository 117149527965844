import React from 'react'
import { useSelector } from 'react-redux';
import BlogPostsTable from './BlogPostsTable';
import BlogPostsForm from './BlogPostsForm';

export default function BlogPosts() {

	const { showAddForm, showEditForm,  showTable, showViewForm } = useSelector(
		(state) => state.app
	);
	return (
		<>
			{showTable && <BlogPostsTable />}
			{(showAddForm || showEditForm || showViewForm) && <BlogPostsForm />}
		</>
	)
}
