import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleLoadingView,
  toggleToastView,
} from "../../redux/reducer/appReducer";

// import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";


export const UsersContainer = () => {
  const { showEditForm, showAddForm, showViewForm, selectedRowData } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
    CommonContainer();

  return {
    tabIndex,
    showViewForm,
    showEditForm,
   
  };
};
