export const userLabels = {
  user_first_name_field: "firstName",
  user_first_name: "First Name",

  user_last_name_field: "lastName",
  user_last_name: "Last Name",

  user_age_field: "age",
  user_age: "Age",

  user_gender_field: "gender",
  user_gender: "Gender",

  created_date_field: "createdDate",
  created_date: "Created Date",

  user_email_field: "email",
  user_email: "Email",

  user_role_field: "role",
  user_role: "Role",

  user_password_field: "password",
  user_password: "Password",

  user_mobile_field: "mobile",
  user_mobile: "Mobile",

  user_image_field: "image",
  user_image: "Profile image",

  user_status_field: "active",
  user_status: "Status",
};
