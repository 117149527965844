export const tableLabels = [
  {
    field: "companyName",
    headerName: "Company Name",
  },
  {
    field: "email",
    headerName: "Email",
  },
  {
    field: "createdDate",
    headerName: "Created Date",
  },
  {
    field: "subscriptionStartDate",
    headerName: "Subscription Start Date",
  },
  {
    field: "subscriptionEndDate",
    headerName: "Subscription End Date",
  },
  {
    field: "noOfUserAccountsAllowed",
    headerName: "No of user accounts allowed",
  },
  {
    field: "typeOfPayment",
    headerName: "Type of subscription",
  },
  {
    field: "userAccountPrice",
    headerName: "User account price (USD)",
  },
  {
    field: "subscriptionStatus",
    headerName: "Subscription Status",
  },
];

export const filterData = {
  subscriptionStatus: [
    {
      id: 'Active',
      title: "Active",
    },
    {
      id: 'Inactive',
      title: "Inactive",
    },
    {
      id: 'Expired',
      title: "Expired",
    },
  ],
  role: [
    {
      id: "All",
      title: "All"

    },
    {
      id: 'SAD',
      title: "SAD",
    },
    {
      id: 'SM',
      title: "SM",
    },
    {
      id: 'DEV',
      title: "DEV",
    },
  ],
};
export const companyData = [
  {
    id: 1,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    // subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 2,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    // userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 3,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    // noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    id: 4,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    id: 5,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 6,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    id: 7,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 8,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    id: 9,
    companyName: "company1",
    email: "company1@gmail.com",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 10,
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 11,
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    id: 12,
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    id: 13,
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    id: 14,
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Active",
  },
  {
    companyName: "company1",
    createdDate: "12-07-2023 12:53: 30",
    subscriptionStartDate: "12-07-2023 12:53: 30",
    subscriptionEndDate: "28-07-2023 12:53: 30",
    noOfUserAccountsAllowed: 12,
    typeOfPayment: "Trial Period",
    userAccountPrice: 20,
    subscriptionStatus: "Expired",
  },
];

export const paginationNumber = [
  {
    name: "10",
    value: 10,
  },
  {
    name: "20",
    value: 20,
  },
  {
    name: "30",
    value: 30,
  },
  {
    name: "All",
    value: 0,
  },
];
