import { useContext } from "react";
import { AppContext } from "../../utils/AppContext";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { reset } from "../../redux/reducer/loginReducer";
import {
	toggleSideMenu,
	toggleViewDialog,
} from "../../redux/reducer/appReducer";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NavBarContainer } from "../NavBar/Container";

export const HeaderContainer = (props) => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { onChangeIndex } = props;
	const appContext = useContext(AppContext);
	const { showSideMenu, showEditForm, showTable, showAddForm, showViewForm } = useSelector((state) => state.app);
	const toggleTheme = () => {
		appContext?.toggleLightMode();
	};

	const {
		getIndexBasedOnRole
	} = NavBarContainer();

	
	const userRole = secureLocalStorage.getItem(storageConstants.role);

	const handleLogoClick = (e) => {
		if (!showTable) {
			dispatch(toggleViewDialog(true));
			e.preventDefault();
		} else {
			const newIndex = getIndexBasedOnRole(userRole);
			onChangeIndex(newIndex);
		}
	}

	// const onClicklogout = () => {
	// 	// secureLocalStorage.removeItem(storageConstants.email);
	// 	// secureLocalStorage.removeItem(storageConstants.role);
	// 	// secureLocalStorage.removeItem(storageConstants.companyId);
	// 	// secureLocalStorage.removeItem(storageConstants.forgotPasswordEmail);
	// 	// secureLocalStorage.removeItem(storageConstants.setTimer);
	// 	// secureLocalStorage.removeItem(storageConstants.password);
	// 	secureLocalStorage.clear();
	// 	Cookies.remove("jwtToken");
	// 	dispatch(toggleViewTable(true));
	// 	dispatch(toggleEditForm(false));
	// 	dispatch(toggleViewForm(false));
	// 	dispatch(toggleAddForm(false));
	// 	dispatch(reset());
	// 	navigate("/login");
	// };

	const toggleSideMenuBar = () => {
		dispatch(toggleSideMenu(!showSideMenu));
	};

	const breadcrumbs = (label) => (
		<Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} aria-label="breadcrumb">
			<Link key="1" color="inherit" to="/" className="dashboard-breadcrumb" onClick={(e) => handleLogoClick(e)}>
				Dashboard
			</Link>
			<Typography key="3" color="text.primary" fontWeight={600}>
				{label}
			</Typography>
		</Breadcrumbs>
	);

	const displayName = (path) => {

		if (path === '/profile') {
			return 'Profile';
		} else if (path === '/change-password') {
			return 'Change Password';
		} else if (path === '/') {
			return 'Dashboard';
		} else if (path === '/company') {
			return 'Company';
		} else if (path === '/dashboard/company') {
			return breadcrumbs('Company')
		}
		else if (path === '/company-projects') {
			return 'Company Projects';
		}
		else if (path === '/app-config') {
			return 'App Configuration';
		}
		else if (path === '/metarob') {
			return 'MetaRob';
		} else if (path === '/metarob-data') {
			return 'MetaRob Data';
		}
		else if (path === '/platform-admin-users' || path === '/admin-users') {
			return 'Admin Users';
		} else if (path === '/dashboard/platform-admin-users' || path === '/dashboard/admin-users') {
			return breadcrumbs('Admin Users')
		}
		else if (path === '/all-users' || path === '/users') {
			return 'Users';
		} else if (path === '/dahsboard/all-users' || path === '/dashboard/users') {
			return breadcrumbs('Users')
		}
		else if (path === '/license') {
			return 'License';
		} else if (path === '/license-requests') {
			return 'License Requests';
		} else if (path === '/contact-us-website') {
			return 'Contact Us Details';
		} else if (path === '/subscription-website') {
			return 'Subscription Details';
		} else if (path === '/blog-website') {
			return 'Blog Posts';
		}

	};

	const roleDisplay = () => {
		const role = secureLocalStorage.getItem(storageConstants.role);
		switch (role) {
			case 'CM':
				return 'Company';
			case 'SAD':
				return 'Super Admin';
			case 'MSAD' :
				return 'Main Super Admin';
			case 'CSAD':
				return 'Client Super Admin';
			case 'SM':
				return 'Sales Manager';
			case 'DEV':
				return 'Developers';
			case 'SP':
				return 'Sales Person';
			case 'MAD':
				return 'MetaRob Admin';
			default:
				return '';
		}

	};

	return {
		toggleTheme,
		appContext,
		// onClicklogout,
		toggleSideMenuBar,
		roleDisplay,
		displayName,
		dispatch,
		navigate,
		showAddForm,
		showTable,
		showEditForm,
		showViewForm,
		handleLogoClick,
		onChangeIndex
	};
};
