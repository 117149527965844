import { baseUrl } from "./baseUrl";
import Cookies from "js-cookie";
// import jwt from "jwt-decode";

// const isTokenExpired = () => {
//   const access_token = Cookies.get("jwtToken");
//   if (access_token) {
//     const deocdedToken = jwt(access_token);
//     var current_time = new Date().getTime() / 1000;
//     if (current_time > deocdedToken.exp) {
//       return true;
//     }
//     return false;
//   } else {
//     return true;
//   }
// };

const getApi = async (url) => {
  try {
    const response = await fetch(`${baseUrl}${url}`, {
      method: "GET",
      headers: {
        Authorization: Cookies.get("jwtToken"),
      },
    });
    return await response.json();
  } catch (err) {
    console.log("error---", err);
  }
};

const postApi = async (url, data) => {
  try {
    const response = await fetch(`${baseUrl}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: Cookies.get("jwtToken"),
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log("error---", err);
  }
};

const updateApi = async (url, data) => {
  try {
    const response = await fetch(`${baseUrl}${url}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: Cookies.get("jwtToken"),
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log("error---", err);
  }
};

const deleteApi = async (url, data) => {
  try {
    const response = await fetch(`${baseUrl}${url}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: Cookies.get("jwtToken"),
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log("error---", err);
  }
};

export const apiServices = {
  getApi,
  postApi,
  updateApi,
  deleteApi,
};
