export const profileConstants = {
  editProfileContent: "Edit Profile",
  changePassword: "Change Password",
  update: "Update",
  cancel: "Cancel",
};


export const profileLabels = {
	firstName: "First Name",
	lastName: "Last Name",
	companyName: "Company Name",
	mobile: "Mobile",
  email: "Email",
  role: "Role",
  image: "Change Profile",
  oldPassword: "Old Password",
  newPassword: "Set New Password",
  confirmPassword: "Confirm New Password"
}

export const profileUrlConstants = {
	updateProfileDataurl: 'company/updateProfile', // update appConfiguration data
  changePasswordurl: 'company/passwordChange'
};