import React from 'react'
import { Typography, Button } from "@mui/material";
import OtpFunctionality from './Container';
import OtpInput from 'react-otp-input';
import "./Style.scss";
import { otpHeadings } from './Constants';
import { otpTextMessage } from '../otp/Constants';
import { useSelector } from "react-redux";
import CircleLoader from "../../common/CircleLoader";
import { LoginFunctionality } from "../login/Container";
import Carousel from "react-material-ui-carousel";
import { headings } from '../login/Constants';
import aautiLogo from "../../assets/images/aauti_logo.png";


export default function OTPScreen(props) {
  const { isLoading } = useSelector((state) => state.app);
  const { items } = LoginFunctionality(props);

  const {
    otp,
    onChangeOtp,
    onSubmit,
    onChangeEmail,
    onResendOtp,
    otpMissMatch,
    otpValidation,
    otpResendText,
    otpExpired,timer
  } = OtpFunctionality(props)

  const Item = (props) => {
    return (
      <div>
        <img
          src={props.item.image}
          className="image-styles"
          alt={props.item.name}
          key={props.item.key}
        />
        <div className="text-container">
          <Typography className="about-us-styles">
            {headings.aboutUs}
          </Typography>
          <Typography className="show-case-text-styles">
            {headings.showcase}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
    {isLoading && <CircleLoader/> }
    <div className="custom-card-container">
    <div className="sub-custom-card-container">
          <img src={aautiLogo}
                className="logo-styles"
                alt="ss"
                />
        <div className='otp-sub-custom-card-container'>
          <Typography className='otp-text'>{otpHeadings.otp}</Typography>

                {otpExpired ? (<Typography className='otp-error-text-styling'>{otpTextMessage.otpExpiredDescription}</Typography>):
                  (<Typography className='otp-error-text-styling'>{otpTextMessage.otpExpireText}<span className="otp-timer-text"> {timer} </span>{otpTextMessage.timerinSeconds}</Typography>)
                }
                 <OtpInput
                      value={otp}
                      onChange={onChangeOtp}
                      numInputs={4}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props}
                      className="otp-input-styles"
                      />}
                      inputStyle={{width: 20, height: 20, margin: 5}}
                      inputType="number"
                      />    
                {otpExpired ? (null) : ( otpMissMatch && <Typography className="otp-text-styling">{otpValidation}</Typography>)}
                {otpResendText ? <Typography variant='body2' className="email-text-styling" >{otpTextMessage.emailSent}</Typography> : null}
                {otpExpired &&  <Typography variant='body2' className='resend-otp-styling' onClick={onResendOtp}>{otpHeadings.resendOtp}</Typography> }
                <Button variant="contained" type="submit" onClick={onSubmit} className='forgot-password-submit-button'>{otpHeadings.buttonText}</Button>
                <Typography className='email-description-styles' variant='body2'>{otpTextMessage.changeEmail}<span className='email-text-styling' variant="body2" onClick={onChangeEmail}> {otpTextMessage.clickhere}</span></Typography>
        </div>
    </div>
    <div className="custom-image-card">
          <Carousel
            animation="slide"
            indicatorIconButtonProps={{
              className: "indicator-icon-button-props",
            }}
            activeIndicatorIconButtonProps={{
              className: "active-indicator-icon-button-props",
            }}
            indicatorContainerProps={{
              className: "indicator-container-props",
            }}
            navButtonsWrapperProps={{
              className: "nav-button-wrapper-props",
            }}
          >
            {items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </Carousel>
    </div>
    </div>
    </div>
    )}
