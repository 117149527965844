import React from "react";
import { useSelector } from "react-redux";
import CompanyTable from "./CompanyTable";
import CompanyForm from "./CompanyForm";
import { useLocation } from "react-router-dom";

const Company = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

  const {state }= useLocation()
  const subscriptionStatusF = state?.subscriptionStatusF ?? "Active"

  return (
    <>
      {showTable && <CompanyTable subscriptionStatusF={subscriptionStatusF} />}
      {(showAddForm || showEditForm || showViewForm) && <CompanyForm />}
    </>
  );
};

export default Company;
