import React from 'react'
import DialogBox from '../../common/DialogBox'
import { ProfileContainer } from './Container';
import { Button } from '@mui/material';
import TextInputField from '../../common/TextInputField';
import { profileConstants, profileLabels } from './Constants';

export default function ChangePassword() {

    const {
		onChangeOldPassword,
		onChangeNewPassword,
		onChangeConfirmPassword,
		onClickChangePasswordCancel,
		onClickChangePasswordUpdate,
		// onClickSave,
	} = ProfileContainer();

  return (
   
    <div className="global-configurations-profile-card">

        <DialogBox />

            <div className="global-configurations-profile-styles">

                <div className="profile-edit-form">
                    <TextInputField
                        className="input-text"
                        label={profileLabels.oldPassword}
                        // value={passwordData?.oldPassword}
                        onChangeEvent={onChangeOldPassword}
                    />
                    <TextInputField
                        className="input-text"
                        label={profileLabels.newPassword}
                        // value={passwordData?.newPassword}
                        onChangeEvent={onChangeNewPassword}
                    />
                    <TextInputField
                        className="input-text"
                        label={profileLabels.confirmPassword}
                        // value={passwordData?.confirmPassword}
                        onChangeEvent={onChangeConfirmPassword}
                    />
                    
                </div>

            </div>
            <div className="update-cancel-container">
                <Button
                    variant="outlined"
                    className="global-form-action-button"
                    onClick={onClickChangePasswordUpdate}
                >
                    {profileConstants.update}
                </Button>
                <Button
                    variant="outlined"
                    className="global-form-action-button"
                    onClick={onClickChangePasswordCancel}
                >
                    {profileConstants.cancel}
                </Button>
            </div>
    </div>

  )
}
