export const platformAdminConstants = {
  companyRoles: [
    { id: "SAD", title: "Super Admin" },
    // { id: "MSAD", title : "Main Super Admin" },
    { id: "SM", title: "Sales Manager" },
    { id: "DEV", title: "Developers" },
  ],
  status: [
    {
      id: true,
      title: "Active",
    },
    {
      id: false,
      title: "Inactive",
    },
  ],
};

export const platformAdminUrlConstants = {
  getTableDataUrl: "plaformAdmin/table",
  getSelectedCompanyDataUrl: "plaformAdmin/table",
  saveCompanyDataurl: "plaformAdmin/register",
  updateCompanyDataurl: "plaformAdmin/update",
  deleteCompanyDataUrl: "plaformAdmin/delete",
};
