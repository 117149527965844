import { allEndUsersLabels } from "../../assets/labels/allEndUsers";
import { getDateTimeFormat } from "../helper";

export const EndUsersColumns = () => {
  const end_users_columns = [
    {
      field: allEndUsersLabels.first_name_field,
      headerName: allEndUsersLabels.first_name,
    },
    {
        field: allEndUsersLabels.last_name_field,
        headerName: allEndUsersLabels.last_name,
      },
    {
        field: allEndUsersLabels.company_name_field,
        headerName: allEndUsersLabels.company_name,
      },
      {
        field: allEndUsersLabels.email_field,
        headerName: allEndUsersLabels.email,
      },
      {
        field: allEndUsersLabels.mobile_field,
        headerName: allEndUsersLabels.mobile,
      },

      {
        field: allEndUsersLabels.status_field,
        headerName: allEndUsersLabels.status,
      },
   
  ];

  return end_users_columns;
};
