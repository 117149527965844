import { createContext, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "./globalConstant";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [lightMode, setLightMode] = useState(
    secureLocalStorage.getItem(storageConstants.lightMode) == null
      ? "light"
      : secureLocalStorage.getItem(storageConstants.lightMode) === "light"
      ? "light"
      : "dark"
  );

  const toggleLightMode = () => {
    secureLocalStorage.setItem(
      storageConstants.lightMode,
      lightMode === "light" ? "dark" : "light"
    );
    setLightMode((value) => (value === "light" ? "dark" : "light"));
  };

  return (
    <AppContext.Provider
      value={{
        lightMode,
        toggleLightMode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
