import { apiServices } from "../../service/apiService";
import { companyAdminUrlConstants } from "./Constants";

const getSelectedCompanyAdminDataService = async (id, companyId) => {
  return await apiServices.getApi(
    `${companyAdminUrlConstants.getSelectedUserDataUrl}/${id}/${companyId}`
  );
};

const saveCompanyAdminDataService = async (data) => {
  return await apiServices.postApi(
    companyAdminUrlConstants.saveUserDataurl,
    data
  );
};

const updateCompanyAdminDataService = async (data) => {
  return await apiServices.updateApi(
    companyAdminUrlConstants.updateUserDataurl,
    data
  );
};

const deleteCompanyAdminDataService = async (id, companyId) => {
  return await apiServices.deleteApi(
    `${companyAdminUrlConstants.deleteUserDataUrl}/${id}/${companyId}`
  );
};

export const companyAdminService = {
  getSelectedCompanyAdminDataService,
  saveCompanyAdminDataService,
  updateCompanyAdminDataService,
  deleteCompanyAdminDataService,
};
