import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { Add as AddIcon } from '@mui/icons-material';

const InputFileUpload = ({
  acceptType = "image/*",
  label,
  isMultiple = false,
  onChangeEvent,
  isRequired = false,
  isDisabled = false,
  initialFileUpload = false,
  resetKey, // New prop to force re-rendering
}) => {
  const [inputKey, setInputKey] = useState(Date.now()); // Unique key for each render

  const handleFileChange = (event) => {
	onChangeEvent(event);  // Handle file upload
	setInputKey(Date.now()); // Reset key after removal
  };

  return (
	<>
	  {initialFileUpload ? (
		<Tooltip title={`Add ${label}`} arrow placement="top">
		  <Button
			component="label"
			variant="contained"
			startIcon={<AddIcon className="tb-act-icons-add" />}
			sx={{
			  m: 1,
			  background: "#00000099",
			  height: 70,
			  borderRadius: 35,
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
		  >
			{`${isRequired ? "*" : ""}`}
			<input
			  key={inputKey} // This resets the input
			  hidden
			  accept={acceptType}
			  multiple={isMultiple}
			  type="file"
			  onChange={handleFileChange}
			  disabled={isDisabled}
			/>
		  </Button>
		</Tooltip>
	  ) : (
		<Button
		  component="label"
		  variant="contained"
		  startIcon={<CloudUploadIcon />}
		  href="#file-upload"
		  sx={{ m: 1, background: "#00000099", height: 100 }}
		>
		  {label} {`${isRequired ? "*" : ""}`}
		  <input
			key={inputKey} // Resetting input here as well
			hidden
			accept={acceptType}
			multiple={isMultiple}
			type="file"
			onChange={handleFileChange}
			disabled={isDisabled}
		  />
		</Button>
	  )}
	</>
  );
};

export default InputFileUpload;
