import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPasswordService } from "./Service";
import { toggleLoadingView } from "../../redux/reducer/appReducer";
import { reset } from "../../redux/reducer/loginReducer";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

export default function ResetPasswordFunctionality() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const savedUserLoginEmail = secureLocalStorage.getItem(
    storageConstants.forgotPasswordEmail
  );

  const ValidFields = (newPassword, confirmPassword) => {
    if (
      newPassword === confirmPassword &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      setPasswordMatch(true);
    } else {
      if (newPassword.length > 0 && confirmPassword.length > 0) {
        setPasswordMatch(false);
      }
    }
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    setNewPassword(newPasswordValue);
    setPasswordLength(false);
    ValidFields(newPasswordValue, confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordLength(false);
    ValidFields(newPassword, confirmPasswordValue);
  };

  const onSubmit = async () => {
    if (newPassword.length > 5 && confirmPassword.length > 5) {
      if (passwordMatch) {
        dispatch(toggleLoadingView(true));
        const response = await resetPasswordService.validateResetPassword({
          email: savedUserLoginEmail,
          password: newPassword,
        });
        if (response?.result !== null) {
          dispatch(toggleLoadingView(false));
          dispatch(reset());
          navigate("/login");
        }
        setPasswordValidation(false);
      } else {
        dispatch(toggleLoadingView(false));
        setPasswordValidation(true);
      }
    } else {
      setPasswordValidation(false);
      setPasswordLength(true);
    }
  };

  return {
    onSubmit,
    newPassword,
    handleNewPasswordChange,
    confirmPassword,
    handleConfirmPasswordChange,
    passwordMatch,
    passwordValidation,
    passwordLength,
  };
}
