import  { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { storageConstants } from '../../utils/globalConstant';
// import { CommonContainer } from '../../utils/CommonFunction';
import { toggleLoadingView, toggleToastView } from '../../redux/reducer/appReducer';
import { licenseService } from './Service';
import { getDateTimeFormat } from '../../utils/helper';

export const LicenseContainer = () => {

    const { showTable } = useSelector(
        (state) => state.app
      );
      const dispatch = useDispatch();
      // const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
      // CommonContainer();
   
    const [userData, setUserData] = useState();
    const [rowData, setRowData] = useState({});
    // const [resetData, setResetData] = useState({});

    useEffect(() => {
        const data = secureLocalStorage.getItem(storageConstants?.userData);
        // console.log("data---", data)
        if (data) {
          setUserData(data);
        }
      }, []); // Empty dependency array means this useEffect runs only once when the component mounts
    //   console.log("userDataa-----", userData)

    // const formatDate = (dateString) => {
    //   const dateObj = new Date(dateString);
    //   const day = (`0${dateObj.getUTCDate()}`).slice(-2);
    //   const month = (`0${dateObj.getUTCMonth() + 1}`).slice(-2); // Months are zero-based
    //   const year = dateObj.getUTCFullYear();
    //   return `${day}/${month}/${year}`;
    // };
      
      useEffect(() => {
        if (userData) {
          const licenseInfo = {
            companyId: userData?.companyId,
            companyName: userData?.companyName,
            email: userData?.email,
            mobile: userData?.mobile,
            userName: userData?.userName,
            licenseType: userData?.subscriptionPlanType,
            startDate: getDateTimeFormat(userData?.subscriptionStartDate),
            expireDate: getDateTimeFormat(userData?.subscriptionEndDate),
            message: ""
          };
          setRowData(licenseInfo);
        }
      }, [userData]);  // This useEffect runs when userData changes



    const onChangeMessage = (event) => {
        setRowData({ ...rowData, message: event.target.value });
      };

      const onClickSave = async () => {
        if (
          rowData?.message.trim() === ""
        ) {
          dispatch(
            toggleToastView({
              showToast: true,
              message: "* Please fill the required fields",
              isError: true,
            })
          );
        } else {
          dispatch(toggleLoadingView(true));
          let response;
        //   if (showAddForm) {
            response = await licenseService.sendLicensDataService(rowData);
        //   } else {
            // response = await companyService.updateCompanyDataService(rowData);
        //   }
          if (response.result.length === 0) {
            dispatch(
              toggleToastView({
                showToast: true,
                message: response.message,
                isError: true,
              })
            );
            // setRowData(resetData);
          } else {
            dispatch(
              toggleToastView({
                showToast: true,
                message: response.message,
                isError: false,
              })
            );
            setRowData(prevState => ({
              ...prevState,
              message: ""
          }));
          }
          dispatch(toggleLoadingView(false));
        }
        
      };
      // const onClickReset = () => {
      //   setRowData(resetData);
      // };


  return {
    showTable,
    onChangeMessage,
    rowData,
    userData,
    onClickSave,
    // onClickReset
  }
}
