export const forgotPasswordHeadings={
    forgotPassword:'Forgot Password',
    sendOTP:'Send OTP!!'

}
export const forgotPasswordTextMessage={
   emailText:"Please enter your recovery email",
   error: "error",
}

export const localStorageKeys ={
    forgotPasswordEmail: "forgotPasswordEmail",
    setTimer:"setTimer"
}