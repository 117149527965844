import React, { useEffect, useState } from "react";
// import { RouterProvider } from "react-router-dom";

import Offline from "./components/offline/Offline";
import Router from "./routes/Router";
import "./App.css";
import { AppProvider } from "./utils/AppContext";

const App = () => {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    const onlineHandler = () => {
      setOnline(true);
    };

    const offlineHandler = () => {
      setOnline(false);
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <div className="App">
      <AppProvider>
        {online ? (
          // <RouterProvider router={Router} />
          <Router />
        ) : (
          <Offline />
        )}
      </AppProvider>
    </div>
  );
};

export default App;
