import { useEffect, useState } from "react";
import { CommonContainer } from "../../utils/CommonFunction";
import { useDispatch, useSelector } from "react-redux";
import { checkNumericExpression, imageUpload } from "../../utils/helper";
import { toggleEditForm, toggleLoadingView, toggleToastView, toggleViewDialog, toggleViewTable } from "../../redux/reducer/appReducer";
import { ProfileService } from "./Service";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";
import { companyService } from "../company/Service";
import { useNavigate } from "react-router-dom";

export const ProfileContainer = () => {
  const { showEditForm, showTable, showAddForm } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const { onClickShowTable, onClickEditForm } = CommonContainer();
  const userData = secureLocalStorage.getItem(storageConstants.userData);

  const profileInfo = {
    firstName: "",
    lastName: "",
    companyName: "",
    mobile: "",
    email: "",
    role: "",
    image: ''
  };

  const passwordChangeData = {
    email: userData?.email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const [rowData, setRowData] = useState(profileInfo);
  const [passwordData, setPasswordData] = useState(passwordChangeData);
  const [resetData, setResetData] = useState({});
  const [userDataa, setUserDataa] = useState(profileInfo);
  const [imageToRemove, setImageToRemove] = useState([]);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    specialChar: false,
    samePassword: false,
  });

  useEffect(() => {
    const userData = secureLocalStorage.getItem(storageConstants?.userData);
    if (userData) {
      setUserDataa(userData);
    }
  }, []);

  const getSelectedRowData = async () => {
    dispatch(toggleLoadingView(true));
    const data = await companyService.getProfileDataService(userData?._id);
    setRowData(data?.result);
    setResetData(data?.result);
    dispatch(toggleLoadingView(false));
  };

  useEffect(() => {
    if (showEditForm || showTable) {
      getSelectedRowData();
    }
  }, [showEditForm, showTable]);

  const handleEditProfileClick = () => {
    setShowProfileForm(true);
    setShowPasswordForm(false);
    onClickProfileEditContent();
  };

  const handleChangePasswordClick = () => {
    setPasswordData({
      email: userDataa?.email,
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    })
    setPasswordValidation(resetData)
    setShowProfileForm(false);
    setShowPasswordForm(true);
    onClickChangePasswordContent();
  };

  const onChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onChangeFirstName = (event) => {
    setRowData({ ...rowData, firstName: event.target.value });
  };

  const onChangeLastName = (event) => {
    setRowData({ ...rowData, lastName: event.target.value });
  };

  const onChangeCompanyName = (event) => {
    setRowData({ ...rowData, companyName: event.target.value });
  };

  const onChangeMobile = (event) => {
    if (
      checkNumericExpression(event.target.value) &&
      event.target.value.length <= 10
    ) {
      setRowData({ ...rowData, mobile: event.target.value });
    } else if (event.target.value === "") {
      setRowData({ ...rowData, mobile: "" });
    }
  };

  const onChangeEmail = (event) => {
    setRowData({ ...rowData, email: event.target.value });
  };

  const onChangeRole = (event) => {
    setRowData({ ...rowData, role: event.target.value });
  };

  const onChangeProfileImage = async (event) => {
    dispatch(toggleLoadingView(true));
    const res = await imageUpload(event);
    if (res.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "Please upload again",
          isError: true,
        })
      );
    } else {
      setRowData({ ...rowData, image: res?.result?.image });
    }
    dispatch(toggleLoadingView(false));
  };

  const onRemoveImage = async () => {
    const image = rowData.image;
    imageToRemove.push({ image: image });
    setImageToRemove(imageToRemove);

    setRowData({
      ...rowData,
      image: "",
    });
  };

  const validateOldPassword = (oldPassword) => {
    // Compare entered oldPassword with userDataa.password
    return oldPassword === rowData?.password;
  };
  

  const onChangeOldPassword = (event) => {
    const oldPassword = event.target.value;
    setPasswordData({ ...passwordData, oldPassword: oldPassword });
    validateOldPassword(oldPassword);
  };

  const onChangeNewPassword = (event) => {
    const newPassword = event.target.value;
    setPasswordData({ ...passwordData, newPassword: newPassword });
    validatePassword(newPassword);
  };

  const onChangeConfirmPassword = (event) => {
    setPasswordData({ ...passwordData, confirmPassword: event.target.value });
  };

  const validatePassword = (password) => {
    const validation = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      numeric: /[0-9]/.test(password),
      specialChar: /[^A-Za-z0-9]/.test(password),
      samePassword: password != rowData.password
    };
    setPasswordValidation(validation);
  };

  const onClickProfileEditForm = () => {
    onClickEditForm();
  };

  const onClickCancel = () => {
    dispatch(toggleViewDialog(true));
  };


  const onClickProfileEditContent = async () => {
    dispatch(toggleEditForm(!showEditForm));
    dispatch(toggleViewTable(false));
  };

  const onClickChangePasswordContent = async () => {
    dispatch(toggleEditForm(!showEditForm));
    dispatch(toggleViewTable(false));
  };

  const onClickChangePasswordUpdate = async () => {
    if (
      passwordData?.oldPassword.trim() === "" ||
      passwordData?.newPassword.trim() === "" ||
      passwordData?.confirmPassword.trim() === ""
    ) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "* Please fill the required fields",
          isError: true,
        })
      );
    } else {
      dispatch(toggleLoadingView(true));
      let response = await ProfileService.changePasswordService(passwordData);
      if (response.result.length === 0) {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: true,
          })
        );
      } else {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: false,
          })
        );
        onClickShowTable();
      }
      dispatch(toggleLoadingView(false));
    }
  };

  const onClickProfileUpdate = async () => {
    if (
      rowData?.firstName.trim() === "" ||
      rowData?.companyName.trim() === "" ||
      rowData?.mobile.trim() === ""
    ) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "* Please fill the required fields",
          isError: true,
        })
      );
    } else {
      dispatch(toggleLoadingView(true));
      let response = await ProfileService.updateProfileDataService(rowData);
      if (response.result.length === 0) {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: true,
          })
        );
      } else {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: false,
          })
        );
        onClickShowTable();
      }
      dispatch(toggleLoadingView(false));
    }
  };

  return {
    tabIndex,
    onChangeTabIndex,
    onChangeFirstName,
    onChangeLastName,
    onChangeCompanyName,
    onChangeEmail,
    onChangeMobile,
    onChangeRole,
    onClickProfileEditForm,
    onClickCancel,
    onClickProfileEditContent,
    onClickProfileUpdate,
    showEditForm,
    rowData,
    passwordData,
    showTable,
    showAddForm,
    onChangeProfileImage,
    onClickChangePasswordUpdate,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    onRemoveImage,
    onClickChangePasswordContent,
    handleChangePasswordClick,
    handleEditProfileClick,
    showProfileForm,
    showPasswordForm,
    passwordValidation,
    userDataa,
    passwordData,
    validateOldPassword,
    validatePassword
  };
};
