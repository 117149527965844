import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CommonContainer } from '../../utils/CommonFunction';
import { subscriptionWebService } from './Service';
import { setSelectedRowData, toggleLoadingView, toggleToastView } from '../../redux/reducer/appReducer';
import { subscriptionWebUrlConstants } from './Constants';
import { getTableData } from '../tableComponent/tableComponent.slice';

export default function SubscriptionWebContainer() {

	const { showViewForm, selectedRowData } =
		useSelector((state) => state.app);

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState("1");

	const { onClickViewForm } = CommonContainer();

	const subscriptionWebInfo = {
		type: "",
		email: "",
		createdAt: "",
	}

	const [rowData, setRowData] = useState(subscriptionWebInfo);

	const getSelectedRowData = async () => {
		dispatch(toggleLoadingView(true));
		const data = await subscriptionWebService.getSelectedSubscriptionWebService(
			selectedRowData?._id
		);
		setRowData(data?.result);
		dispatch(toggleLoadingView(false));
	};

	useEffect(() => {
		if (showViewForm) {
			getSelectedRowData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};
	const onClickSubscriptionWebViewForm = () => {
		onClickViewForm();
	}

	const onClickDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await subscriptionWebService.deleteSelectedSubscriptionWebService(
			selectedRowData?._id
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(subscriptionWebUrlConstants.getTableDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};

	const handleDeleteClick = () => {
		setShowDeleteConfirmation(true);
	};

	const handleDeleteConfirm = () => {
		onClickDelete();
		setShowDeleteConfirmation(false);
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};
	return {
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		rowData,
		onClickSubscriptionWebViewForm,
		onClickDelete,
		handleDeleteClick,
		handleDeleteConfirm,
		handleDeleteCancel,
		showDeleteConfirmation
	}
}
