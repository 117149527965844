import { Box, Grid, Tab, Typography } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import { PlatformAdminUserContainer } from "./Container";
import FormActionButton from "../../common/FormActionButton";
import { platformAdminLabels } from "../../assets/labels/platformAdmin";
import DropdownField from "../../common/DropdownField";
import { platformAdminConstants } from "./Constants";
import InputFileUpload from "../../common/InputFileUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const PlatformAdminUserForm = () => {
  const {
    onChangeFirstName,
    onChangeLastName,
    onChangeEmail,
    onChangeMobile,
    onChangeRole,
    tabIndex,
    onChangeTabIndex,
    showViewForm,
    showEditForm,
    onClickSave,
    onClickReset,
    rowData,
    onChangeProfileImage,
    onChangeStatus,
    onRemoveImage
  } = PlatformAdminUserContainer();

  return (
    <Grid className="global-form">
      <Box
        className="global-form-container"
        sx={{
          typography: "body1",
          height: "95%",
        }}
      >
        <TabContext value={tabIndex}>
          <Box className="global-form-tab-header">
            <TabList
              onChange={onChangeTabIndex}
              aria-label="lab API tabs example"
            >
              <Tab label="Admin User Form" value="1" />
            </TabList>
          </Box>
          <TabPanel value="1" className="global-form-fields-container">
            <TextInputField
              label={platformAdminLabels.platform_admin_firstname}
              value={rowData?.firstName}
              onChangeEvent={onChangeFirstName}
              isRequired={true}
              isDisabled={showViewForm}
            />
             <TextInputField
              label={platformAdminLabels.platform_admin_lastname}
              value={rowData?.lastName}
              onChangeEvent={onChangeLastName}
              isRequired={false}
              isDisabled={showViewForm}
            />
            <TextInputField
              label={platformAdminLabels.platform_admin_email}
              value={rowData?.email}
              onChangeEvent={onChangeEmail}
              isRequired={true}
              isMail={true}
              isDisabled={showViewForm || showEditForm}
            />
                <TextInputField
              label={platformAdminLabels.platform_admin_mobile}
              value={rowData?.mobile}
              onChangeEvent={onChangeMobile}
              isRequired={true}
              isDisabled={showViewForm}
            />
            <DropdownField
              label={platformAdminLabels.platform_admin_role}
              value={rowData?.role}
              onChangeEvent={onChangeRole}
              data={platformAdminConstants.companyRoles}
              isDisabled={showViewForm}
            />
            <DropdownField
              label={platformAdminLabels.platform_admin_status}
              value={rowData?.active}
              onChangeEvent={onChangeStatus}
              data={platformAdminConstants.status}
              isDisabled={showViewForm}
              isRequired={false}
            />
            <Grid
              sx={{
                display: "flex",
              }}
            >
              <InputFileUpload
                label={platformAdminLabels.platform_admin_profile_image}
                onChangeEvent={onChangeProfileImage}
                isRequired={false}
                isDisabled={showViewForm}
              />

              {rowData?.image ? (
                <>
                 <img
                  src={rowData?.image}
                  alt="profile_image"
                  style={{
                    width: 95,
                    height: 95,
                    margin: 8,
                  }}
                />
                {!showViewForm && (
										<CancelIcon
											onClick={() => onRemoveImage()}
											sx={{ color: "#ff0000", fontSize: 16 }}
										/>
									)}
                </>
               
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#00000099",
                    fontSize: 12,
                  }}
                >
                  No image uploaded
                </Typography>
              )}
            </Grid>
          </TabPanel>
          <FormActionButton
            onClickSave={onClickSave}
            onClickReset={onClickReset}
          />
        </TabContext>
        <DialogBox />
      </Box>
    </Grid>
  );
};

export default PlatformAdminUserForm;
