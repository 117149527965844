export const contactWebLabels = {

	full_name_field: "fullName",
	full_name: "Full Name",

	mobile_field: "mobile",
	mobile: "Mobile",

	email_field: "email",
	email: "Email",

	subject_field: "subject",
	subject: "Subject",

	message_field: 'message',
	message: 'Message',

	created_date_field: 'createdAt',
	created_date: 'Created Date',

	// status_field: 'status',
	// status: 'Status'

};
