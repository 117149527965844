import React from 'react'
import TableComponent from '../tableComponent/TableComponent';
// import ContactWebContainer from './Container';
import secureLocalStorage from 'react-secure-storage';
import { storageConstants } from '../../utils/globalConstant';
import { contactWebColumns } from '../../utils/tableColumns/contactWebColumns';
import { contactWebUrlConstants } from './Constants';
import ContactWebContainer from './Container';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

export default function ContactWebTable() {

	const contactWebColumnFields = contactWebColumns();
	const {
		onClickContactWebViewForm,
        onClickDelete,
		showDeleteConfirmation,
		handleDeleteConfirm,
		handleDeleteCancel,
		handleDeleteClick,
	} = ContactWebContainer();

	const userRole = secureLocalStorage.getItem(storageConstants.role);

	return (
		<>
			<Dialog
				open={showDeleteConfirmation}
				onClose={handleDeleteCancel}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to delete this item?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color='primary' autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<TableComponent
			columnFields={contactWebColumnFields}
			url={contactWebUrlConstants.getTableDataUrl}
			onClickViewForm={onClickContactWebViewForm}
            onClickDelete={handleDeleteClick}
			showAddBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
			showEditBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
			// showDeleteBtn={!["SAD", "MSAD", "SM"].includes(userRole)}
		// showViewBtn = {!["SAD", "MSAD", "SM"].includes(userRole)}
		/>
		</>
	
	);
}
