export const otpTextMessage={
    otpExpiredDescription:"OTP expired. please click on Resend OTP !!",
    otpExpireText:"OTP will expire in",
    timerinSeconds:'Seconds',
    emailSent:"OTP sent to your email. please have a look !!",
    changeEmail:"Need to change Email ? ",
    clickhere:"click here",
}

export const otpHeadings={
    otp:'Enter OTP',
    resendOtp:"Resend OTP",
    buttonText:"Okay,got it!"
}