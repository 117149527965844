import React from "react";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
// import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import "./Style.scss";
import secureLocalStorage from "react-secure-storage";
import { Link } from "react-router-dom";
import aautiverse_logo from "../../assets/images/Aautiverse.png";
import profile from "../../assets/images/profile_img.png";
import { NavBarContainer } from "./Container";
import { storageConstants } from "../../utils/globalConstant";
import { ProfileContainer } from "../profile/Container";
// import { useDispatch, useSelector } from "react-redux";
import { HeaderContainer } from "../header/Container";
import DialogBox from "../../common/DialogBox";
import { toggleViewDialog } from "../../redux/reducer/appReducer";

const settings = ["Profile", "Logout"];

export default function NavBar(props) {
	const { roleDisplay } =
		HeaderContainer(props);

	const { onChangeIndex } = props;
	const {
		onClicklogout,
		anchorElUser,
		handleCloseUserMenu,
		handleOpenUserMenu,
		handleeClick,
		getIndexBasedOnRole,
		showTable,
		dispatch,
		navigate
	} = NavBarContainer();

	const handleLogoClick = (e) => {
		if (!showTable) {
			dispatch(toggleViewDialog(true));
			e.preventDefault();
		} else {
			const newIndex = getIndexBasedOnRole(userRole);
			onChangeIndex(newIndex);
		}
	}

	const handleMenuClick = (setting) => {
		if (setting === "Logout") {
			onClicklogout();
		} else if (setting === "Profile") {
			if (showTable) {
				onChangeIndex(-999);
				navigate("/profile");
				handleCloseUserMenu();
			} else {
				dispatch(toggleViewDialog(true));
			}

		} else {
			handleCloseUserMenu();
		}
	};


	const { rowData } = ProfileContainer();

	const userRole = secureLocalStorage.getItem(storageConstants.role);
	// const userData = secureLocalStorage.getItem(storageConstants.userData);

	return (
		<div position="static" className="navbar">
			<div className="logo" >
				<Link to="/"
					onClick={(e) => {
						handleLogoClick(e)
					}}>
					<img
						src={aautiverse_logo}
						className="aauti-logo"
						alt="ss"
						onClick={handleeClick}
					/>
				</Link>
			</div>

			<div className="user-info">
				<span className="user-name">
					<a className="name">{rowData?.firstName + ' ' + rowData?.lastName}</a>
				</span>

				<span className="user-role">
					{roleDisplay()}
				</span>
			</div>

			<div className="user-profile-toggle">
				<Tooltip title="Open settings">
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<img
							src={rowData?.image || profile}
							alt="profile"
							className="profile-image"
						/>
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: "45px" }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					{settings.map((setting) => (
						<MenuItem key={setting} onClick={() => handleMenuClick(setting)}>
							<Typography textAlign="center">{setting}</Typography>
						</MenuItem>
					))}
				</Menu>
			</div>

			<DialogBox />
		</div>
	);
}