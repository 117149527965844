import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';
import '../themes/globalStyle.scss';
import { baseUrl } from '../service/baseUrl';
import Cookies from 'js-cookie'; // Ensure to have js-cookie for handling cookies
import { Typography } from '@mui/material';
// import { Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';

const RichTextEditor = ({
  label,
  value,
  onChangeEvent,
  isRequired = false,
  isDisabled = false,
//   showErrorOnBlur = false,
  descriptionStyling = {},
  className = "global-form-rich-text-editor",
  size = "small",
  helperText
}) => {
  const [editorData, setEditorData] = useState(value);
  const [showError, setShowError] = useState(false);

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    onChangeEvent(data);
  };

  const handleBlur = () => {
    if (isRequired && !editorData.trim()) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

const imageUpload = async (loader) => {
    const formData = new FormData();
    
    // Get the file from the loader
    const file = await loader.file;
    formData.append('image', file);
  
    try {
      const response = await fetch(`${baseUrl}common/imageUpload`, {
        method: 'POST',
        headers: {
          Authorization: Cookies.get('jwtToken'),
        },
        body: formData,
      });
  
      const resp = await response.json();
    //   console.log("image------------", resp.result.image)
  
      // Return the URL required for displaying the uploaded image in CKEditor
      return {
        default: resp.result.image, // Ensure that `result.url` contains the path to the uploaded image
      };
    } catch (err) {
      console.log('error---', err);
      throw err;
    }
  };
  

  // Define a custom plugin to handle image uploads in CKEditor
function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return {
        upload: () => imageUpload(loader),
      };
    };
  }
  

  return (
    <div className={`${className} ${size}`} style={descriptionStyling}>
      {label && <label className="editor-label">{label}</label>}
      <CKEditor
        editor={ClassicEditor}
        data={value}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
        required={isRequired}
        onError={showError}
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin],
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'alignment',
            // 'link',
            'bulletedList',
            'numberedList',
            '|',
            'imageUpload', // Enable image upload
            // 'blockQuote',
            'undo',
            'redo'
          ],
          image: {
            toolbar: [
            //   'imageStyle:full',
            //   'imageStyle:side',
              '|',
            //   'imageTextAlternative'
            ],
        
            // styles: ['full', 'side'],
            upload: {
              types: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff']
            },
            resizeOptions: [
              {
                name: 'resizeImage:default',
                label: 'Default',
                value: { width: '600px', height: '400px' }, // Set default dimensions
              },
            ],
          },
          alignment: {
            options: ['left', 'center', 'right', 'justify'] // Specify alignment options
          },
        }}
      />
      {helperText && (
        <Typography variant="caption" color="textSecondary" style={{ marginTop: "5px" }}>
          {helperText}
        </Typography>
      )}
      {/* Show error message if required */}
      {showError && <p className="error-text">This field is required.</p>}
    </div>
  );
};


RichTextEditor.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChangeEvent: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showErrorOnBlur: PropTypes.bool,
  descriptionStyling: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default RichTextEditor;
