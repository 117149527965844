export const subscriptionWebLabels = {

	email_field: "email",
	email: "Email",

	created_date_field: 'createdAt',
	created_date: 'Created Date',

	// status_field: 'status',
	// status: 'Status'

};
