import React from "react";
import "./Style.scss";
import LayersIcon from "@mui/icons-material/Layers";
import BasicTable from "./BasicTable";
import { Typography } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import AnalyticsFunctionality from "../analytics/Container";
import { analytics } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";
// import {
//   Tooltip,
//   Label,
//   XAxis,
//   YAxis,
//   Legend,
//   Bar,
//   BarChart,
//   LabelList,
// } from "recharts";
// import DropdownField from "../../common/DropdownField";
import { useNavigate } from "react-router-dom";
import { setActiveIndex } from "../../redux/reducer/appReducer";
import { useDispatch } from "react-redux";

export default function Analytics(props) {
	const {
		userCount,
		companyUsers,
		endUsers,
		// pricingPlans,
		// barChartPricingPlans,
		// chartType,
		// handleChartTypeChange,
	} = AnalyticsFunctionality(props);

	const userRole = secureLocalStorage.getItem(storageConstants.role);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	//   const filterDataByDay = (data) => {
	//     const currentDate = new Date();
	//     const lastWeekDate = new Date();
	//     lastWeekDate.setDate(lastWeekDate.getDate() - 7);
	//     data?.sort((a, b) => new Date(a._id) - new Date(b._id));
	//     return data?.filter((item) => {
	//       const createdAtDate = new Date(item._id);
	//       return createdAtDate >= lastWeekDate && createdAtDate <= currentDate;
	//     });
	//   };

	//   const filterDataByMonth = (data) => {
	//     const currentDate = new Date();
	//     const currentYear = currentDate.getFullYear();
	//     const currentMonth = currentDate.getMonth();
	//     const currentMonthStart = new Date(
	//       currentDate.getFullYear(),
	//       currentDate.getMonth(),
	//       1
	//     );
	//     const threeMonthsAgoStart = new Date(currentYear, currentMonth - 3, 1);

	//     data?.sort((a, b) => new Date(a._id) - new Date(b._id));
	//     return data?.filter((item) => {
	//       const createdAtDate = new Date(item._id);
	//       return (
	//         createdAtDate >= currentMonthStart ||
	//         createdAtDate >= threeMonthsAgoStart
	//       );
	//     });
	//   };

	//   const filteredData =
	//     chartType === "day"
	//       ? filterDataByDay(barChartPricingPlans)
	//       : filterDataByMonth(barChartPricingPlans);

	const TotalUserCard = ({ title, activeCount, inActiveCount }) => {
		const handleCardClick = (isActive) => {
			let statusf;
			let subscriptionStatusF;
			let route = "";
			let index = 0;

			if (title === "Total Users") {
				statusf = isActive; // true or false
				if (["SAD", "MSAD", "SM"].includes(userRole)) {
					route = "/dahsboard/all-users";
					index = 9;
				} else if (["CSAD", "CM", "SP"].includes(userRole)) {
					route = "/dashboard/users";
					index = 7;
				}
			} else if (title === "Total Companies") {
				subscriptionStatusF = isActive ? "Active" : "Inactive"; // Active or Inactive
				route = "/dashboard/company";
				index = 1;
			}

			dispatch(setActiveIndex(index));
			navigate(route, { state: { statusf, subscriptionStatusF } });
		};

		return (
			<div className="total-user-custom-card">
				<LayersIcon className="icon-styling" />
				<div className="count">
					<Typography variant="body2" className="main-title">
						{title}
					</Typography>
					<div className="sub-widgets">
						<div className="sub">
							<Typography>Active</Typography>
							<Typography
								variant="body1"
								className="total-user-name"
								underline="hover"
								onClick={() => handleCardClick(true)}
							>
								{activeCount}
							</Typography>
						</div>
						<div className="sub">
							<Typography>Inactive</Typography>
							<Typography
								variant="body1"
								className="total-user-name"
								onClick={() => handleCardClick(false)}
							>
								{inActiveCount}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const TotalAdminCard = ({ title, superAdminCount, adCount1, adCount2 }) => {
		const handleClick = (roleF) => {
			let route = "";
			let index = 0;

			if (["SAD", "MSAD", "SM"].includes(userRole)) {
				route = "/dashboard/platform-admin-users";
				index = 8;
			} else if (["CSAD", "CM", "SP"].includes(userRole)) {
				route = "/dashboard/admin-users";
				index = 6;
			}

			dispatch(setActiveIndex(index));
			navigate(route, { state: { roleF } });
		};


		return (
			<div className="total-admin-custom-card">
				<LayersIcon className="icon-styling" />
				<div className="count">
					<Typography variant="body2" className="main-title">
						{" "}
						{title}{" "}
					</Typography>
					<div className="sub-widgets">
						<div className="sub">
							<Typography>
								{["SAD", "MSAD", "SM"].includes(userRole)
									? "Super Admin"
									: "Client Super Admin"}
							</Typography>
							<Typography
								variant="body1"
								className="total-user-name"
								onClick={() => handleClick(
									["SAD", "MSAD", "SM"].includes(userRole) ? "SAD" : "CSAD"
								)}
							>
								{" "}
								{superAdminCount}{" "}
							</Typography>
						</div>
						<div className="sub">
							<Typography>
								{["SAD", "MSAD", "SM"].includes(userRole)
									? "Sales Manager"
									: "Sales Person"}
							</Typography>
							<Typography variant="body1" className="total-user-name"
								onClick={() => handleClick(
									["SAD", "MSAD", "SM"].includes(userRole) ? "SM" : "SP"
								)}>
								{" "}
								{adCount1}{" "}
							</Typography>
						</div>
						<div className="sub">
							<Typography>
								{["SAD", "MSAD", "SM", "DEV"].includes(userRole)
									? "Developer"
									: "MetaRob Admin"}
							</Typography>
							<Typography variant="body1" className="total-user-name"
								onClick={() => handleClick(
									["SAD", "MSAD", "SM", "DEV"].includes(userRole) ? "DEV" : "MAD"
								)}>
								{" "}
								{adCount2}{" "}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		);
	};

	//   const CustomCard = ({ title, data }) => {
	//     return (
	//       <div className="all-details-custom-card">
	//         <Typography variant="body1" className="title-container">
	//           {title}
	//         </Typography>
	//         <div className="details-data">
	//           {title === analytics.totalRevenue && (
	//             <div className="revenue-titles">
	//               <Typography variant="body2" className="revenue-title">
	//                 {pricingPlan.revenueTitle}
	//               </Typography>
	//               <Typography variant="body2" className="revenue-title">
	//                 {pricingPlan.revenuePrice}
	//               </Typography>
	//               <Typography variant="body2" className="revenue-title">
	//                 {pricingPlan.userAccounts}
	//               </Typography>
	//             </div>
	//           )}
	//           {data?.map((dataItem) => (
	//             <div key={dataItem._id} className="data-item">
	//               <Typography variant="body2" className="title">
	//                 {title === analytics.totalRevenue
	//                   ? dataItem.title
	//                   : dataItem.role}
	//               </Typography>
	//               <Typography variant="body2" className="title">
	//                 {title === analytics.totalRevenue && dataItem.price}
	//               </Typography>
	//               <Typography variant="body2" className="title">
	//                 {title === analytics.totalRevenue && dataItem.userLimit}
	//               </Typography>
	//               <Typography variant="body1">{dataItem.usersCount}</Typography>
	//             </div>
	//           ))}
	//         </div>
	//       </div>
	//     );
	//   };

	const SubCustomCard = ({ title, description, item }) => {
		return (
			<div className="user-details-custom-card">
				<Typography variant="body1" className="mainTitle">
					{" "}
					{title}{" "}
				</Typography>
				<Typography className="description-styling"> {description} </Typography>
				<div key={item}>
					<BasicTable data={item} title={title} />
				</div>
			</div>
		);
	};

	//   function getColorForPaymentType(paymentType) {
	//     switch (paymentType) {
	//       case "prime":
	//         return "#EE82EE";
	//       case "Premium":
	//         return "#87CEFA";
	//       case "supreme":
	//         return "#FFC0CB";
	//       default:
	//         return "gray";
	//     }
	//   }

	//   const CustomXAxisTick = ({ x, y, payload }) => {
	//     const dateString = payload.value;
	//     const date = new Date(dateString);
	//     const dayOfWeek = date.getDay();
	//     const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	//     const dayName = dayNames[dayOfWeek];
	//     const monthNames = [
	//       "Jan",
	//       "Feb",
	//       "Mar",
	//       "Apr",
	//       "May",
	//       "Jun",
	//       "Jul",
	//       "Aug",
	//       "Sep",
	//       "Oct",
	//       "Nov",
	//       "Dec",
	//     ];
	//     if (chartType === "month") {
	//       return (
	//         <g transform={`translate(${x},${y})`}>
	//           <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
	//             {monthNames[date.getMonth()]}
	//           </text>
	//         </g>
	//       );
	//     } else {
	//       return (
	//         <g transform={`translate(${x},${y})`}>
	//           <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
	//             {dayName}
	//           </text>
	//         </g>
	//       );
	//     }
	//   };

	//   const chartData = filteredData.map((item) => {
	//     const chartDataItem = {
	//       date: item._id,
	//     };

	//     item.data.forEach((dataItem) => {
	//       chartDataItem[`${dataItem.paymentType}_${dataItem.companyName}`] =
	//         dataItem.price;
	//     });
	//     return chartDataItem;
	//   });

	//   function getUniquePaymentTypes(data) {
	//     const uniquePaymentTypes = new Set();
	//     data.forEach((item) => {
	//       item.data.forEach((dataItem) => {
	//         uniquePaymentTypes.add(dataItem.paymentType);
	//       });
	//     });
	//     return Array.from(uniquePaymentTypes);
	//   }

	//   const uniquePaymentTypes = getUniquePaymentTypes(filteredData);

	return (
		<div className="dashboard-main-card-container">
			<div className="total-user-main-container">
				{["SAD", "MSAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						<>
							<TotalUserCard
								title={analytics.totalRegisterUsers}
								activeCount={userCount[0]?.TotalActiveCompanyUsers}
								inActiveCount={userCount[0]?.TotalInactiveCompanyUsers}
							/>
							<TotalAdminCard
								title="Total Admin Users"
								superAdminCount={userCount[0]?.TotalPlatformSAD}
								adCount1={userCount[0]?.TotalPlatformSM}
								adCount2={userCount[0]?.TotalPlatformDEV}
							/>
							<TotalUserCard
								title="Total Companies"
								activeCount={userCount[0]?.TotalActiveCompanies}
								inActiveCount={userCount[0]?.TotalInactiveCompanies}
							/>
							<SubCustomCard
								item={endUsers}
								title={analytics.newUserRegistration}
							/>
						</>
					)}
				{["SM"].includes(secureLocalStorage.getItem(storageConstants.role)) && (
					<>
						<TotalUserCard
							title={analytics.totalRegisterUsers}
							activeCount={userCount[0]?.TotalActiveCompanyUsers}
							inActiveCount={userCount[0]?.TotalInactiveCompanyUsers}
						/>
						<TotalUserCard
							title="Total Companies"
							activeCount={userCount[0]?.TotalActiveCompanies}
							inActiveCount={userCount[0]?.TotalInactiveCompanies}
						/>
						<SubCustomCard
							item={endUsers}
							title={analytics.newUserRegistration}
						/>
					</>
				)}
				{["CM", "CSAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						// <CustomCard title={analytics.totalCompanyUsers} data={companyUsers} />
						<>
							<TotalUserCard
								title={analytics.totalRegisterUsers}
								activeCount={companyUsers[0]?.activeUsersCount}
								inActiveCount={companyUsers[0]?.inactiveUsersCount}
							/>
							<TotalAdminCard
								title="Total Admin Users"
								superAdminCount={companyUsers[0]?.activeCSADCount}
								adCount1={companyUsers[0]?.activeSPCount}
								adCount2={companyUsers[0]?.activeMADCount}
							/>
							<SubCustomCard
								item={companyUsers}
								title={analytics.newUserRegistration}
							// description={analytics.registerUserDescription}
							/>
						</>
					)}
				{["SP"].includes(secureLocalStorage.getItem(storageConstants.role)) && (
					// <CustomCard title={analytics.totalCompanyUsers} data={companyUsers} />
					<>
						<TotalUserCard
							title={analytics.totalRegisterUsers}
							activeCount={companyUsers[0]?.activeUsersCount}
							inActiveCount={companyUsers[0]?.inactiveUsersCount}
						/>

						<SubCustomCard
							item={companyUsers}
							title={analytics.newUserRegistration}
						// description={analytics.registerUserDescription}
						/>
					</>
				)}
				{/* <CustomCard title={analytics.totalRevenue} data={pricingPlans} /> */}
				{/* {["CM"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
					<SubCustomCard
						item={companyUsers[0]?.companyUsers}
						title={analytics.newUserRegistration}
						// description={analytics.registerUserDescription}
					/>
				)} */}

				{/* <div className='user-details-custom-card'>
					<div className="dropdown-parent-container">
						<DropdownField
								label="Select Time Period"
								value={chartType}
								onChangeEvent={handleChartTypeChange}
								data={[
										{ id : "day",   title : "Week" },
										{ id : "month", title : "Month" }
										]}
										width={150}/>
					</div>
					<div className="barChart-parent-container">      
								 <BarChart width={600} height={165} data={chartData}>
									<XAxis 
												dataKey="date" 
												tick={<CustomXAxisTick />} />
										<YAxis>
											<Label
													value="Price"
													position="insideLeft"
													angle={-90}
													offset={5}
													style={{ fontWeight: 'bold' }}/>
										</YAxis>
										<Tooltip
												formatter={(value, name, props) => {
													return [`${props.dataKey}: ${value}`, props.payload.companyName];
												}}/>
										<Legend
												iconSize={15} 
												iconType="square"
												payload={uniquePaymentTypes.map((paymentType) => ({
												value: paymentType,
												type: 'square',
												color: getColorForPaymentType(paymentType),
												}))}/>
											
											{filteredData?.map((item) => (
													item?.data?.map(dataItem => {
														const { paymentType, companyName } = dataItem;
														const key = `${paymentType}_${companyName}`;
														return (
															<Bar
																key={key}
																dataKey={key}
																name={`${paymentType}`}
																fill={getColorForPaymentType(paymentType)}
																barSize={20}>  
																<LabelList
																	dataKey={key}
																	position="top"
																	content={data => data.value}/>
															</Bar>
														);
													})
												))}
								 </BarChart>
					</div>
			 </div>   */}
				{/* {["AD", "SAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
						// <SubCustomCard
						// 	item={endUsers}
						// 	title={analytics.newUserRegistration}
						// // description={analytics.registerUserDescription}
						// />
					)} */}
				{/* <SubCustomCard
					title={analytics.redeemRequest}
					description={analytics.withdrawRequest}
				/> */}
			</div>
		</div>
	);
}
